var render, staticRenderFns
import script from "./UspBlock.vue?vue&type=script&lang=js"
export * from "./UspBlock.vue?vue&type=script&lang=js"
import style0 from "./UspBlock.vue?vue&type=style&index=0&id=7c54b0d8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@5.2.7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports