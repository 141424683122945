import { renderChildren } from '@nsf/base/components/PageBuilder/index.js';
export default {
  name: 'CustomWidget',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    return h('div', {
      class: 'text'
    }, [h('h2', ctx.parent.$i18n.t('global.pageBuilder.Benefit with card')), h('div', {
      class: 'mt-2 box',
      props: {
        data: ctx.props.data
      }
    }, renderChildren(h, ctx, children))]);
  }
};