<template>
  <div class="drug-leaflet-widget">
    <form @submit.prevent="onSearch">
      <input v-model="query" class="form-control border" :placeholder="$t('clubs.Enter drug name')">
      <v-button class="btn btn-primary">
        {{ $t('clubs.Search') }}
      </v-button>
    </form>

    <div v-if="loading" class="text-center mt-5">
      <span class="spinner-border text-primary" />
    </div>

    <div v-if="lastSearchedQuery && !loading" class="leaflets">
      <h2 v-if="leaflets.length > 0">
        {{ $t('clubs.Found results') }}
      </h2>
      <h2 v-if="leaflets.length === 0">
        {{ $t('clubs.No results found') }}
      </h2>

      <div v-for="leaflet in leaflets" :key="leaflet.apa_code" class="leaflet">
        <div class="leaflet-name" @click="toggleLeaflet(leaflet.apa_code)">
          <span class="leaflet-product-name">{{ leaflet.name }}</span>
          <span class="leaflet-arrow" :class="{active: isLeafletOpened(leaflet.apa_code)}">
            <svg
              width="22"
              height="14"
              viewBox="0 0 22 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="20.3984"
                y="0.521973"
                width="3"
                height="15.41"
                rx="1.96257"
                transform="rotate(45 20.3984 0.521973)"
                fill="currentColor"
              />
              <rect
                width="3"
                height="15.3506"
                rx="2"
                transform="matrix(-0.707107 0.707107 0.707107 0.707107 1.41406 0.521973)"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>

        <div v-if="isLeafletOpened(leaflet.apa_code)" class="d-xl-block leaflet-images">
          <img :src="'/pharmdata-image/obr/' + leaflet.apa_code">
          <img :src="'/pharmdata-image/spc/' + leaflet.apa_code">
          <img :src="'/pharmdata-image/kratky/' + leaflet.apa_code">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Button from '@nsf/ui-library/components/atoms/Button.vue'
import { getDrugLeaflets } from '../repositories/DrugLeafletRepository.js'

export default {
  name: 'DrugLeafletWidget',

  components: {
    'v-button': Button,
  },

  data() {
    return {
      query: '',
      leaflets: [],
      loading: false,
      openedLeaflets: [],
      lastSearchedQuery: '',
    }
  },

  methods: {
    async onSearch() {
      // request to REST api
      if (this.loading) {
        return
      }

      this.loading = true
      const leaflets = await getDrugLeaflets(this.query)
      this.openedLeaflets = []
      this.leaflets = []
      this.lastSearchedQuery = this.query

      // foreach through items and we will receive info from pharmdata
      for (const leaflet of leaflets) {
        // send request
        fetch(`/pharmdata/${leaflet.apa_code}`)
          .then((response) => {
            if (!response.ok) {
              return
            }

            // eslint-disable-next-line consistent-return
            return response.text()
          })
          .then((text) => {
            // eslint-disable-next-line promise/always-return
            if (!text.includes('Neex')) {
              this.leaflets.push(leaflet)
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }

      this.loading = false
    },

    toggleLeaflet(apaCode) {
      const index = this.openedLeaflets.indexOf(apaCode)

      if (index === -1) {
        this.openedLeaflets.push(apaCode)
      } else {
        this.openedLeaflets.splice(index, 1)
      }
    },

    isLeafletOpened(apaCode) {
      return this.openedLeaflets.includes(apaCode)
    },
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable max-nesting-depth */
.drug-leaflet-widget {
  margin: 15px 0;

  form {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 15px;

    input {
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 30px;
    }

    button {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .leaflets {
    margin-top: 30px;
  }

  .leaflet {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .leaflet-arrow {
    color: #0078be;
    transition: transform 0.2s ease;
  }

  .leaflet-arrow.active {
    transform: rotate(180deg);
  }

  .leaflet-product-name {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #0078be;
    cursor: pointer;
  }

  .leaflet-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;

    &:hover .leaflet-product-name {
      color: #af0023;
    }

    &:hover .leaflet-arrow {
      color: #af0023;
    }
  }

  .leaflet-images {
    img {
      display: block;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
}
/* stylelint-enable max-nesting-depth */
</style>
