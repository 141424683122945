export default {
  footer: {
    footerTopLogos: [],
    emailSubscription: true,
    paymentsImages: [],
    suklImages: false,
    footerTopAbsoluteImages: [
      {
        src: '/images/logo-grms.png',
        alt: 'logo MS',
        link: 'https://ms.ro/ro/',
        width: 160,
        height: 160,
        top: 20,
        right: 450,
      },
      {
        src: '/images/logo-ms.png',
        alt: 'Logo Ms',
        link: 'https://ms.ro/ro/informatii-de-interes-public/pacienti/farmacii-%C8%99i-drogherii/',
        width: 123,
        height: 102,
        top: 0,
        right: 0,
      },
      {
        src: '/images/mc_vrt_opt_pos_73_3x.png',
        alt: 'MasterCard',
        width: 50,
        height: 41,
        top: 122,
        right: 100,
      },
      {
        src: '/images/vbm_blu01.png',
        alt: 'VISA',
        width: 80,
        height: 26,
        top: 127,
        right: 0,
      },
      {
        src: '/images/sal.png',
        alt: 'SAL',
        link: 'https://anpc.ro/ce-este-sal/',
        width: 250,
        height: 60,
        top: 30,
        right: 180,
      },
      {
        src: '/images/sol.png',
        alt: 'SOL',
        link: 'https://ec.europa.eu/consumers/odr',
        width: 250,
        height: 60,
        top: 100,
        right: 180,
      },
    ],
    footerTopAbsoluteImagesHeight: 90,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: true,
    footerCertificateText: 'DR.MAX S.R.L., CUI: 9378655, J23/673/2007, sediu Mogoșoaia, str. Aeroportului, nr. 53, Ilfov, punct de lucru: Mogoșoaia, str. Buiacului, nr. 2, Zona de Depozitare Suplimentară și Zona de Mezanin Suplimentară, Ilfov, autorizația MS <a href="https://backend.drmax.ro/media/wysiwyg/buiacului-drogherie.pdf" target="_blank">3059/NT3480/04.06.2020</a>',
    footerCertificateTextClass: 'alert alert-info',
    footerCertificateImages: [],
    flags: true,
    mobileApplications: false,
  },
}
