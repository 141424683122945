export const contentTypeMap = {
  drmaxArticleBlock: 'articleBlock',
  drmaxBannerBlocks: 'bannerBlocks',
  drmaxCategoryFilters: 'filters',
  drmaxFaqBlock: 'drmaxAdvisory',
  drmaxHpTopProducts: 'hpTopProducts',
  drmaxLimitedTimeOfferForm: 'drmaxLimitedTimeOffer',
  drmaxPagebuilderArticleBlock: 'articleBlock',
  drmaxPagebuilderBrandCategoryFilter: 'drmaxBrandCategoryFilter',
  drmaxPagebuilderCustomCategoryBlock: 'drmaxCustomCategoryBlock',
  drmaxPagebuilderFaqBlock: 'drmaxAdvisory',
  drmaxPdf: 'pdf',
  drmaxSocials: 'socials',
  drmaxTableOfContentsForm: 'drmaxTableOfContents',
  drmaxTimedPromotion: 'timedPromotion',
  drmaxTip: 'tip',
  drmaxWidget: 'widget',
  pagebuilderDrmaxArticleBlockForm: 'articleBlock',
}

export const headingsTagList = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
