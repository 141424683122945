import { renderChildren } from '@nsf/base/components/PageBuilder/index.js';
export default {
  name: 'VTable',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var data = {
      class: 'cms-table-wrapper table-responsive-md'
    };
    var tableData = {
      class: 'table table-bordered'
    };
    return h('div', data, [h('table', tableData, renderChildren(h, ctx, children))]);
  }
};