import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
import flatMap from 'lodash/flatMap.js';
import { toCamel, isArray, isObject, isString, isNullish } from '@nsf/core/Utils.js';
import * as pbComponents from '@ioc/pageBuilder.js';
import * as html from './Html/index.js';
import { contentTypeMap, headingsTagList } from '../../utils/PageBuilderEnums.js';
var _renderChildren = function renderChildren(h, ctx, children) {
  var depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  return depth ? _renderChildren(h, ctx, flatMap(children, function (child) {
    return child.children || [];
  }), depth - 1)
  // eslint-disable-next-line no-use-before-define
  : children === null || children === void 0 ? void 0 : children.map(function (child) {
    return renderItem(h, {
      props: {
        data: child
      },
      useAllAttribs: ctx.useAllAttribs || false
    });
  });
};

// eslint-disable-next-line complexity
export { _renderChildren as renderChildren };
export var renderItem = function renderItem(h, ctx) {
  var _contentTypeMap$conte;
  var data = ctx.props.data;
  var style = data.style || '';
  // eslint-disable-next-line no-use-before-define
  var displayStyle = extract(style, /display: .+?;/) || '';

  // if content is hidden in admin, we will not render it
  if (displayStyle === 'display: none;') {
    return '';
  }
  var contentType = toCamel(data['data-content-type']);
  var resultContentType = (_contentTypeMap$conte = contentTypeMap[contentType]) !== null && _contentTypeMap$conte !== void 0 ? _contentTypeMap$conte : contentType;
  var tag = data.tag;
  var children = data.children || [];
  if (children[0] !== undefined) {
    if (children[0].class === 'uspblock') {
      return h(pbComponents.pbUspBlock, {
        props: {
          data: data
        }
      });
    }
    if (children[0].class === 'notext') {
      return h(pbComponents.pbNoText, {
        props: {
          data: data
        }
      });
    }
    if (children[0].class === 'brandblock') {
      return h(pbComponents.pbBrandBlock, {
        props: {
          data: data
        }
      });
    }
    if (children[0].class === 'accordion') {
      return h(pbComponents.pbButtonAccordion, {
        props: {
          data: data
        }
      });
    }
  }
  if (resultContentType && !isNullish(pbComponents[toCamel("pb_".concat(resultContentType))])) {
    return h(pbComponents[toCamel("pb_".concat(resultContentType))], {
      props: {
        data: data
      }
    });
  }
  if (tag in html) {
    return h(html[tag], _objectSpread({
      props: {
        data: data
      }
    }, data.id && {
      attrs: {
        id: data.id
      }
    }));
  }
  if (headingsTagList.includes(tag)) {
    return h(tag, _objectSpread({
      props: {
        data: data
      },
      style: data.style
    }, data.id && {
      attrs: {
        id: data.id
      }
    }), _renderChildren(h, ctx, children));
  }
  if (tag === 'none') {
    return data.html;
  }
  if (tag === 'head') {
    return '';
  }
  var elementData = {
    attrs: {
      id: data.id
    }
  };
  if (ctx.useAllAttribs) {
    elementData.attrs = data;
  }
  return h(tag, elementData, _renderChildren(h, ctx, children));
};
export var extract = function extract(string, regex) {
  if (!isString(string)) {
    return '';
  }
  var match = string.match(regex);
  return match ? match[0] : match;
};
export var extractBorderRadius = function extractBorderRadius(string) {
  var extracted = '';
  extracted += extract(string, /border-radius: .+?;/) || '';
  extracted += extract(string, /border-top-left-radius: .+?;/) || '';
  extracted += extract(string, /border-top-right-radius: .+?;/) || '';
  extracted += extract(string, /border-bottom-left-radius: .+?;/) || '';
  extracted += extract(string, /border-bottom-right-radius: .+?;/) || '';
  return extracted;
};
export var extractBorder = function extractBorder(string) {
  var extracted = '';
  extracted += extract(string, /border-style: .+?;/) || '';
  extracted += extract(string, /border-color: .+?;/) || '';
  extracted += extract(string, /border-width: .+?;/) || '';
  return extracted;
};
export var extractMinHeight = function extractMinHeight(string) {
  return extract(string, /min-height: .+?;/) || '';
};
export var extractChildren = function extractChildren(ctx) {
  return ctx.props.data.children || [];
};
var _extractHtmlProperty = function extractHtmlProperty(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  var value = '';
  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    value = o.map(function (o) {
      return _extractHtmlProperty(o, depth);
    }).join('');
  }
  if (isObject(o)) {
    value = o.html || _extractHtmlProperty(o.children || o.items, depth - 1);
  }
  return value;
};
export { _extractHtmlProperty as extractHtmlProperty };
var _extractHtml = function extractHtml(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  var value = '';
  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    value = o.map(function (o) {
      return _extractHtml(o, depth);
    }).join('');
  }
  if (isObject(o)) {
    value = o.html || o.value || _extractHtml(o.children || o.items, depth - 1);
    if (o.tag && !['', 'none'].includes(o.tag)) {
      var keys = Object.keys(o);
      var attrs = '';
      for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
        var key = _keys[_i];
        if (!['children', 'tag'].includes(key)) {
          // eslint-disable-next-line max-depth
          if (key) {
            attrs = "".concat(attrs, " ").concat(key);
          }
          // eslint-disable-next-line max-depth
          if (o[key]) {
            attrs = "".concat(attrs, "=\"").concat(o[key], "\"");
          }
        }
      }
      if (['img', 'br', 'hr', 'input', 'link', 'base', 'area', 'meta'].includes(o.tag)) {
        value = "<".concat(o.tag).concat(attrs, ">");
      } else {
        value = "<".concat(o.tag).concat(attrs, ">").concat(value, "</").concat(o.tag, ">");
      }
    }
  }
  return value;
};
export { _extractHtml as extractHtml };
var _extractStyle = function extractStyle(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map(function (o) {
      return _extractStyle(o, depth);
    }).join('; ');
  }
  if (isObject(o)) {
    return o.style || _extractStyle(o.children, depth - 1);
  }
  return '';
};
export { _extractStyle as extractStyle };
var _extractMobileStyle = function extractMobileStyle(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map(function (o) {
      return _extractMobileStyle(o, depth);
    }).join('; ');
  }
  if (isObject(o)) {
    return (o === null || o === void 0 ? void 0 : o['mobile-style']) || _extractMobileStyle(o.children, depth - 1);
  }
  return '';
};
export { _extractMobileStyle as extractMobileStyle };
var _extractClass = function extractClass(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map(function (o) {
      return _extractClass(o, depth);
    }).join('; ');
  }
  if (isObject(o)) {
    return o.class || _extractClass(o.children, depth - 1);
  }
  return '';
};
export { _extractClass as extractClass };
var _extractSrc = function extractSrc(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  if (isArray(o)) {
    var _o$map;
    // eslint-disable-next-line no-shadow
    return (_o$map = o.map(function (o) {
      return _extractSrc(o, depth);
    })) === null || _o$map === void 0 ? void 0 : _o$map[0];
  }
  if (isObject(o)) {
    return o.src || _extractSrc(o.children, depth - 1);
  }
  return '';
};
export { _extractSrc as extractSrc };
var _extractAlt = function extractAlt(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  if (isArray(o)) {
    var _o$map2;
    // eslint-disable-next-line no-shadow
    return (_o$map2 = o.map(function (o) {
      return _extractAlt(o, depth);
    })) === null || _o$map2 === void 0 ? void 0 : _o$map2[0];
  }
  if (isObject(o)) {
    return o.alt || _extractAlt(o.children, depth - 1);
  }
  return '';
};
export { _extractAlt as extractAlt };
var _extractHref = function extractHref(o) {
  var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  if (depth < 0) {
    return '';
  }
  if (isArray(o)) {
    var _o$map3;
    // eslint-disable-next-line no-shadow
    return (_o$map3 = o.map(function (o) {
      return _extractHref(o, depth);
    })) === null || _o$map3 === void 0 ? void 0 : _o$map3[0];
  }
  if (isObject(o)) {
    return o.href || _extractHref(o.children, depth - 1);
  }
  return '';
};
export { _extractHref as extractHref };
var _findAll = function findAll(o, predicate) {
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Infinity;
  if (isNullish(o)) {
    return [];
  }
  if (depth < 0) {
    return [];
  }
  var found = [];
  if (isArray(o)) {
    var _iterator = _createForOfIteratorHelper(o),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var val = _step.value;
        found.push.apply(found, _toConsumableArray(_findAll(val, predicate, depth - 1)));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  if (predicate(o)) {
    found.push(o);
  }
  if (isObject) {
    found.push.apply(found, _toConsumableArray(_findAll(o.children, predicate, depth - 1)));
  }
  return found;
};
export { _findAll as findAll };
var _findFirstElement = function findFirstElement(o, predicate) {
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Infinity;
  if (isNullish(o)) {
    return null;
  }
  if (depth < 0) {
    return null;
  }
  if (isArray(o)) {
    var _iterator2 = _createForOfIteratorHelper(o),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var val = _step2.value;
        var f = _findFirstElement(val, predicate, depth - 1);
        if (f) {
          return f;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
  if (predicate(o)) {
    return o;
  }
  if (isObject) {
    var _f = _findFirstElement(o.children, predicate, depth - 1);
    if (_f) {
      return _f;
    }
  }
  return null;
};
export { _findFirstElement as findFirstElement };
var _findDataElement = function findDataElement(type, source) {
  if (!source.children) {
    return null;
  }
  var _iterator3 = _createForOfIteratorHelper(source.children),
    _step3;
  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var item = _step3.value;
      var property = item['data-element'] !== type ? _findDataElement(type, item) : item;
      if (property) {
        return property;
      }
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
  return null;
};
export { _findDataElement as findDataElement };
var _findDataContentType = function findDataContentType(type, source) {
  var checkStartsWith = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!source.children) {
    return null;
  }
  var _iterator4 = _createForOfIteratorHelper(source.children),
    _step4;
  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var _item$dataContentTy;
      var item = _step4.value;
      var property = item['data-content-type'] === type || checkStartsWith && (_item$dataContentTy = item['data-content-type']) !== null && _item$dataContentTy !== void 0 && _item$dataContentTy.startsWith(type) ? item : _findDataContentType(type, item, checkStartsWith);
      if (property) {
        return property;
      }
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }
  return null;
};
export { _findDataContentType as findDataContentType };
export var extractTableOfContentForPagebuilder = function extractTableOfContentForPagebuilder(data) {
  // List of anchors for Table of contents
  var anchors = [];

  // Find all h2 headings in content
  var headings = _findAll(data, function (o) {
    return ['h2'].includes(o.tag);
  });

  // generate anchors for Table of contents
  headings.forEach(function (heading) {
    var headingContent = _extractHtmlProperty(heading).trim();
    if (headingContent) {
      var htmlContent = headingContent.replace(/(<([^>]+)>)/gi, '');
      anchors.push({
        id: heading.id,
        html: htmlContent,
        tag: heading.tag
      });
    }
  });
  return anchors;
};
export var getPropsData = function getPropsData(ctx, key) {
  var _ctx$props$data$key;
  return (_ctx$props$data$key = ctx.props.data[key]) !== null && _ctx$props$data$key !== void 0 ? _ctx$props$data$key : '';
};