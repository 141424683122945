import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.includes.js";
import { extractDeclarationFromStyle, extractMarginAndPaddingFromStyle, getBackgroundStyle, setFlexAlignment } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import Button from '@nsf/ui-library/components/atoms/Button.vue';
import { extractChildren, renderChildren } from '../components/PageBuilder/index.js';
var getButtonVariant = function getButtonVariant(className) {
  switch (className) {
    case 'pagebuilder-button-secondary':
      return 'secondary';
    case 'pagebuilder-button-link':
      return 'naked';
    case 'pagebuilder-button-primary':
    default:
      return 'primary';
  }
};
var isAttachment = function isAttachment(ctx, index) {
  var attach = extractChildren(ctx).map(function (child) {
    return child['data-content-type'];
  });
  return attach[index] === 'attachment';
};
var extractButtons = function extractButtons(ctx) {
  return extractChildren(ctx).flatMap(function (child) {
    return child.children;
  }).map(function (button, index) {
    var marginAndPadding = extractMarginAndPaddingFromStyle(button.style);
    var openInNewTab = isAttachment(ctx, index) || button.target === '_blank';
    return {
      ctx: _objectSpread(_objectSpread({
        props: {
          link: button.href || ''
        },
        attrs: {
          target: openInNewTab ? '_blank' : undefined
        },
        class: marginAndPadding.includes('margin') ? 'mb-1 mr-1' : ''
      }, marginAndPadding && {
        style: marginAndPadding
      }), {}, {
        variant: getButtonVariant(button.class)
      }),
      children: button.children || []
    };
  });
};
export default {
  name: 'VButtons',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _extractDeclarationFr = extractDeclarationFromStyle('text-align', ctx.props.data.style),
      textAlign = _extractDeclarationFr.declarationValue;
    var buttons = extractButtons(ctx).map(function (button) {
      return h(Button, button.ctx, renderChildren(h, {}, button.children));
    });
    var appearance = ctx.props.data['data-appearance'];
    var classes = [];
    classes.push('pagebuilder-buttons');
    classes.push("pagebuilder-buttons-".concat(appearance));
    classes.push(setFlexAlignment(textAlign));
    var sameWidth = ctx.props.data['data-same-width'] === 'true';
    if (sameWidth) {
      classes.push('pagebuilder-buttons-same-width');
    }
    return h('div', {
      class: classes.join(' '),
      style: [extractMarginAndPaddingFromStyle(ctx.props.data.style), getBackgroundStyle(ctx)].join('')
    }, buttons);
  }
};