import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

function allowedCharacters(value) {
  return /^[a-z\u0103\u00E2\u00EE\u0219\u021B\s]*$/i.test(value)
}

export default defineAppConfig({
  cityMaxLength: 150,
  companyNameMaxLength: 150,
  emailMaxLength: 150,
  firstNameMaxLength: 150,
  lastNameMaxLength: 150,
  streetMaxLength: 150,

  cityAllowedCharacters: (value) => /^[a-z\u0103\u00E2\u00EE\u0219\u015f\u021B\u0163\u002E\u002C\u002D\s\d]*$/i.test(value),
  cityContainsLetter: () => true,
  customerNotesAllowedCharacters: (value) => allowedCharacters(value),
  firstLastNameAllowedCharacters: (value) => /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value) || allowedCharacters(value),
  streetAllowedCharacters: (value) => /^[a-z\u0103\u00E2\u00EE\u0219\u015f\u021B\u0163\u002E\u002C\u002D\s\d]*$/i.test(value),

  crnValid: (value) => /^[JCF][0-9]{1,2}\/[0-9]{1,7}\/[0-9]{4}$/.test(value),
  crnNumbersOnly: () => true,
  crnMaxLengthValid: (value) => value.length <= 16,
  crnMaxLengthLength: 16,

  passwordForbiddenKeywords: ['parola'],

  postCodeValid: (value) => /^[0-9]{6}$/.test(value),

  streetValid: () => true,
  streetNumberValid: () => true,
  streetNumberContainsNumber: () => true,

  validByFirstThreeDigits: () => true,
  validByForbiddenCode: () => true,

  vatIdMinLength: (value) => (value.substring(0, 2) === 'RO' ? 4 : 2),
  vatIdMaxLength: (value) => (value.substring(0, 2) === 'RO' ? 12 : 10),
  vatIdForbiddenValues: ['9378655', 'RO9378655'],
  vatIdMinLengthValid: (value) => (value.substring(0, 2) === 'RO' ? value.length >= 4 : value.length >= 2),
  vatIdMaxLengthValid: (value) => (value.substring(0, 2) === 'RO' ? value.length <= 12 : value.length <= 10),
  vatIdValid(value) {
    if (!/^[0-9]+/.test(value) && value.substring(0, 2) !== 'RO') {
      return false
    }

    return value.substring(0, 2) === 'RO'
      ? /^RO[0-9]{2,10}$/.test(value)
      : /^[0-9]{2,10}$/.test(value)
  },
  vatIdForbiddenWords: (value) => !['9378655', 'RO9378655'].includes(value),
  vatIdStartsWith: (value) => (!/^[0-9]+/.test(value) ? value.substring(0, 2) === 'RO' : true),
})
