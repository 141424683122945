import {
  smMax,
  mdMax,
  xlMin,
  xlMax,
} from '@nsf/ui-library/assets/styles/responsive.scss'
import throttle from 'lodash/throttle.js'

export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      size: {
        xs: smMax,
        sm: mdMax,
        md: xlMin,
        lg: xlMax,
      },
    }
  },

  /*
   * When migrating to Nuxt 3, remove vue-reactive-provide package and rewrite this file according to this task:
   * https://dev.azure.com/drmaxglobal/ecommerce-operations/_workitems/edit/87553
   */
  reactiveProvide: {
    name: 'WindowDimensionProvider',
    include: [
      'isXs',
      'isSm',
      'isMd',
      'isLg',
      'isXl',
      'isXsMobile',
      'isMobile',
      'isTablet',
      'isDesktop',
      'forXsMobile',
      'fromXsMobile',
      'forMobile',
      'fromMobile',
      'forTablet',
      'forDesktop',
      'window',
      'size',
    ],
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize: throttle(function () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }, 100),
  },

  computed: {
    isXs() {
      return this.window.width < this.size.xs
    },

    isSm() {
      return this.window.width < this.size.sm
    },

    isMd() {
      return this.window.width < this.size.md
    },

    isLg() {
      return this.window.width < this.size.lg
    },

    isXl() {
      return this.window.width >= this.size.lg
    },

    isXsMobile() {
      return this.window.width
        ? this.isXs
        : this.$device.isMobile
    },

    isMobile() {
      return this.window.width
        ? this.isSm
        : this.$device.isMobile
    },

    isTablet() {
      return this.window.width
        ? (!this.isSm && this.isMd)
        : this.$device.isTablet
    },

    isDesktop() {
      return this.window.width
        ? (!this.isMd && (this.isLg || this.isXl))
        : this.$device.isDesktop
    },

    forXsMobile() {
      return `(max-width: ${this.size.xs}px)`
    },

    fromXsMobile() {
      return `(min-width: ${this.size.xs}.1px)`
    },

    forMobile() {
      return `(max-width: ${this.size.sm}px)`
    },

    fromMobile() {
      return `(min-width: ${this.size.sm}.1px)`
    },

    forTablet() {
      return `(min-width: ${this.size.sm + 1}px) and (max-width: ${this.size.md}px)`
    },

    forDesktop() {
      return `(min-width: ${this.size.lg}px)`
    },
  },
}
