<template>
  <div
    :class="`pagebuilder-video${data.class ? ` ${data.class}` : ''}`"
    :id="elementId"
  >
    <div
      class="pagebuilder-video-inner"
      :style="`${innerElement.style}display: inline-block; width: 100%;`"
    >
      <div class="pagebuilder-video-wrapper" :style="wrapperInlineStyle">
        <div
          class="pagebuilder-video-container"
          style="padding-top: 56.25%; position: relative; overflow: hidden; border-radius: inherit"
        >
          <lite-youtube
            v-if="isYouTubeVideo && liteLoaded"
            :videoid="getYouTubeIdFromUrl(extractedSrc)"
            :autoplay="hasAutoplay"
            preview-image-size="mqdefault"
            style="position:absolute; top:0; left:0; width:100%; height:100%;"
            :params="hasAutoplay ? 'mute=1' : undefined"
          />
          <iframe
            v-else-if="!isYouTubeVideo"
            :src="extractedSrc"
            style="position:absolute; top:0; left:0; width:100%; height:100%;"
          />
        </div>
      </div>
    </div>
    <style v-if="styleContent" v-text="styleContent"></style>
  </div>
</template>

<script>
import { applyInlineStyles, createElementId, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js'
import { loadScript } from '@nsf/core/Utils.js'
import '@nsf/base/public/css/lite-yt-embed.css'

export default {
  name: 'VVideo',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    liteLoaded: false,
  }),
  computed: {
    desktopStyle() {
      return applyInlineStyles(this.data, this.data.style, { padding: true, width: true })
    },
    mobileStyle() {
      return this.data?.['mobile-style']
    },
    styleContent() {
      return createStyleElementContent(this.elementId, this.desktopStyle, this.mobileStyle)
    },
    elementId() {
      return createElementId(this.data?.['data-pb-style'])
    },
    children() {
      return this.data.children || []
    },
    innerElement() {
      return this.children?.[0]
    },
    wrapperElement() {
      return this.innerElement?.children?.[0]
    },
    containerElement() {
      return this.wrapperElement?.children?.[0]
    },
    extractedSrc() {
      return this.containerElement?.children?.[0]?.src ?? ''
    },
    hasAutoplay() {
      return this.containerElement?.children?.[0]?.autoplay === 'true'
    },
    wrapperInlineStyle() {
      return applyInlineStyles(this.wrapperElement, this.wrapperElement?.style, { margin: true, width: true })
    },
    isYouTubeVideo() {
      return this.getYouTubeIdFromUrl(this.extractedSrc)
    },
  },
  mounted() {
    // eslint-disable-next-line promise/catch-or-return,promise/always-return
    loadScript('/js/lite-yt-embed.js').then(() => {
      this.liteLoaded = true
    })
  },
  methods: {
    getYouTubeIdFromUrl(url) {
      const regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i
      const match = url.match(regex)
      return match ? match[1] : false
    },

  },
}

</script>
