<template>
  <article class="tabs__item" :class="{'tabs__item--active': isActive}" data-test-id="tabs-content-item">
    <h3 class="tabs__item__title d-md-none">
      <button
        :aria-expanded="isActive"
        @click="onClick"
      >
        {{ title }}
        <svg>
          <use xlink:href="~/assets/icons/icons.svg#chevron-left" />
        </svg>
      </button>
    </h3>

    <div
      v-if="isActive"
      class="tabs__item__content"
      :class="{ 'tabs__item__minheight': hasMinHeight }"
    >
      <div class="py-2 pt-md-5 pb-md-0" />

      <slot />

      <div class="py-2 pt-md-5 pb-md-0" />
    </div>
  </article>
</template>

<script>
export default {
  inject: [
    'tabs',
  ],

  props: {
    title: {
      type: String,
      default: '',
    },

    hasMinHeight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
    }
  },

  created() {
    this.tabs.register(this)
  },

  methods: {
    onClick() {
      this.tabs.toggle(this)
    },
  },
}
</script>
