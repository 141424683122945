/* eslint-disable camelcase */
import {
  Mutation, variable, field,
} from '@nsf/core/GraphQL.js'
import {
  REQUIRED_STRING, STRING, INT,
} from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  checkout: {
    features: {
      cartAgreementsEnabled,
      competitionService: {
        competitionWidgetEnabled,
      },
      drmaxCjEventEnabled,
      drmaxGatewayMethodEnabled,
      extraDataInPlaceOrderEnabled,
      extraOfferEnabled,
      gtmOrderInfoGqlEnabled,
      orderTypeEnabled,
      placeOrderDeliveryCalculationEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    competitionServiceEnabled,
  },
} = useRuntimeConfig()

export default () => Mutation.named('placeOrder')
  .where(
    'input',
    {
      cart_id: variable(
        'cartId',
        REQUIRED_STRING,
      ),
      ...orderTypeEnabled
        ? {
            order_type: variable(
              'orderType',
              REQUIRED_STRING,
            ),
          }
        : {},
      ...cartAgreementsEnabled
        ? {
            cart_agreements: variable(
              'cartAgreements',
              '[CartAgreementInput!]!',
            ),
          }
        : {},
      ...gtmOrderInfoGqlEnabled
        ? {
            google_ga_360: variable(
              'GoogleGa360',
              '[GoogleGa360Input!]!',
            ),
          }
        : {},
      ...placeOrderDeliveryCalculationEnabled
        ? {
            delivery_calculation_data: {
              delivery_absolute_time: variable(
                'deliveryAbsoluteTime',
                STRING,
              ),
              delivery_source_code: variable(
                'deliverySourceCode',
                STRING,
              ),
              delivery_source_id: variable(
                'deliverySourceId',
                INT,
              ),
              delivery_route_id: variable(
                'deliveryRouteId',
                STRING,
              ),
              delivery_method: variable(
                'deliveryMethod',
                INT,
              ),
            },
          }
        : {},
      ...drmaxCjEventEnabled
        ? {
            drmax_cj_event: variable(
              'drmaxCjEvent',
              REQUIRED_STRING,
            ),
          }
        : {},
      ...competitionServiceEnabled && competitionWidgetEnabled
        ? {
            drmax_competition_id: variable(
              'competitionId',
              STRING,
            ),
            drmax_competition_channel: variable(
              'competitionChannel',
              STRING,
            ),
            drmax_competition_number_of_coupons: variable(
              'competitionNumberOfCoupons',
              INT,
            ),
          }
        : {},
    },
  )
  .fields([
    field(
      'order',
      [
        field('form_url'),
        field('order_id'),
        ...drmaxGatewayMethodEnabled ? [field('drmax_gateway_method')] : [],
        ...extraOfferEnabled ? [field('thank_you_page_enabled')] : [],

        ...extraDataInPlaceOrderEnabled
          ? [
              field('payment_fee'),
              field('delivery_fee'),

              field(
                'address_delivery',
                [
                  field('street'),
                  field('city'),
                  field('zip'),
                ],
              ),

              field(
                'address_invoice',
                [
                  field('street'),
                  field('city'),
                  field('zip'),
                ],
              ),

              field(
                'discounts ',
                [
                  field('name'),
                  field('amount'),
                  field('products'),
                ],
              ),
            ]
          : [],
        ...extraOfferEnabled
          ? [
              field('drmax_typ_products',
                [
                  field('products', [
                    field('sku'),
                  ]),
                  field('order_created_at'),
                  field('countdown'),
                  field('server_time'),
                ]),
            ]
          : [],
      ],
    ),
  ])
