<template>
  <div v-if="pdfLink">
    <iframe :src="pdfLink" width="100%" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    pdfLink() {
      return this.data?.children[0]?.href
    },
  },
}
</script>

<style scoped lang="scss">
iframe {
  height: 80vh;

  @media only screen and (max-width: $md) {
    height: 75vh;
  }
}
</style>
