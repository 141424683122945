import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('filterPage')
  .where('page_id', variable('page_id', REQUIRED_INT))
  .fields([
    field('title'),
    field('url_key'),
    field('category_id'),
    field('attribute_id'),
    field('attribute_option_id'),
    field('content'),
    field('meta_title'),
    field('meta_description'),
    field('meta_keywords'),
  ])
  .shouldBeCached(true)
