<template>
  <div v-if="category">
    <v-categories
      v-if="hasCategoryFilter"
      :categories="cats"
      :category="category"
      :loading="loadingCategories"
      @onChangeCategory="changeCategory"
    />

    <v-brands
      v-if="hasBrandFilter"
      :brands="brands"
      :category-path="category.urlPath"
      :loading="loadingBrands"
      @onChangeBrand="changeBrand"
    />
  </div>
</template>

<script>
import uniq from 'lodash/uniq.js'
import { Query } from '@nsf/core/ElasticSearch.js'

export default {
  name: 'VFilters',

  components: {
    'v-brands': () => import('../components/PageBuilder/Filters/Brands.vue'),
    'v-categories': () => import('../components/PageBuilder/Filters/Categories.vue'),
  },

  inject: {
    category: {
      default: null,
    },
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cats: [],
      brands: [],
      catId: '',
      loadingBrands: true,
      loadingCategories: true,
    }
  },

  computed: {
    settings() {
      return this.data.children?.length ? this.data.children[0].filter_settings : null
    },

    hasBrandFilter() {
      return this.getFilterType('drmax_brand')
    },

    hasCategoryFilter() {
      return this.getFilterType('category')
    },
  },

  async mounted() {
    await this.loadBrandsAndCategories()
  },

  methods: {
    getFilterType(param) {
      return this.settings?.includes(param)
    },

    async changeBrand(brand) {
      const categoryQuery = (this.$route.query.category) ? `?category=${this.$route.query.category}` : ''

      const path = `${brand.url_path}/${this.category.urlPath}${categoryQuery}`
      this.$push(path)
      this.loadingBrands = true
    },

    async changeCategory(categoryId) {
      const categoryQuery = (categoryId && categoryId !== 'all') ? `?category=${categoryId}` : ''
      const path = `/${this.category.urlPath}${categoryQuery}`

      await this.$router.push(path)
      await this.loadBrands(categoryId)
    },

    async loadCategories() {
      if (!this.category) {
        return
      }

      this.loadingCategories = true

      const products = await Query.products()
        .only('drmax_brand', 'category', 'category_ids')
        .whereIn('category_ids', this.category.id)
        .getAll()

      const categoryIds = []

      for (const product of products) {
        for (const cat of product.category) {
          if (typeof cat.category_id !== 'undefined') {
            categoryIds.push(cat.category_id)
          }
        }
      }

      this.cats = await Query.categories()
        .only('id', 'name', 'url_path', 'path')
        .whereIn('id', uniq(categoryIds))
        .where('level', 2)
        .getAll()

      for (const cat of this.cats) {
        cat.count = products.reduce(
          (count, product) => (product.category_ids.includes(cat.id) ? count + 1 : count), 0,
        )
      }

      // we will remove parent category
      this.cats = this.cats.filter((cat) => !cat.path.split('/').includes(`${this.category.id}`))

      this.cats.unshift({
        name: this.$t('base.filters.all'),
        // eslint-disable-next-line camelcase
        url_path: '',
        id: 'all',
      })

      this.loadingCategories = false
    },

    async loadBrands(currentCategoryId) {
      if (!this.category) {
        return
      }

      this.loadingBrands = true
      const categoryId = this.category.id

      const query = Query.products()
        .only('drmax_brand', 'category')
        .whereIn('category_ids', categoryId)

      if (currentCategoryId && currentCategoryId !== 'all') {
        query.whereIn('category_ids', currentCategoryId)
      }

      const products = await query.getAll()
      const brandIds = []

      for (const product of products) {
        if (product.drmax_brand) {
          brandIds.push(product.drmax_brand)
        }
      }

      this.brands = await Query.brands()
        .only('id', 'option_id', 'title', 'url_path')
        .whereIn('option_id', uniq(brandIds))
        .getAll()

      for (const brand of this.brands) {
        brand.count = products.reduce(
          (count, product) => (product.drmax_brand === brand.option_id ? count + 1 : count), 0,
        )
      }

      // eslint-disable-next-line no-nested-ternary
      this.brands = this.brands.sort((a, b) => ((a.count > b.count) ? 1 : ((b.count > a.count) ? -1 : 0))).reverse()

      this.brands.unshift({
        title: this.$t('base.filters.all'),
        // eslint-disable-next-line camelcase
        url_path: '',
        id: 'all',
      })
      this.loadingBrands = false
    },

    async loadBrandsAndCategories() {
      await this.loadCategories()
      await this.loadBrands(this.$route.query.category)
    },
  },
}
</script>
