<template functional>
  <ul
    class="m-3"
    role="list"
    itemprop="mainEntity"
    itemscope=""
    itemtype="http://schema.org/ItemList"
  >
    <slot />
    <li
      v-for="(item, k) in props.items"
      :key="k"
      itemprop="itemListElement"
      itemscope
      class="mx-3 mb-2"
    >
      <div
        class="h4 mb-3 text-nowrap"
        itemprop="name"
      >
        <v-link
          class="text-black"
          :to="item.url"
          itemprop="url"
        >
          <div class="circle">
            <span
              v-if="item.number"
              class="number"
            >{{ item.number }}</span>
          </div>

          <template v-if="props.type === 'toddler'">
            {{ parent.$t(`clubs.baby.months`) }}
          </template>

          <template v-else>
            {{ item.type === 'week' ? parent.$t(`clubs.baby.week`) : parent.$t(`clubs.baby.month`) }}
          </template>
        </v-link>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PregnancyWeek',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },

    type: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
li {
  list-style: none;
}

.circle {
  position: relative;
  top: -0.25rem;
  float: left;
  width: 2rem;
  height: 2rem;
  padding: 0.2rem;
  margin-right: 1rem;
  color: #fff;
  border-radius: 50%;
  background-color: #d986ba;
}

.number {
  position: absolute;
  top: 0.35rem;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
}
</style>
