/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import cartShippingAddress from '../fragments/cartShippingAddress.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'

const {
  checkout: {
    features: {
      streetNumberToMagentoEnabled,
    },
  },
  rootConfig: {
    global: {
      regionSelectEnabled,
      streetNumberEnabled,
    },
  },
} = useAppConfig()

export default () => Mutation.named('setShippingAddressesOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    shipping_addresses: [{
      address: {
        city: variable('city', REQUIRED_STRING),
        country_code: variable('countryCode', REQUIRED_STRING),
        ...streetNumberToMagentoEnabled && streetNumberEnabled && { drmax_sk_house_number: variable('streetNumber', REQUIRED_STRING) },
        firstname: variable('firstname', REQUIRED_STRING),
        lastname: variable('lastname', REQUIRED_STRING),
        postcode: variable('postcode', REQUIRED_STRING),
        ...regionSelectEnabled && { region: variable('regionCode', REQUIRED_STRING) },
        street: [variable('street', REQUIRED_STRING)],
        telephone: variable('telephone', REQUIRED_STRING),
      },
    }],
  })
  .fields([
    field('cart', [
      cartShippingAddress,
      cartAvailablePaymentMethods,
      cartAppliedPaymentRestrictions,
      cartSelectedPaymentMethod,
    ]),
  ])
