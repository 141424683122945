import { getDefaultProduct, getProductsByBrandId } from '@nsf/catalog/repositories/ProductRepository.js'
import { getCategoriesByBrandId } from '@nsf/catalog/repositories/CategoryRepository.js'
import { fetchFilterPrefix, getFilter } from '@nsf/catalog/utils/BuilderUtils.js'
import { listingRequiredFields } from '@nsf/catalog/utils/FieldsUtils.js'
import { mapDataToProduct } from '@nsf/catalog/mappers/ProductMapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { Builder } from '@nsf/base/builders/Builder.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { withDefault } from '@nsf/core/Mapper.js'

import { getBrandById } from '@nsf/brands/repositories/BrandRepository.js'

const {
  rootConfig: {
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
} = useAppConfig()

// eslint-disable-next-line import/prefer-default-export
export class BrandBuilder extends Builder {
  async _fetchBrand() {
    const { brand } = await getBrandById(this.id())

    return {
      ...brand,
      ...(!brand?.id && { statusCode: 404 }),
    }
  }

  async _fetchProducts({ optionId }) {
    const page = Number(this.query('page')) || 1
    const sort = this.query('sort')
    const filter = this._getFilter()
    const search = this.query('search')
    const range = this.query('range')
    const categoryId = this.query('a_category_id')

    const {
      products, total, query, baseQuery,
    } = await getProductsByBrandId(optionId, {
      from: (page - 1) * productsPerPage,
      sort,
      filter,
      search,
      range,
      categoryId,
    })

    // If page is out of range of available pages we temporary redirect page to base brand path
    if (page > 1 && !products.length) {
      return {
        redirect: this._url,
        statusCode: 302,
      }
    }

    return {
      _products: products,
      _productsTotal: total,
      _productsQuery: query,
      _productsBaseQuery: baseQuery,
      _categoryId: categoryId,
      _page: page,
    }
  }

  async _fetchPromoActions({ optionId }) {
    const promoActions = await Query.promoActions()
      .only('brand_banners')
      .where('brand_banners.assigned_to', optionId)
      .get()

    const banners = promoActions.flatMap((promoAction) => promoAction.brand_banners)

    return { _banners: banners }
  }

  async _fetchCategories({ optionId }) {
    if (!this._isBrandCategoryFilterInDescription()) {
      return
    }

    const { categories } = await getCategoriesByBrandId(optionId)
    // eslint-disable-next-line consistent-return
    return { categories }
  }

  async _fetchProductLines({ productLine }) {
    if (!productLine) {
      return
    }
    const loaders = await productLine
      .map(async (line) => {
        const slugs = line.urlKey.split('/')

        const { hits, total } = await Query.productsAvailable()
          .only(listingRequiredFields)
          .where('drmax_brand_slug', slugs[0])
          .where('drmax_product_line_slug', slugs[1])
          .getWithTotal(3)

        // eslint-disable-next-line no-param-reassign
        line.topProducts = withDefault(getDefaultProduct, mapDataToProduct(hits))
        // eslint-disable-next-line no-param-reassign
        line.total = total
        return line
      })

    await Promise.all(loaders)
  }

  _isBrandCategoryFilterInDescription() {
    if (!this._data.layout) {
      return false
    }
    return !!(
      this._data?.dscTop?.includes('drmax_pagebuilder_brand_category_filter')
      || this._data?.dscTop?.includes('drmax_brand_category_filter')
      || this._data?.dscBottom?.includes('drmax_pagebuilder_brand_category_filter')
      || this._data?.dscBottom?.includes('drmax_brand_category_filter')
    )
  }

  _getFilter() {
    return getFilter(this.query('filter'), this._data)
  }

  async load() {
    await super.load([this._fetchBrand, fetchFilterPrefix])

    await super.load([this._fetchProducts, this._fetchCategories, this._fetchProductLines, this._fetchPromoActions])
  }
}
