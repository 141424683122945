import "core-js/modules/es.string.starts-with.js";
import { pbPregnancyCalculatorWidget, pbPregnancyWeeks, pbCountdownWidget, pbStreamsWidget, pbAdvisoryWidget, pbCategoriesWidget, pbCustomWidget, pbRecentArticlesWidget, pbSubCategoryBoxesWidget, pbDrugLeafletWidget, pbCookiebotWidget, pbAtDoctorLinkWidget, pbNewReturnFormWidget } from '@ioc/pageBuilder.js';
import { applyInlineStyles, createElementId, createStyleElementContent } from '../utils/PagebuilderStyleUtils.js';
import { renderChildren } from '../components/PageBuilder/index.js';
export default {
  name: 'VBlock',
  functional: true,
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$data$class, _ctx$props$data$class2, _ctx$props$data$class3, _ctx$props$data$class4, _ctx$props$data;
    var children = ctx.props.data.children || [];

    // Check block identifier
    if (children.length && children[0]['data-identifier'] === 'pregnancy-calculator-widget') {
      return h(pbPregnancyCalculatorWidget);
    }
    if (children.length && children[0]['data-identifier'] === 'pregnancy-weeks') {
      return h(pbPregnancyWeeks);
    }
    if (children.length && children[0]['data-identifier'] === 'countdown-widget') {
      return h(pbCountdownWidget, {
        props: {
          data: ctx.props.data
        }
      });
    }

    // Check element CSS class
    if (ctx.props.data.class === 'pregnancy-calculator-widget') {
      return h(pbPregnancyCalculatorWidget);
    }
    if (ctx.props.data.class === 'drug-leaflet-widget') {
      return h(pbDrugLeafletWidget);
    }
    if (ctx.props.data.class === 'advisory-widget') {
      return h(pbAdvisoryWidget);
    }
    if (ctx.props.data.class === 'categories-widget') {
      return h(pbCategoriesWidget);
    }
    if (ctx.props.data.class === 'cookiebot') {
      return h(pbCookiebotWidget);
    }
    if (ctx.props.data.class === 'custom-widget') {
      return h(pbCustomWidget, {
        props: {
          data: ctx.props.data
        }
      });
    }
    if (ctx.props.data.class === 'recent-articles-widget') {
      return h(pbRecentArticlesWidget);
    }
    if (ctx.props.data.class === 'at-doctor-link-widget') {
      return h(pbAtDoctorLinkWidget);
    }
    if ((_ctx$props$data$class = ctx.props.data.class) !== null && _ctx$props$data$class !== void 0 && _ctx$props$data$class.startsWith('subCategoryBoxes-widget')) {
      return h(pbSubCategoryBoxesWidget, {
        props: {
          className: ctx.props.data.class
        }
      });
    }
    if ((_ctx$props$data$class2 = ctx.props.data.class) !== null && _ctx$props$data$class2 !== void 0 && _ctx$props$data$class2.startsWith('countdown-')) {
      return h(pbCountdownWidget, {
        props: {
          data: ctx.props.data
        }
      });
    }
    if ((_ctx$props$data$class3 = ctx.props.data.class) !== null && _ctx$props$data$class3 !== void 0 && _ctx$props$data$class3.startsWith('streamswidget-')) {
      return h(pbStreamsWidget, {
        props: {
          data: ctx.props.data
        }
      });
    }
    if ((_ctx$props$data$class4 = ctx.props.data.class) !== null && _ctx$props$data$class4 !== void 0 && _ctx$props$data$class4.startsWith('new-return-form-widget')) {
      return h(pbNewReturnFormWidget, {
        props: {
          data: ctx.props.data
        }
      });
    }

    // we will parse styles and create style element
    var elementId = createElementId(ctx.props.data['data-pb-style']);
    var mobileStyle = (_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : _ctx$props$data['mobile-style'];
    var desktopStyle = applyInlineStyles(ctx.props.data, ctx.props.data.style, {
      width: true
    });
    var styleElementContent = createStyleElementContent(elementId, desktopStyle, mobileStyle);
    var styleElement = h('style', {}, styleElementContent);
    return h('div', {
      props: ctx.data.props,
      attrs: {
        id: elementId
      },
      class: "".concat(ctx.props.data.class ? "".concat(ctx.props.data.class, " ") : '', "pagebuilder-block")
    }, [renderChildren(h, ctx, children), styleElement]);
  }
};