<template>
  <div class="advisory-categories-widget">
    <div class="text">
      <h2>
        {{ $t('articles.Healthy life style') }}
      </h2>
    </div>
    <div class="box mt-2">
      <v-fetch-loader>
        <ul v-if="categories.length" class="categories categories--single" role="list">
          <v-categories-widget-category v-for="(category, id) in categories" :key="id" :category="category" />
        </ul>
      </v-fetch-loader>
    </div>
  </div>
</template>

<script>
import { getAdvisoryCategories } from '@nsf/articles/repositories/CategoryRepository.js'
import FetchLoader from '@nsf/base/components/FetchLoader.vue'
import CategoriesWidgetCategory from '../components/CategoriesWidgetCategory.vue'

export default {
  components: {
    'v-categories-widget-category': CategoriesWidgetCategory,
    'v-fetch-loader': FetchLoader,
  },

  data() {
    return { categories: [] }
  },

  async fetch() {
    const { categories } = await getAdvisoryCategories()
    this.categories = categories
  },
}
</script>
<style scoped>
/* stylelint-disable */
.advisory-categories-widget {
  .box {
    min-height: 540px;
  }
}
/* stylelint-enable */
</style>
