import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { applyInlineStyles, createElementId, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import { renderChildren } from '../components/PageBuilder/index.js';
export default {
  name: 'VHeading',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$data$tag, _ctx$props$data, _ctx$props, _ctx$props$data2;
    var children = ctx.props.data.children || [];
    var tag = (_ctx$props$data$tag = ctx.props.data.tag) !== null && _ctx$props$data$tag !== void 0 ? _ctx$props$data$tag : 'div';

    // we will parse styles and create style element
    var elementId = createElementId(ctx.props.data['data-pb-style']);
    var mobileStyle = ctx.props.data['mobile-style'];
    var desktopStyle = applyInlineStyles(ctx.props.data, (_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : _ctx$props$data.style, {
      width: true
    });
    var styleElementContent = createStyleElementContent(elementId, desktopStyle, mobileStyle);
    var styleElement = h('style', {}, styleElementContent);
    var data = _objectSpread(_objectSpread({
      class: "pagebuilder-heading ".concat(((_ctx$props = ctx.props) === null || _ctx$props === void 0 ? void 0 : (_ctx$props$data2 = _ctx$props.data) === null || _ctx$props$data2 === void 0 ? void 0 : _ctx$props$data2.class) || '')
    }, ctx.props.data.id && {
      attrs: {
        id: ctx.props.data.id
      }
    }), {}, {
      attrs: {
        id: elementId
      }
    });
    return h(tag, data, [renderChildren(h, ctx, children), styleElement]);
  }
};