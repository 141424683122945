import { toOpeningHours, isOpen } from '../utils/DateUtils.js'

/**
 * Used for Pharmacy API
 *
 * @param {Object[]} responseJson
 *
 * @returns {Object[]}
 */
export default function(responseJson) {
  return responseJson.data
    .map((place, index) => ({
      pickupPlaceId: String(place.id),
      key: `place-${index}`,
      street: place.location?.street,
      city: place.location?.city,
      zip: place.location?.zipCode,
      district: null,
      region: place.location?.region,
      regionId: null,
      reservationAvailable: false,
      limitReservation: null,
      reservationStatus: null,
      orderAvailable: false,
      limitOrder: null,
      placeStatus: null,
      reservationRXAvailable: false,
      limitRXReservation: null,
      reservationOTCAvailable: false,
      limitOTCReservation: null,
      callcentrumAvailable: false,
      latitude: Number(place.location?.latitude),
      longitude: Number(place.location?.longitude),
      priority: 1,
      phone: null,
      note: place.note,
      title: place.title,
      urlKey: String(place.id),
      openingDate: null,
      closureDate: null,
      pharmacistName: null,
      pharmacistImageUrl: null,
      pharmacistChiefName: null,
      pharmacistChiefImageUrl: null,
      pharmacistExpertName: null,
      pharmacistExpertImageUrl: null,
      pharmacyImageUrl: place.image,
      shoppingMall: place.location?.description,
      destinationId: null,
      htmlId: `place${place.id}`,
      open: isOpen(place.openingHours),
      openHours: toOpeningHours(place.openingHours),
      type: place.type,
      leadTimeReservationRX: null,
      leadTimeReservationOTC: null,
      leadTimeOrders: null,
      disabled: false,
      hidden: place.visibility?.list === false,
      pharmacy_type: null,
      additionalParams: place.additionalParams || {},
      services: [],
    }))
}
