<template>
  <placement-view-provider
    :placement-name="placementName"
  >
    <div
      v-if="!$fetchState.pending && hasBanners"
      :id="elementId"
      ref="bannerblocks"
      class="pagebuilder-banner-blocks"
    >
      <div>
        <v-brands
          v-if="hasBrandFilter && brands.length"
          :brands="brands"
          :category-path="''"
          :loading="false"
          :selected-brand-id="selectedBrandId"
          @onChangeBrand="onChangeBrand"
        />
      </div>

      <v-homepage-banner
        v-if="isCarousel"
        class="mt-4"
        :banners="bannersToShow"
      />

      <div
        v-if="isBannersInRow"
        class="action-sale section order-4"
      >
        <ul class="commercial row row--largegap">
          <li
            v-for="(banner) in bannersToShow"
            :key="banner.id"
            class="col-12 col-sm-6 col-md-4 banners-in-row-item"
          >
            <v-banner-block-item
              :banner="banner"
              :position="banner.position"
              :media="'test'"
            />
          </li>
        </ul>

        <v-pagination
          v-if="hasPagination"
          :total="bannersFiltered.length"
          :change-url="false"
          :current-page-number="page"
          :per-page="limit"
          class="mt-2 mb-3"
          @pageClick="pageClick"
        />
      </div>

      <div
        v-if="isBannersListed"
        class="action-sale section order-4"
      >
        <template v-if="isSlider">
          <v-listed-banners-slider :banners="bannersToShow" />
        </template>

        <template v-else>
          <div
            v-for="(banner) in bannersToShow"
            :key="banner.id"
            class="banner-block-item mb-1"
          >
            <v-banner-block-item
              :banner="banner"
              :position="banner.position"
              :media="'test'"
            />
          </div>

          <v-pagination
            v-if="hasPagination"
            :total="bannersFiltered.length"
            :change-url="false"
            :current-page-number="page"
            :per-page="limit"
            class="mt-2 mb-3"
            @pageClick="pageClick"
          />
        </template>
      </div>
      <style v-if="styleContent" v-text="styleContent" ></style>
    </div>
  </placement-view-provider>
</template>

<script>

import { toChunks } from '@nsf/utils/ArrayUtils.js'
import { applyInlineStyles, createElementId, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js'
import { getBrandsByOptionIds } from '@nsf/brands/repositories/BrandRepository.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import PlacementViewProvider from '@nsf/gtm/providers/PlacementViewProvider.js'
import { getPromoActionsBanners } from '../repositories/PromoActionRespository.js'

const {
  public: {
    magentoUrl,
  },
} = useRuntimeConfig()

export default {
  name: 'VBannerBlocks',

  components: {
    PlacementViewProvider,
    'v-brands': () => import('../components/PageBuilder/Filters/Brands.vue'),
    'v-homepage-banner': () => import('@nsf/homepage/components/HomepageBanner.vue'),
    'v-pagination': () => import('../components/Pagination.vue'),
    'v-listed-banners-slider': () => import('../components/PageBuilder/ListedBannersSlider.vue'),
    'v-banner-block-item': () => import('../components/PageBuilder/BannerBlocks/Item.vue'),
  },

  inject: ['WindowDimensionProvider'],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      banners: [],
      chunks: [],
      bannersAll: [],
      brands: [],
      selectedBrandId: 0,
      page: 1,
    }
  },

  // eslint-disable-next-line consistent-return
  async fetch() {
    if (this.bannersAll.length && this.hasPagination) {
      return this.setBannersFromChunks()
    }

    if (this.imageTypeIndex) {
      const bannersAll = await getPromoActionsBanners(this.entityIds, this.imageTypeIndex)
      this.bannersAll = bannersAll.map((banner, index) => ({ ...banner, position: index + 1 }))

      if (this.hasBrandFilter && this.imageTypeIndex === 'brand_banners') {
        const brandOptionIdsSet = new Set()

        for (const banner of this.bannersAll) {
          const arr = banner.assigned_to || []
          arr.filter((item) => !!item).forEach((item) => brandOptionIdsSet.add(item))
        }

        const brandOptionIds = Array.from(brandOptionIdsSet)
        this.brands = (await getBrandsByOptionIds(brandOptionIds))
          .map((item) => ({ title: item.name, id: item.optionId, count: 0 }))

        // we will count have many banners are for each brand
        const bannersMap = {}

        this.bannersAll.forEach((item) => {
          const ids = item?.assigned_to || []
          for (const id of ids) {
            if (!(id in bannersMap)) {
              bannersMap[id] = 0
            }

            bannersMap[id] += 1
          }
        })

        for (const brand of this.brands) {
          brand.count = bannersMap[brand.id] || 0
        }

        this.brands.unshift({
          title: this.$t('base.filters.all'),
          id: 0,
          count: this.bannersAll.length,
        })
      }
    }

    if (this.hasPagination) {
      this.chunkBannersPerPage()
      return this.setBannersFromChunks()
    }

    this.banners = this.bannersFiltered.slice(0, this.limit) || []
  },

  computed: {
    bannersFiltered() {
      if (this.hasBrandFilter && this.selectedBrandId) {
        return this.bannersAll.filter((item) => item?.assigned_to?.includes(`${this.selectedBrandId}`))
      }

      return this.bannersAll
    },

    bannersToShow() {
      switch (this.data['data-appearance']) {
        case 'carousel':
          return this.bannersFiltered.map((item) => ({
            id: item.banner_id,
            name: item.title,
            description: item.desc,
            position: item.position,
            source: item.source,
            imagePath: this.imagePath(item.image),
            imageMobilePath: this.imagePath(item.image_mobile),
            urlLink: item.url_link,
            backgroundColor: '#fff',
          }))
        case 'banners-in-row':
          return this.banners.map((item) => ({
            id: item.banner_id,
            title: item.title,
            description: item.desc,
            position: item.position,
            source: item.source,
            opts: [
              {
                path: this.imagePath(item.image_mobile),
                w: 360,
                h: 330,
                fit: 'contain',
              },
            ],
            urlLink: item.url_link,
          }))
        case 'listed-banners':
          return this.banners.map((item) => ({
            id: item.banner_id,
            title: item.title,
            description: item.desc,
            position: item.position,
            source: item.source,
            opts: [
              {
                path: this.imagePath(item.image_mobile),
                w: 360,
                h: item.image_size?.image_mobile ? this.listedImageHeight(item.image_size.image_mobile, 360) : 150,
                media: this.WindowDimensionProvider.forMobile,
              },
              {
                path: this.imagePath(item.image),
                w: 1140,
                h: item.image_size?.image ? this.listedImageHeight(item.image_size.image, 1140) : 150,
              },
            ],
            urlLink: item.url_link,
          }))
        default:
          return []
      }
    },

    hasBanners() {
      return !!this.banners?.length
    },

    placementName() {
      let name = 'banner-widget'
      if (this.settings?.widget_name) {
        name += `-${this.settings.widget_name}`
      }
      return name
    },

    isCarousel() {
      return this.data['data-appearance'] === 'carousel'
    },

    isBannersInRow() {
      return this.data['data-appearance'] === 'banners-in-row'
    },

    isBannersListed() {
      return this.data['data-appearance'] === 'listed-banners'
    },

    hasBrandFilter() {
      return !!(Number(this.settings.enable_brand_filter))
    },

    isDesktopSlider() {
      return this.data.children?.[0]?.desktop_slider === '1'
    },

    isMobileSlider() {
      return this.data.children?.[0]?.mobile_slider === '1'
    },

    isSlider() {
      return (this.WindowDimensionProvider.isMobile && this.isMobileSlider)
        || (!this.WindowDimensionProvider.isMobile && this.isDesktopSlider)
    },

    hasPagination() {
      return Number(this.settings.enable_paging) && this.limit
    },

    entityIds() {
      return {
        // eslint-disable-next-line camelcase
        ...this.settings.promo_action_id && { promo_actions: this.settings.promo_action_id },
        ...this.settings.rule_id && { salesrule: this.settings.rule_id },
      }
    },

    elementId() {
      return createElementId(this.data?.['data-pb-style'])
    },

    imageTypeIndex() {
      switch (this.settings.image_type) {
        case 'brand_page':
          return 'brand_banners'
        case 'category':
          return 'category_banners'
        case 'homepage':
          return 'homepage_banners'
        case 'sales_page':
          return 'sales_banners'
        case 'sidebanner':
          return 'sidebar_banners'
        case 'cms_page':
          return 'cms_page_banners'
        case 'cms_category':
          return 'cms_category_banners'
        default:
          return null
      }
    },

    limit() {
      return Number(this.settings.limit) || 10000
    },

    settings() {
      return this.data.children[0]
    },

    mobileStyle() {
      return this.data?.['mobile-style']
    },

    desktopStyle() {
      return applyInlineStyles(this.data, this.data.style, { width: true })
    },

    styleContent() {
      return createStyleElementContent(this.elementId, this.desktopStyle, this.mobileStyle)
    },
  },

  watch: {
    '$route.query':
      '$fetch',
  },

  methods: {
    chunkBannersPerPage() {
      this.chunks = toChunks(this.bannersFiltered, this.limit)
    },

    imagePath(imageUrl) {
      return `${magentoUrl}/media/drmax/action_banners/${imageUrl}`
    },

    listedImageHeight(imageSize, renderWidth) {
      const dim = imageSize.split('x')
      const imageWidth = parseFloat(dim[0])
      const imageHeight = parseFloat(dim[1])
      return Math.round(imageHeight * (renderWidth / imageWidth))
    },

    setBannersFromChunks() {
      this.banners = this.chunks[this.page - 1]
    },

    onChangeBrand(brand) {
      this.selectedBrandId = brand.id
      this.page = 1

      this.banners = this.bannersFiltered.slice(0, this.limit) || []

      if (this.hasPagination) {
        this.chunkBannersPerPage()
        this.setBannersFromChunks()
      }
    },

    pageClick(page) {
      this.page = page
      this.chunkBannersPerPage()
      this.setBannersFromChunks()
      this.scrollTop()
    },

    scrollTop() {
      const target = this.$refs.bannerblocks

      let offset = 30
      if (window.innerWidth < 768) {
        offset += document.querySelector('#header').clientHeight
      }

      const bodyRect = document.body.getBoundingClientRect().top
      const targetRect = target.getBoundingClientRect().top
      const targetPosition = targetRect - bodyRect
      const offsetPosition = targetPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss">
/* stylelint-disable */
.pagebuilder-banner-blocks {
  margin: 20px 0;

  .action-sale picture,
  .action-sale::v-deep img {
    width: 100%;
  }

  .navigation-tab li {
    list-style: disc;
  }

  .banners-in-row-item {
    aspect-ratio: 360 / 330;
  }

  .banner-block-item {
    width: 100%;
    aspect-ratio: 360 / 150;

    @media screen and (min-width: $lg-min) {
      aspect-ratio: 1140 / 150;
    }
  }
}
/* stylelint-enable */
</style>
