import { renderChildren } from '@nsf/base/components/PageBuilder/index.js';
export default {
  name: 'VBody',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    return h('div', renderChildren(h, ctx, children));
  }
};