import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getPlacementBanners } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

const {
  productRelationshipLoaderPlacement: {
    placementCategoryBannerLimit,
  },
} = useAppConfig()

export default async function () {
  const userId = ''
  const config = this?.config ?? { limit: placementCategoryBannerLimit }
  return await getPlacementBanners('categoryBanner', userId, config, this?.ctx)
}
