<template functional>
  <component
    v-bind="data.attrs"
    :is="$options.componentTag(props.link)"
    :class="[data.staticClass].concat(data.class).concat([
      'drmax-btn',
      `drmax-btn--${props.variant}`,
      `drmax-btn--${props.size}`,
      `drmax-btn-icon--${props.iconPosition}`,
      {'drmax-btn--only-icon': $options.hasOnlyIcon($slots.icon, $slots.default)},
      {'drmax-btn--loading': props.loading},
      {'drmax-btn--stretch': props.stretch},
    ])"
    :disabled="props.disabled || props.loading"
    :to="props.link"
    v-on="listeners"
  >
    <div v-if="$options.hasIcon($slots.icon)" class="drmax-btn-icon-slot">
      <div v-if="!props.loading" :class="[props.iconWrapperClasses]">
        <slot name="icon" />
      </div>
      <span
        v-else
        class="spinner-border spinner-border-sm drmax-btn-icon-spinner"
        aria-hidden="true"
      />
    </div>
    <div v-if="$options.hasText($slots.default)" class="drmax-btn-text-div">
      <span
        class="drmax-btn-text-slot"
        :style="{ opacity: $options.isTextInvisible($slots.icon, props.loading) ? '0' : '1' }"
      ><slot /></span>
      <div
        v-if="$options.isTextInvisible($slots.icon, props.loading)"
        class="drmax-btn-text-spinner-wrapper"
      >
        <span
          v-show="!$options.hasIcon($slots.icon) && props.loading"
          class="spinner-border spinner-border-sm drmax-btn-text-spinner"
          aria-hidden="true"
        />
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'DrmaxButton',

  props: {
    variant: {
      type: String,
      validator: (value) => ['primary', 'secondary', 'naked', 'blue', 'red'].includes(value),
      default: 'primary',
    },
    size: {
      type: String,
      validator: (value) => ['large', 'medium', 'small'].includes(value),
      default: 'medium',
    },
    iconPosition: {
      type: String,
      validator: (value) => ['left', 'right'].includes(value),
      default: 'left',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, null],
      default: null,
    },
    iconWrapperClasses: {
      type: String,
      default: '',
    },
  },

  componentTag(link) {
    return link ? 'v-link' : 'button'
  },

  hasIcon(iconSlot) {
    return !!iconSlot
  },

  hasText(defaultSlot) {
    return !!defaultSlot
  },

  hasOnlyIcon(iconSlot, defaultSlot) {
    return this.hasIcon(iconSlot) && !this.hasText(defaultSlot)
  },

  isTextInvisible(iconSlot, loading) {
    return !this.hasIcon(iconSlot) && loading
  },
}
</script>

<style lang="scss">
/* stylelint-disable */
@mixin button-color($bg-color, $border-color, $gradient-color, $border-opacity) {
  border: 1px solid rgba($border-color, $border-opacity);
  background: $bg-color linear-gradient(180deg, rgba(0, 0, 0, 0), $gradient-color);
}
@mixin button-elevation($elevation: null, $inset-color: null) {
  box-shadow: $elevation, 0px 1px 0px 0px $inset-color inset;
}
.drmax-btn {
  display: inline-flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  overflow: hidden;
  color: white;
  cursor: pointer;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.24);
  gap: 6px;
  min-width: 32px;
  transition: background-color 0.1s, color 0.1s, border-color 0.1s;

  &.drmax-btn--stretch {
    min-width: 100%;
  }

  &:focus-visible {
    outline: 2px solid $yellow-200;
    outline-offset: 2px;
  }
  &:disabled, &[disabled="disabled"] {
    cursor: default;
    text-shadow: none;
    box-shadow: none;
    > .drmax-btn-icon-slot > div {
      filter: none;
    }
  }
  .drmax-btn-icon-spinner, .drmax-btn-text-spinner {
    border: 0.2em solid rgba(0, 0, 0, 0.24);
    border-right-color: currentColor;
  }
  &.drmax-btn-icon--right {
    flex-direction: row-reverse;
  }
  > .drmax-btn-icon-slot > div {
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.24));
    align-items: center;
  }
  // handle different sizes
  &.drmax-btn--large {
    border-radius: 5px;
    min-height: 52px;
    padding: 0 25px;
    gap: 10px;
    @include body-medium-bold;
    .drmax-btn-icon-spinner, .drmax-btn-text-spinner {
      margin: 4px;
      width: 24px;
      height: 24px;
    }
    .drmax-btn-icon-slot > div > * {
      width: 32px;
      height: 32px;
    }
    &:has(.drmax-btn-icon-slot) {
      &.drmax-btn-icon--left {
        padding-left: 16px
      }
      &.drmax-btn-icon--right {
        padding-right: 16px
      }
    }
    &.drmax-btn--only-icon.drmax-btn--only-icon {
      padding: 0 10px;
    }
  }
  &.drmax-btn--medium {
    border-radius: 4px;
    min-height: 40px;
    padding: 0 19px;
    @include label-large-bold;
    .drmax-btn-icon-spinner, .drmax-btn-text-spinner {
      margin: 2px;
      width: 20px;
      height: 20px;
    }
    .drmax-btn-icon-slot > div > * {
      width: 24px;
      height: 24px;
    }
    &:has(.drmax-btn-icon-slot) {
      &.drmax-btn-icon--left {
        padding-left: 11px
      }
      &.drmax-btn-icon--right {
        padding-right: 11px
      }
    }
    &.drmax-btn--only-icon.drmax-btn--only-icon {
      padding: 0 8px;
    }
  }
  &.drmax-btn--small {
    border-radius: 3px;
    min-height: 32px;
    padding: 0 13px;
    @include label-medium-bold;
    .drmax-btn-icon-spinner, .drmax-btn-text-spinner {
      margin: 1px;
      width: 16px;
      height: 16px;
    }
    .drmax-btn-icon-slot > div > * {
      width: 18px;
      height: 18px;
    }
    &:has(.drmax-btn-icon-slot) {
      &.drmax-btn-icon--left {
        padding-left: 9px
      }
      &.drmax-btn-icon--right {
        padding-right: 9px
      }
    }
    &.drmax-btn--only-icon.drmax-btn--only-icon {
      padding: 0 7px;
    }
  }
  // handle different colors
  &.drmax-btn--primary {
    @include button-color($green-400, $green-800, $green-500, 0.8);
    @include button-elevation($elevation-hard-2dp, $green-300);
    &:focus-visible, &:hover {
      @include button-color($green-300, $green-700, $green-400, 0.72);
      @include button-elevation($elevation-hard-2dp, $green-200);
      color: #fff;
    }
    &:hover {
      @media (hover: hover){
        @include button-color($green-300, $green-700, $green-400, 0.72);
        @include button-elevation($elevation-hard-2dp, $green-200);
        color: #fff;
      }
    }
    &:disabled, &[disabled="disabled"] {
      @include button-color($green-25, $green-600, $green-50, 0.24);
      @include button-elevation(null, white);
      color: #08210261
    }
    &:active:not(:disabled), &.drmax-btn--loading {
      @include button-color($green-500, $green-900, $green-600, 0.72);
      @include button-elevation($elevation-hard-1dp, $green-400);
    }
    &.drmax-btn--loading {
      color: white;
    }
  }

  &.drmax-btn--secondary {
    color: $blue-700;
    text-shadow: none;
    > .drmax-btn-icon-slot > div {
      filter: none;
    }
    @include button-color(white, $gray-200, $gray-25, 0.8);
    @include button-elevation($elevation-medium-2dp, white);
    &:hover, &:focus-visible {
      @include button-color(white, $blue-500, $gray-25, 0.56);
      @include button-elevation($elevation-medium-2dp, white);
      color: $blue-600;
    }
    &:disabled, &[disabled="disabled"] {
      @include button-color($gray-25, $gray-600, $gray-50, 0.24);
      @include button-elevation(null, white);
      color:#00000061
    }
    &:active:not(:disabled), &.drmax-btn--loading {
      @include button-color($blue-50, $blue-700, $blue-100, 0.56);
      @include button-elevation($elevation-medium-1dp, white);
      color: $blue-700;
    }
  }
  &.drmax-btn--naked {
    color: $blue-600;
    text-shadow: none;
    > .drmax-btn-icon-slot > div {
      filter: none;
    }
    background: transparent;
    text-decoration-line: underline;
    border: none;
    box-shadow: none;
    &:hover, &:focus-visible {
      color: $blue-500
    }
    &:disabled, &[disabled="disabled"] {
      color: #00000061
    }
    &:active, &.drmax-btn--loading {
      color: $blue-700;
    }
    &:disabled {
      color: #00000061
    }
    &:active:not(:disabled), &.drmax-btn--loading {
      color: $blue-700;
    }
  }
  &.drmax-btn--blue {
    @include button-color($blue-400, $blue-800, $blue-500, 0.8);
    @include button-elevation($elevation-hard-2dp, $blue-300);
    &:hover, &:focus-visible {
      @include button-color($blue-300, $blue-700, $blue-400, 0.72);
      @include button-elevation($elevation-hard-2dp, $blue-200);
      color: #fff;
    }
    &:disabled, &[disabled="disabled"] {
      @include button-color($blue-25, $blue-600, $blue-50, 0.24);
      @include button-elevation(null, white);
      color:#021D3561
    }
    &:active:not(:disabled), &.drmax-btn--loading {
      @include button-color($blue-500, $blue-900, $blue-600, 0.72);
      @include button-elevation($elevation-hard-1dp, $blue-400);
    }
    &.drmax-btn--loading {
      color: white;
    }
  }
  &.drmax-btn--red {
    @include button-color($red-400, $red-800, $red-500, 0.8);
    @include button-elevation($elevation-hard-2dp, $red-300);
    &:hover, &:focus-visible {
      @include button-color($red-300, $red-700, $red-400, 0.72);
      @include button-elevation($elevation-hard-2dp, $red-200);
      color: #fff;
    }
    &:disabled, &[disabled="disabled"] {
      @include button-color($red-25, $red-600, $red-50, 0.24);
      @include button-elevation(null, white);
      color:#3F020A61
    }
    &:active:not(:disabled), &.drmax-btn--loading {
      box-shadow: $elevation-hard-1dp;
      @include button-color($red-500, $red-900, $red-600, 0.8);
      @include button-elevation($elevation-hard-1dp, $red-400);
    }
    &.drmax-btn--loading {
      color: white;
    }
  }
  > .drmax-btn-icon-slot {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// wrapper that ensures text only button stays the same width while in leading state
.drmax-btn-text-div {
  position: relative;
  > .drmax-btn-text-spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* stylelint-enable */
</style>
