import { required, maxLength } from 'vuelidate/lib/validators/index.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  validations: {
    emailMaxLength,
  },
} = useAppConfig()

export const EMAIL_VALIDATION_REGEX = /^(?=.{1,64}@.{4,255}$)([A-Za-z0-9_+\-.]{1,64})@([a-zA-Z0-9-]{1,63}\.)+([A-Za-z]{2,63})$/
export const RESTRICTED_DOMAIN_EMAIL_REGEX = /@mapn(\.|$)/

export function emailValidation({ isRestrictedDomainEnabled = false } = {}) {
  return {
    required,
    email: (value) => EMAIL_VALIDATION_REGEX.test(value),
    maxLength: maxLength(emailMaxLength),
    restrictedDomain: (value) => (isRestrictedDomainEnabled ? !RESTRICTED_DOMAIN_EMAIL_REGEX.test(value) : true),
  }
}

export function emailValidationErrors(ctx) {
  return {
    required: ctx.$i18n.t('validations.Email is required'),
    email: ctx.$i18n.t('validations.Email is not in correct format'),
    maxLength: ctx.$i18n.t('validations.Email can contain a maximum of x characters', { length: emailMaxLength }),
    restrictedDomain: ctx.$i18n.t('validations.Sorry you cannot register the loyalty card with this email address'),
  }
}
