/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/3173482574/DataLayer+Placement+entity
 */
import placementTypeMapper from '@nsf/gtm/mappers/placementTypeMapper.js'
import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { formatProduct } from '../utils/product.js'

export default (placement, data, isLoggedIn = false) => {
  const event = {
    event: 'placement_view',
    placement: {
      ...placement,
      type: placementTypeMapper(placement.type),
    },
  }

  switch (placement.type) {
    case 'products':
      event.placement[placement.type] = data.map((product) => formatProduct(product, '', isLoggedIn))
      break
    default:
      event.placement[placement.type] = data
  }

  usePushEvent(event)
}
