import { mapDataToModel } from '@nsf/core/Mapper.js'
import { isArray } from '@nsf/core/Utils.js'
import { searchRequiredFields } from '@nsf/catalog/utils/FieldsUtils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
// eslint-disable-next-line import/no-cycle
import { getProductsByIds } from '@nsf/catalog/repositories/ProductRepository.js'
import { LOADER_LABEL_SEARCH, LOADER_LABEL_MAGENTO } from '@nsf/catalog/mixins/IsProduct.js'
import { getDefaultTotalRating } from '@nsf/catalog/mappers/ReviewMapper.js'

const {
  catalog: {
    product: {
      reviewsEnabled,
    },
  },
} = useAppConfig()

const keepOriginal = (data) => data

class ProductMap {
  constructor(data, translations = {}) {
    this.data = mapDataToModel(
      data,
      {
        description: keepOriginal,
        ...translations,
      },
    )
  }

  getData() {
    return this.data
  }

  extendData(extendObj) {
    this.data = isArray(this.data) ? this.data.map((p) => ({ ...extendObj, ...p })) : { ...extendObj, ...this.data }
  }

  addLoaderLabel() {
    this.extendData({ loaderLabel: LOADER_LABEL_MAGENTO })
    return this
  }

  addReviewLabel() {
    if (!reviewsEnabled) {
      return this
    }
    this.extendData(getDefaultTotalRating())
    return this
  }

  // puts all multichoice salesRule duplicates into one with array of drmaxValidFor instead of single string value
  convertSalesRulesValidForToArray() {
    if (!Array.isArray(this.data.salesRules) || this.data.salesRules.length === 0) {
      return this
    }

    const result = []
    for (const salesRule of this.data.salesRules) {
      const existingRule = result.find((r) => r.ruleId === salesRule.ruleId)
      if (existingRule) {
        existingRule.drmaxValidFor.push(salesRule.drmaxValidFor)
      } else {
        result.push({ ...salesRule, drmaxValidFor: [salesRule.drmaxValidFor] })
      }
    }

    this.data.salesRules = result

    return this
  }
}

export const mapDataToProduct = (data, translations) => {
  const product = new ProductMap(data, translations)
    .addLoaderLabel()
    .addReviewLabel()
    .convertSalesRulesValidForToArray()
    .getData()

  return product
}

export const mapSearchProductsToElastic = async (searchProducts) => {
  const productsId = searchProducts.map((product) => Number(product.id))
  const { products } = await getProductsByIds(productsId, {
    callback: (query) => query.only(searchRequiredFields),
    size: productsId.length,
  })

  const updatedHits = searchProducts.map((hit) => {
    if (hit.indexType !== 'PRODUCTS') {
      return hit
    }

    const esProduct = products.find((product) => product.id === Number(hit.id))
    if (!esProduct) {
      return false
    }

    esProduct.indexType = 'PRODUCTS'

    // merge data from ES and search. ES properties are more important
    const commonProduct = { ...hit, ...esProduct, loaderLabel: LOADER_LABEL_SEARCH }
    commonProduct.name = commonProduct.name ?? esProduct.drmax_title_row1

    return commonProduct
  }).filter((item) => !!item)

  return mapDataToProduct(updatedHits)
}
