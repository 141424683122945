<template functional>
  <div
    v-bind="data.attrs"
    :class="props.defaultClass"
    v-on="listeners"
  >
    <v-link
      :to="props.link"
      class="btn btn-link pl-0"
      data-test-id="back-button"
    >
      <svg class="mr-2 text-body">
        <use xlink:href="~/assets/icons/icons.svg#chevron-left" />
      </svg>
      {{ props.trans ? parent.$t(props.trans) : props.title }}
    </v-link>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    trans: {
      type: String,
      default: null,
    },
    defaultClass: {
      type: String,
      default: 'section mt-3 order-9',
    },
  },
}
</script>
