import { applyInlineStyles, createElementId, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import { renderChildren } from '../components/PageBuilder/index.js';
export default {
  name: 'VText',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$data;
    var children = ctx.props.data.children || [];
    var className = "".concat(ctx.props.data.class ? "".concat(ctx.props.data.class, " ") : '', "text pagebuilder-text");

    // we will parse styles and create style element
    var elementId = createElementId(ctx.props.data['data-pb-style']);
    var mobileStyle = (_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : _ctx$props$data['mobile-style'];
    var desktopStyle = applyInlineStyles(ctx.props.data, ctx.props.data.style, {
      width: true
    });
    var styleElementContent = createStyleElementContent(elementId, desktopStyle, mobileStyle);
    var styleElement = h('style', {}, styleElementContent);
    return h('div', {
      class: className,
      attrs: {
        id: elementId
      }
    }, [h('div', renderChildren(h, ctx, children)), styleElement]);
  }
};