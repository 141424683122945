import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
import { isServer } from '@nsf/core/Utils.js';
import { applyInlineStyles, extractMobileBackgroundStyle } from '../utils/PagebuilderStyleUtils.js';
import { renderChildren, extractStyle, extractClass, getPropsData } from '../components/PageBuilder/index.js';
import UserOnlyContent from '../components/PageBuilder/UserOnlyContent.vue';
var hasFullAppearance = function hasFullAppearance(ctx) {
  return getPropsData(ctx, 'data-appearance').startsWith('full');
};
export default {
  name: 'VRow',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$data, _children$, _children$$children, _children$$children$;
    var children = ctx.props.data.children || [];
    var className = extractClass(ctx.props.data, 1);
    var marginRowDisabled = ctx.props.data.marginRowDisabled || false;
    var data = {
      class: "pagebuilder-row section ".concat(marginRowDisabled ? '' : 'mt-2', " ").concat(className),
      style: '',
      attrs: {
        'data-test-id': 'cms-row-wrapper'
      }
    };
    var dataInner = {
      class: 'section-container',
      style: ''
    };

    // special classes for alerts
    if (className.includes('alert-info')) {
      data.class += ' alert text-center';
      return h('div', data, renderChildren(h, ctx, children));
    }
    if (className.includes('alert-warning')) {
      data.class += ' alert';
      return h('div', data, renderChildren(h, ctx, children));
    }
    var elementWithStyles = ctx.props.data;

    // if appearance is set to container, first child contains styles and background
    if (((_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : _ctx$props$data['data-appearance']) === 'contained') {
      var _ctx$props$data$child;
      elementWithStyles = (_ctx$props$data$child = ctx.props.data.children) === null || _ctx$props$data$child === void 0 ? void 0 : _ctx$props$data$child[0];
    }

    // we will extract inline styles
    data.style = applyInlineStyles(elementWithStyles, extractStyle(ctx.props.data, 1), {
      width: true,
      margin: true,
      padding: true
    });

    // mobile background
    dataInner.style = extractMobileBackgroundStyle(elementWithStyles);

    // we will apply classes for full appearance (width: 100%)
    if (hasFullAppearance(ctx)) {
      data.class += ' section-full';
      if (getPropsData(ctx, 'data-appearance') === 'full-width') {
        dataInner.class += ' container';
      }
    }
    var userOnlyContent = getPropsData(ctx, 'data-user-only-content');
    // value 1 means that content is only for logged-in users
    // value 2 means that content is only for NOT logged-in users
    var useUserOnlyContentComponent = userOnlyContent === '1' || userOnlyContent === '2';

    // Check if we need align columns content vertical center
    if ((children === null || children === void 0 ? void 0 : (_children$ = children[0]) === null || _children$ === void 0 ? void 0 : (_children$$children = _children$.children) === null || _children$$children === void 0 ? void 0 : (_children$$children$ = _children$$children[0]) === null || _children$$children$ === void 0 ? void 0 : _children$$children$['data-content-type']) === 'column-group') {
      var _children$2, _children$2$style, _children$3, _children$3$style;
      if (children !== null && children !== void 0 && (_children$2 = children[0]) !== null && _children$2 !== void 0 && (_children$2$style = _children$2.style) !== null && _children$2$style !== void 0 && _children$2$style.includes('justify-content: center;')) {
        children[0].children[0].alignItems = 'center';
      } else if (children !== null && children !== void 0 && (_children$3 = children[0]) !== null && _children$3 !== void 0 && (_children$3$style = _children$3.style) !== null && _children$3$style !== void 0 && _children$3$style.includes('justify-content: flex-end;')) {
        children[0].children[0].alignItems = 'flex-end';
      }
    }
    var rendered = renderChildren(h, ctx, useUserOnlyContentComponent ? [] : children);
    if (isServer() === false && useUserOnlyContentComponent) {
      var rowId = getPropsData(ctx, 'data-content-id');
      var _ctx$parent$$store$st = ctx.parent.$store.state['_base/pagebuilder'],
        entityType = _ctx$parent$$store$st.entityType,
        entityId = _ctx$parent$$store$st.entityId;
      var props = {
        props: {
          entityId: entityId,
          entityType: entityType,
          rowId: rowId,
          onlyForLoggedIn: userOnlyContent === '1',
          children: children
        }
      };
      rendered = [h(UserOnlyContent, props)];
    }
    return h('div', data, [h('div', dataInner, rendered)]);
  }
};