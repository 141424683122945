<template>
  <div
    class="skin-analyzer-widget"
  >
    <client-only>
      <script
        data-integration="iframe"
        :data-inject-url="url"
        data-container="div#iframe-window"
        src="https://storage.googleapis.com/fe-scripts/js/saas-integration.js?v.1"
      />
      <div id="iframe-window" />
      <v-button id="haut-open-iframe">
        {{ $t('base.Start scan') }}
      </v-button>
    </client-only>
  </div>
</template>

<script>
import Button from '@nsf/ui-library/components/atoms/Button.vue'

export default {
  name: 'SkinAnalyzerWidget',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    'v-button': Button,
  },

  computed: {
    url() {
      return this.data?.children[0]?.url
    },
  },
}
</script>
