import icons from './icons.mjs'

export default {
  ...icons,
  agreementsApiTimeout: 3000,
  /**
   * dummy object to prevent epic crashes caused by using old config
   * DO NOT USE THIS!!!
   */
  clickAndCollect: {},
  testingEmailSuffixes: [
    '@magento2.drmax.cz',
    '@eshop.drmax.net',
    '@obelix.test',
  ],

  timeApiTakesTooLong: 5000, // in ms
  timeApiTimeout: 10000, // in ms
}
