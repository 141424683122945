import { mapState } from 'vuex'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  rootConfig: {
    global: {
      myAccountSorpEnabled,
    },
  },
} = useAppConfig()

export default {
  created() {
    this.myAccountSorpEnabled = myAccountSorpEnabled
  },

  computed: {
    ...mapState(
      'my-account/user',
      [
        'user',
        'isLoggedIn',
        'isLoggingIn',
        'isInitialized',
      ],
    ),
    isLimitedUserAccount() {
      return this?.user?.accountType === 'E'
    },
    isFullUserAccount() {
      return this?.user?.accountType === 'O'
    },
  },
}
