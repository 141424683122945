import "core-js/modules/es.string.starts-with.js";
import { renderChildren } from '@nsf/base/components/PageBuilder/index.js';
export default {
  name: 'VA',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var href = ctx.props.data.href || '#';
    var isMail = href.startsWith('mailto:');
    var newWindow = ctx.props.data.target === '_blank';
    var tag = isMail ? 'a' : 'v-link';
    var data = {
      props: {
        to: href,
        newWindow: newWindow
      }
    };
    if (isMail) {
      data.attrs = {
        href: href,
        target: newWindow ? '_blank' : '_self'
      };
    }
    return h(tag, data, renderChildren(h, ctx, children));
  }
};