import logger from '@nsf/catalog/logger.js'
import { withDefault, mapDataToModel } from '@nsf/core/Mapper.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { mapDataToCategory } from '@nsf/catalog/mappers/CategoryMapper.js'
import { variable } from '@nsf/core/GraphQL.js'
import {
  filterPageQuery,
} from '@ioc/graphql/queries.js'
import {
  REQUIRED_INT,
} from '@nsf/core/consts/GraphQLTypes.js'

const CATEGORIES_DISPLAYED_LEVEL = 4

export const getDefaultCategory = () => ({
  id: 0,
  name: '',
  path: '',
  topProductsIds: [],
})

export const getCategoryById = async (id) => {
  try {
    const response = await Query.categories()
      .where('id', id)
      .first()

    const category = withDefault(getDefaultCategory, mapDataToCategory(response))

    return { category }
  } catch (e) {
    logger.error('getCategoryById(%o) failed: %s', id, e.message)

    return { category: {} }
  }
}

export const getBrandEnabledCategoriesByIds = async (ids) => {
  try {
    const response = await Query.categories()
      .whereIn('id', ids)
      .whereNot('drmax_hide_in_brand_page_filter', 1)
      .getAll()

    const categories = withDefault(getDefaultCategory, mapDataToCategory(response))

    return { categories }
  } catch (e) {
    logger.error('getBrandEnabledCategoriesByIds(%o) failed: %s', ids, e.message)

    return { categories: [] }
  }
}

export const getCategoriesByIds = async (ids) => {
  try {
    const response = await Query.categories()
      .whereIn('id', ids)
      .getAll()

    const categories = withDefault(getDefaultCategory, mapDataToCategory(response))

    return { categories }
  } catch (e) {
    logger.error('getCategoriesByIds(%o) failed: %s', ids, e.message)

    return { categories: [] }
  }
}

export const getCategoriesByParentId = async (parentId) => {
  try {
    const response = await Query.categories()
      .where('parent_id', parentId)
      .sort('position', 'asc')
      .getAll()

    const categories = withDefault(getDefaultCategory, mapDataToCategory(response))

    return { categories }
  } catch (e) {
    logger.error('getCategoriesByParentId(%o) failed: %s', parentId, e.message)

    return { categories: [] }
  }
}

export const getCategoriesByPath = async (path, { except = [] } = {}) => {
  try {
    const ids = path.split('/')

    const query = Query.categories()
      .whereIn('id', ids)

    if (except.length) {
      query.whereNotIn('id', except)
    }

    const response = await query.getAll()

    const categories = withDefault(getDefaultCategory, mapDataToCategory(response))

    categories.sort((a, b) => ids.indexOf(a) - ids.indexOf(b))

    return { categories }
  } catch (e) {
    logger.error('getCategoriesByPath(%o) failed: %s', path, e.message)

    return { categories: [] }
  }
}

export const getCategoriesByBrandId = async (brandId) => {
  try {
    const query = Query.productsAvailable()
      .where('drmax_brand', brandId)
      .only('id', 'category_ids')
      .aggregate('category_ids')

    const aggregations = await query.aggregations()
    const categoryIds = []
    aggregations.category_ids.buckets?.forEach((item) => categoryIds.push(item.key))
    if (!categoryIds.length) {
      return { categories: [] }
    }
    const { categories } = await getBrandEnabledCategoriesByIds(categoryIds)

    const categoriesFiltered = categories
      .filter((cat) => cat.level === CATEGORIES_DISPLAYED_LEVEL)
      .map((cat) => ({ ...cat, position: categoryIds.indexOf(cat.category_id) }))

    return {
      categories: [
        ...categoriesFiltered
          .filter((cat) => cat.position > -1)
          .sort((a, b) => a.position - b.position),
        ...categoriesFiltered
          .filter((cat) => cat.position === -1),
      ],
    }
  } catch (e) {
    logger.error('getCategoriesByBrandId(%o) failed: %s', brandId, e.message)

    return { categories: [] }
  }
}

export const getCategoriesByProductLineId = async (productLineId) => {
  try {
    const query = Query.products()
      .where('drmax_product_line', productLineId)
      .only('id', 'category_ids')
      .aggregate('category_ids')

    const aggregations = await query.aggregations()
    const categoryIds = []
    aggregations.category_ids.buckets?.forEach((item) => categoryIds.push(item.key))
    if (!categoryIds.length) {
      return { categories: [] }
    }
    const { categories } = await getBrandEnabledCategoriesByIds(categoryIds)

    const categoriesFiltered = categories
      .filter((cat) => cat.level === CATEGORIES_DISPLAYED_LEVEL)
      .map((cat) => ({ ...cat, position: categoryIds.indexOf(cat.category_id) }))

    return {
      categories: [
        ...categoriesFiltered
          .filter((cat) => cat.position > -1)
          .sort((a, b) => a.position - b.position),
        ...categoriesFiltered
          .filter((cat) => cat.position === -1),
      ],
    }
  } catch (e) {
    logger.error('getCategoriesByProductLineId(%o) failed: %s', productLineId, e.message)

    return { categories: [] }
  }
}

export const getCategoryFilterPageById = async (id) => {
  try {
    const response = await filterPageQuery()
      .clone()
      .where(
        'page_id',
        variable(
          'page_id',
          REQUIRED_INT,
        ),
      )
      // eslint-disable-next-line camelcase
      .bind({ page_id: id })
      .get()

    return mapDataToModel(response)
  } catch (e) {
    logger.error('getCategoryFilterPageById(%o) failed: %s', id, e.message)

    return null
  }
}
