<template>
  <v-portal v-if="isShown">
    <div
      ref="modal"
      :class="['modal', modalClass, {'no-padding': noPadding, 'half-padding': halfPadding}]"
      role="dialog"
      tabindex="0"
      aria-modal="true"
      @mousedown="onBgClick"
      @touchstart="onBgClick"
      @keydown.esc="onClose"
    >
      <div
        :class="[
          'modal-dialog modal-dialog-centered',
          size ? `modal-${size}` : '',
        ]"
        role="document"
        :aria-label="ariaModalLabel"
      >
        <div class="modal-content">
          <slot name="modal-header">
            <div
              v-if="!noHeader"
              :class="[
                'modal-header',
                {
                  'text-white': isSuccess || isError,
                  'bg-success': isSuccess,
                  'bg-danger': isError,
                }
              ]"
            >
              <h1 class="modal-title h1 font-weight-light">
                <span v-if="title">
                  {{ title }}
                </span>
                <slot v-else name="header" />
              </h1>

              <button
                v-if="canDismiss"
                :class="[
                  'close align-self-center',
                  {
                    'text-white': isSuccess || isError,
                  }
                ]"
                type="button"
                :aria-label="$t('base.Close')"
                @click="onClose"
              >
                <svg
                  class="icon close__icon icon-lg"
                  aria-hidden="true"
                >
                  <use xlink:href="~/assets/icons/icons.svg#close" />
                </svg>
              </button>
            </div>
          </slot>

          <div class="modal-body">
            <button
              v-if="noHeader && canDismiss"
              :class="[
                'close align-self-center',
                {
                  'text-white': isSuccess || isError,
                  'm-3 p-3 bg-white shadow': noPadding,
                  'btn': isDismissAButton,
                  'rounded-circle': noPadding && modernCloseButton,
                  'btn-circle': isDismissAButton && modernCloseButton,
                }
              ]"
              type="button"
              aria-label="Close"
              @click="onClose"
            >
              <svg
                :class="['icon close__icon icon-lg position-absolute', {
                  'close__icon--modern': !modernCloseButton
                }]"
                aria-hidden="true"
              >
                <use xlink:href="~/assets/icons/icons.svg#close" />
              </svg>
            </button>
            <slot />
          </div>

          <div v-if="$slots.footer" class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
      <div tabindex="0" @focus.prevent="$refs.modal.focus()" />
    </div>
  </v-portal>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal'
import { mapGetters } from 'vuex'
import focusTrap from '../mixins/focusTrap.js'

export default {
  name: 'DrmaxModal',

  components: {
    'v-portal': Portal,
  },

  mixins: [focusTrap],

  props: {
    canDismiss: {
      type: Boolean,
      default: true,
    },
    clickToClose: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    halfPadding: {
      type: Boolean,
      default: false,
    },
    modalClass: {
      type: String,
      default: '',
    },
    isDismissAButton: {
      type: Boolean,
      default: true,
    },
    modernCloseButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isShown: false,
    }
  },
  computed: {
    ariaModalLabel() {
      return this.title ?? 'modal'
    },
    ...mapGetters({
      modalsOpened: '_base/modal/getModalsOpened',
      ableToUnblockScroll: '_base/modal/ableToUnblockScroll',
    }),
  },

  beforeDestroy() {
    if (this.isShown) {
      this.hide()
    }
  },

  methods: {
    async show() {
      setTimeout(async () => {
        this.isShown = true
        document.body.classList.add('modal-open')

        await this.$nextTick()

        this.addFocusTrapListener(this.$refs.modal, this.$refs.modal, document.activeElement)
      }, 0)

      await this.$store.commit('_base/modal/increaseCount')

      this.$emit('show')
    },

    async hide() {
      setTimeout(() => {
        this.isShown = false
      }, 0)

      await this.$store.commit('_base/modal/decreaseCount')

      if (this.ableToUnblockScroll) {
        document.body.classList.remove('modal-open')
      }

      this.$emit('hide')

      this.removeFocusTrapListener()
    },

    onClose() {
      this.$emit('setLoading', false)
      this.hide()
    },

    onBgClick(ev) {
      if (this.clickToClose && ev.target === this.$refs.modal) {
        this.hide()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable max-nesting-depth */
.modal {
  z-index: 9001;
  display: block;
  max-width: 100vw;
  background-color: #000b;

  .modal-dialog {
    width: 100vw;
    padding: 0.5rem;
    margin: auto;

    &.modal-md {
      max-width: $md;
    }
  }

  .modal-xl {
    max-width: 1140px;
  }

  .modal-xxl {
    max-width: 100vw;
  }

  &-body {
    max-height: 75vh;
    overflow-y: auto;

    @media (max-width: 575px) {
      max-height: 100%;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-simple .modal-body {
    max-height: none;
    overflow-x: hidden;
  }

  &-no-scroll .modal-body {
    max-height: initial;
    overflow-y: hidden;
  }
  @media only screen and (max-width: $md) {
    .modal-title {
      font-size: 22px;
    }
  }

  &.half-padding {
    .modal-dialog {
      max-width: 640px;
    }

    .modal-body {
      padding: 10px;
    }
  }

  &.hidden {
    display: none;
  }

  &.header-white {
    .modal-header {
      align-items: center;
      border-bottom: 0;
      background-color: inherit;

      p.h1 {
        /* stylelint-disable selector-no-qualifying-type */
        font-weight: 700 !important;
        font-size: 19px;
      }
    }
  }

  &.no-padding {
    .modal-dialog {
      width: calc(100vw - 6em);
      max-width: 1140px;

      &.modal-full {
        @media only screen and (min-width: $lg-min) {
          max-width: calc(100vw - 6em);

          .modal-body {
            max-height: calc(100vh - 6em);
          }
        }
      }
      @media only screen and (max-width: $md) {
        width: 100%;
        max-width: unset;
        height: 100%;
        max-height: unset;
        margin: 0;
      }

      .modal-content {
        overflow: hidden;
        // Fix for a Safari bug - border-radius not applied with Google Maps
        transform: translateZ(0);
        @media only screen and (max-width: $md) {
          height: 100%;
          border-radius: unset;
        }

        /* stylelint-disable selector-max-compound-selectors */
        .modal-body {
          height: 90vh;
          padding: 0;

          .close {
            &__icon--modern {
              color: #054979;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              &__icon {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
              }
            }
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
            width: 8px;
            height: 8px;
            line-height: 0.4;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
