export default defineNuxtPlugin(function (nuxtApp) {
  var state = nuxtApp.payload.state;
  if (!state.newRelic) {
    return;
  }
  var activationThreshold = state.newRelic.activationThreshold;
  var activationValue = parseFloat(localStorage.getItem('new-relic-value') || '') || Math.random();
  localStorage.setItem('new-relic-value', "".concat(activationValue));
  if (activationValue > activationThreshold) {
    return;
  }
  useHead({
    script: [{
      src: '/assets/new-relic.js',
      defer: true
    }]
  });
});