export default {
  name: 'FetchLoader',
  computed: {
    pending: function pending() {
      return this.$parent.$fetchState.pending;
    }
  },
  render: function render(h) {
    if (this.pending) {
      return h('div', {
        class: 'text-center'
      }, [h('div', {
        class: 'text-center spinner-border text-success display-4 mb-3'
      }), h('p', this.$t('base.Loading'))]);
    }
    return h('slot', this.$slots.default);
  }
};