import logger from '@nsf/catalog/logger.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { mapDataToProductAttachment } from '@nsf/catalog/mappers/ProductAttachmentMapper.js'

export const getProductAttachmentsByProductId = async (productId) => {
  try {
    const response = await Query.productAttachments()
      .only('file_type', 'file_url', 'file_path')
      .where('product_id', productId)
      .getAll()

    const productAttachments = mapDataToProductAttachment(response)

    return { productAttachments }
  } catch (e) {
    logger.error('getProductAttachmentsByProductId(%o) failed: %s', productId, e.message)

    return {
      productAttachments: [],
    }
  }
}
