/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/1540948392/Product+list+impressions+and+Product+list+clicks
 */

import placementTypeMapper from '@nsf/gtm/mappers/placementTypeMapper.js'
import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { formatProduct } from '@nsf/gtm/utils/product.js'

/**
 * @param {Object} placement
 * @param {Object[]} data
 */
export default (placement, data, isLoggedIn = false) => {
  const event = {
    event: 'placement_click',
    placement: {
      ...placement,
      type: placementTypeMapper(placement.type),
    },
  }

  switch (placement.type) {
    case 'products':
      event.placement[placement.type] = data.map((product) => formatProduct(product, '', isLoggedIn))
      break
    default:
      event.placement[placement.type] = data
  }

  usePushEvent(event)
}
