import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import maps from './maps.mjs'

export default defineAppConfig({
  excludeRegionIds: [],
  filters: [],
  ...maps,
  pickupListMaxLength: 200,
  pickupListPageSize: 20,
  ...config,
  customizePickupPharmacyTitle: ({ title }) => title.toLowerCase().includes('sector') ? title + ', Bucuresti' : title,
})
