/* eslint-disable no-shadow */
import { setState } from '@nsf/core/Store.js'
import { mapDataToPoints } from '../mappers/PointMappers.js'
import {
  POINT_TYPES,
  filteredByAll,
  mappedByAll,
  withinMapBounds,
  distinctByDistanceFrom,
  nearestTo,
} from '../utils/PlaceUtils.js'

export const state = () => ({
  /** Place selected by user */
  selectedPlace: null,
  isLoading: false,
})

export const getters = {
  /**
   * Gathers places from submodules.
   */
  places: (_, __, rootState) => {
    const pharmacyPlaces = rootState['pickup-select/places/pharmacies'].places
    const postOfficesPlaces = rootState['pickup-select/places/postOffices'].places
    const packetaPlaces = rootState['pickup-select/places/packeta'].places
    const pickupPlaces = rootState['pickup-select/places/pickupPlaces'].places

    const pharmacyExpressPlaces = rootState['pickup-select/places/pharmaciesExpress'].places

    return [...pharmacyPlaces, ...postOfficesPlaces, ...packetaPlaces, ...pickupPlaces, ...pharmacyExpressPlaces]
  },

  numberOfPlaces: (_, { placesFiltered }) => placesFiltered.length,

  /**
   * Nearest place to the location.
   */
  nearestPlace: (_, { placesFiltered }, __, rootGetters) => {
    const nearestToCurrentLocation = nearestTo(rootGetters['pickup-select/map/location'])

    return nearestToCurrentLocation(placesFiltered)
  },

  placesFiltered: (_, { places }, __, rootGetters) => {
    const filteredByAllFilters = filteredByAll(rootGetters['pickup-select/filters/getFilters'])
    const mappedByAllMappers = mappedByAll(rootGetters['pickup-select/mappers/getMappers'])

    return mappedByAllMappers(filteredByAllFilters(places))
  },

  disabledPlacesIds: (_, { places }, __, rootGetters) => {
    const filters = rootGetters['pickup-select/userFilters/getActiveUserFilters']
    if (!filters.length) {
      return []
    }

    const filteredIds = filteredByAll(filters.map((item) => item.func))(places).map((item) => item.pickupPlaceId)

    return places.map((item) => item.pickupPlaceId).filter((x) => !filteredIds.includes(x))
  },

  /**
   * Places that are now within boundaries of the map. Return empty array when map in not shown yet.
   */
  placesWithinMapBounds: (_, { places }, __, rootGetters) => {
    const filteredByAllFilters = filteredByAll(rootGetters['pickup-select/filters/getFilters'])
    const mappedByAllMappers = mappedByAll(rootGetters['pickup-select/mappers/getMappers'])
    const withinCurrentMapBounds = withinMapBounds(rootGetters['pickup-select/map/mapBounds'])

    return mappedByAllMappers(filteredByAllFilters(withinCurrentMapBounds(places)))
  },

  /**
   * Places within map bounds and sorted.
   */
  placesWithinMapBoundsSorted: (_, { places }, __, rootGetters) => {
    const filteredByAllFilters = filteredByAll(rootGetters['pickup-select/filters/getFilters'])
    const mappedByAllMappers = mappedByAll(rootGetters['pickup-select/mappers/getMappers'])
    const withinCurrentMapBounds = withinMapBounds(rootGetters['pickup-select/map/mapBounds'])
    const sortedByDistance = distinctByDistanceFrom(rootGetters['pickup-select/map/location'])

    return sortedByDistance(mappedByAllMappers(filteredByAllFilters(withinCurrentMapBounds(places))))
  },

  selectedPlace: (state, getters) => {
    if (!state.selectedPlace) {
      return null
    }
    return getters.placesFiltered.find(
      (place) => place.pickupPlaceId === state.selectedPlace.pickupPlaceId
        && place.type === state.selectedPlace.type,
    ) ?? null
  },

  isLoading: (state) => state.isLoading,
}

export const mutations = {
  setState,

  setIsLoading: (state, payload) => {
    state.isLoading = payload
  },

  setSelectedPlace: (state, payload) => {
    if (payload?.pickupPlaceId && payload?.type) {
      const { pickupPlaceId, type } = payload
      state.selectedPlace = { pickupPlaceId, type }
    } else {
      state.selectedPlace = null
    }
  },
}

export const actions = {
  initPlaces: async ({ commit, dispatch }, { points = [], type } = {}) => {
    commit('setState', { isLoading: true })

    if (type === POINT_TYPES.PHARMACY) {
      await dispatch('pickup-select/places/pharmacies/initPlaces', {}, { root: true })
    }

    if (type === POINT_TYPES.POST_OFFICE) {
      await dispatch('pickup-select/places/postOffices/initPlaces', {
        points,
      }, { root: true })
    }

    if (type === POINT_TYPES.PACKETA) {
      await dispatch('pickup-select/places/packeta/initPlaces', {
        points: mapDataToPoints(points),
      }, { root: true })
    }

    if (type === POINT_TYPES.PICKUP_PLACE || type === POINT_TYPES.DRMAX_BOX) {
      await dispatch('pickup-select/places/pickupPlaces/initPlaces', {
        points: mapDataToPoints(points),
      }, { root: true })
    }

    if (type === POINT_TYPES.PHARMACY_EXPRESS) {
      await dispatch('pickup-select/places/pharmaciesExpress/initPlaces', {}, { root: true })
    }

    commit('setState', { isLoading: false })
  },

  clearPlaces: ({ commit }) => {
    commit('pickup-select/places/pharmacies/clearPlaces', null, { root: true })
    commit('pickup-select/places/postOffices/clearPlaces', null, { root: true })
    commit('pickup-select/places/packeta/clearPlaces', null, { root: true })
    commit('pickup-select/places/pickupPlaces/clearPlaces', null, { root: true })
    commit('pickup-select/places/pharmaciesExpress/clearPlaces', null, { root: true })
  },
}
