<template>
  <v-menu
    v-if="groups && groups.length"
    class="pregnancy mt-2"
  >
    <v-menu-item
      v-for="(group, i) in groups"
      :key="i"
      :starts-shown="i === 0"
      :title="$t(`clubs.baby.${group.name}`)"
      :name="$t(`clubs.baby.${group.name}`)"
    >
      <template v-if="group.items && group.items.length">
        <div v-if="i===0" class="pregnancy-wrapper">
          <v-weeks
            v-for="(trimester) in getTrimesters"
            :key="trimester"
            :items="getTrimesterItems(trimester, group.items)"
            :type="group.name"
          >
            <div class="mx-3 mb-5 ">
              {{ trimesterName(trimester) }}
            </div>
          </v-weeks>
        </div>

        <div v-else-if="i===1" class="pregnancy-wrapper">
          <v-weeks
            :items="getItemsByType(group.items, 'week')"
            :type="group.name"
          />
          <v-weeks
            :items="getItemsByType(group.items, 'month')"
            :type="group.name"
          />
        </div>

        <div v-else class="infant-baby-wrapper">
          <v-weeks :items="group.items" :type="group.name" />
        </div>
      </template>
    </v-menu-item>

    <template #menuItemName="{title}">
      <img
        :alt="title"
        class="align-middle d-inline-block"
      > &nbsp;
      {{ title }}
    </template>
  </v-menu>
</template>

<script>

import MenuItem from '@nsf/base/components/Menu/Item.vue'
import Menu from '@nsf/base/components/Menu/index.vue'
import { getCategoryWithChildren } from '@nsf/articles/repositories/CategoryRepository.js'
import { mapState } from 'vuex'
import IsConfig from '@nsf/base/mixins/IsConfig.js'
import Weeks from '../components/pregnancyWeeks/Week.vue'

export default {
  name: 'PregnancyWeeks',

  components: {
    'v-menu': Menu,
    'v-menu-item': MenuItem,
    'v-weeks': Weeks,
  },

  mixins: [IsConfig],

  data() {
    return { category: null }
  },

  async fetch() {
    if (!(this.resolverType === 'AMASTY_BLOG_CATEGORY' && this.resolverId)) {
      return
    }
    const { category } = await getCategoryWithChildren(this.resolverId)
    category.children = category.children.sort((a, b) => a.sortOrder - b.sortOrder)
    this.category = category
  },

  created() {
    this.config = this.$store.state['_base/config'].config
  },

  methods: {
    trimesterName(i) {
      switch (i) {
        case 1:
          return this.$t('clubs.First trimester')
        case 2:
          return this.$t('clubs.Second trimester')
        case 3:
          return this.$t('clubs.Third trimester')
        default:
          return ''
      }
    },

    getItemsByType(items, type) {
      return items.filter((week) => week.type === type)
    },

    getTrimesterItems(trimester, weeks) {
      let fromWeek
      let toWeek

      switch (trimester) {
        case 2:
          fromWeek = 13
          toWeek = 25
          break
        case 3:
          fromWeek = 26
          toWeek = 40
          break
        case 1:
        default:
          fromWeek = 1
          toWeek = 12
          break
      }

      return weeks.filter((week) => week.number >= fromWeek && week.number <= toWeek)
    },
  },

  computed: {
    ...mapState('_base/navigation', { resolverType: 'resolverType', resolverId: 'resolverId' }),

    groups() {
      const groupNames = Object.keys(this.drmaxNsfContentBabyCalendarWidget)
      const groups = []

      for (const groupName of groupNames) {
        const items = this.drmaxNsfContentBabyCalendarWidget[groupName]

        groups.push({
          name: groupName,
          items,
        })
      }

      return groups
    },

    getTrimesters() {
      return [1, 2, 3]
    },
  },

}
</script>

<style lang="scss" scoped>
/* purgecss start ignore */
/* stylelint-disable */

.infant-baby-wrapper::v-deep {
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (min-width: 575px) {
      max-height: 20vw;
    }
  }

  li {
    flex: 1 1 25%;
  }

}

.pregnancy-wrapper::v-deep {
  display: flex;
  flex-wrap: wrap;

  ul {
    flex: 1;
  }
}

.pregnancy::v-deep {
  .category-menu__tab {
    color: black;
    background-color: whitesmoke;

    &:hover {
      color: #d184ac;
    }

    + .isCurrent, .active, .category-menu--desktabs {
      background-color: white;
      color: #d184ac;

    }
  }

  .category-menu__list {
    background-color: #f0f0f0;
    border: none;
  }

  .category-menu__list::before, .category-menu__list, .category-menu__tab.isCurrent {
    background-color: #f0f0f0;
  }

  .category-menu--desktabs .category-menu__tab.isCurrent {
    color: #d184ac;
    background-color: white;
  }

  .category-menu__tab {
    border-top: 2px solid whitesmoke;
  }

  .category-menu__trigger {
    color: #d184ac;
    background-color: #f0f0f0;
    border: 1px solid whitesmoke;
    box-shadow: 0 5px 3px -3px whitesmoke;
  }

}

/* stylelint-enable */
/*purgecss end ignore */

</style>
