export default {
  name: 'VIframe',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var data = ctx.props.data;
    var iframeData = {
      attrs: {
        style: 'position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0; max-width:900px;'
      }
    };
    var iframe = h('iframe', {
      attrs: {
        src: data.src || '',
        style: 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;'
      }
    });
    return h('div', iframeData, [iframe]);
  }
};