import { withDefault } from '@nsf/core/Mapper.js'
import logger from '@nsf/my-account/logger.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import returnServiceClient from '../clients/ReturnServiceClient.js'
import {
  getUniqueProducts, mapProducts, mapReasonsSolutions,
} from '../mappers/ReturnsMapper.js'
import { withAuth } from '../utils/authDecorator.js'

export const getDefaultProduct = () => ({
  isReturnable: false,
  isComplainable: false,
  price: 0,
  productName: '',
  sku: '',
  quantity: 1,
})

export const getDefaultProductToSelect = () => ({
  ...getDefaultProduct(),
  isSelected: false,
  note: '',
  reason: '',
  solution: '',
  text: '',
})

export const getMappedProducts = (products) => getUniqueProducts(withDefault(
  getDefaultProduct,
  mapProducts(products),
))

export const getMappedProductsToSelect = (products) => getUniqueProducts(withDefault(
  getDefaultProductToSelect,
  mapProducts(products),
))

export const getReturnSchemasReasons = async () => {
  try {
    const {
      data, ok,
    } = await returnServiceClient.get(`/schemas/reasons`)
    if (!ok) {
      throw new Error(data.message)
    }
    return {
      reasons: mapReasonsSolutions(data.data),
    }
  } catch (e) {
    logger.error(
      'getReturnSchemasReasons failed: %s',
      e.message,
    )
    return {
      reasons: [],
    }
  }
}

export const getReturnSchemasSolutions = async () => {
  try {
    const {
      data, ok,
    } = await returnServiceClient.get(`/schemas/solutions`)
    if (!ok) {
      throw new Error(data.message)
    }
    return {
      solutions: mapReasonsSolutions(data.data, 'solution'),
    }
  } catch (e) {
    logger.error(
      'getReturnSchemasSolutions failed: %s',
      e.message,
    )
    return {
      solutions: [],
    }
  }
}

export const verifyClient = async (
  orderId, email, redirectUrl,
) => {
  const payload = {
    orderId,
    email,
    redirectUrl,
  }
  try {
    const {
      data, ok,
    } = await returnServiceClient.post(
      '/public/returns/verify',
      payload,
    )

    if (data.detail === 'Order type is OTC Reservation. Return is disabled for this order type.') {
      return {
        ok: true,
        isOtcReservationOrderType: true,
        isReturnExists: false,
      }
    }

    if (data.detail === `Active return or complaint with orderId [${orderId}] already exists.`) {
      return {
        ok: true,
        isOtcReservationOrderType: false,
        isReturnExists: true,
      }
    }

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      ok: true,
      isReturnExists: false,
      isOtcReservationOrderType: false,
    }
  } catch (e) {
    logger.error(
      'verifyClient(%s, %s, %s) failed: %s',
      orderId,
      email,
      redirectUrl,
      e.message,
    )

    return {
      ok: false,
      isReturnExists: false,
      isOtcReservationOrderType: false,
    }
  }
}

export const checkOrderReturnsPublic = async ({
  orderId, email, verificationCode,
}) => {
  const payload = {
    orderId,
    email,
    verificationCode,
  }
  try {
    const {
      data, ok,
    } = await returnServiceClient.post(
      '/public/returns/check',
      payload,
    )

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      products: getMappedProductsToSelect(data.products),
      orderType: data.orderType,
      ok: true,
      verificationCode: data.verificationCode,
    }
  } catch (e) {
    logger.error(
      'checkOrderReturns(%s, %s) failed: %s',
      orderId,
      email,
      e.message,
    )

    return {
      products: [],
      ok: false,
      verificationCode: null,
      orderType: null,
    }
  }
}

export const createReturnPublic = async (payload) => {
  try {
    const {
      data, ok,
    } = await returnServiceClient.post(
      '/public/returns',
      payload,
    )

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      data,
      ok: true,
    }
  } catch (e) {
    logger.error(
      'createReturnPublic failed: %s',
      e.message,
    )

    return {
      data: {},
      ok: false,
    }
  }
}

export const checkOrderReturns = async (
  {
    orderId, email,
  }, opts = { isProductMappedToSelect: false },
) => {
  const payload = {
    orderId,
    email,
  }
  try {
    const {
      data, ok,
    } = await withAuth(myAccountServiceClient.post)(
      '/clients/me/returns/check',
      payload,
    )

    if (data.detail) {
      const detailData = JSON.parse(data.detail)
      if (detailData.detail === 'Order type is OTC Reservation. Return is disabled for this order type.') {
        return {
          isMissingReturnInfo: false,
          products: [],
          ok: true,
          isOtcReservationOrderType: true,
        }
      }
    }

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      isMissingReturnInfo: false,
      products: opts.isProductMappedToSelect ? getMappedProductsToSelect(data.data) : getMappedProducts(data.data),
      ok,
      isOtcReservationOrderType: false,
    }
  } catch (e) {
    logger.error(
      'checkOrderReturns(%s, %s) failed: %s',
      orderId,
      email,
      e.message,
    )

    return {
      isMissingReturnInfo: false,
      products: [],
      ok: false,
      isOtcReservationOrderType: false,
    }
  }
}

export const getReturns = async () => {
  try {
    const {
      data, ok,
    } = await withAuth(myAccountServiceClient.get)(`/clients/me/returns`)

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      returns: data.data,
      ok: true,
    }
  } catch (e) {
    logger.error(
      'getReturns() failed: %s',
      e.message,
    )
    return {
      returns: {},
      ok: false,
    }
  }
}

export const getReturn = async (id) => {
  try {
    const {
      data, ok,
    } = await withAuth(myAccountServiceClient.get)(`/clients/me/returns/${id}`)

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      data: {
        ...data,
        products: getMappedProducts(data.products),
      },
      ok: true,
    }
  } catch (e) {
    logger.error(
      'getReturn(%s) failed: %s',
      id,
      e.message,
    )
    return {
      data: {},
      ok: false,
    }
  }
}

export const createReturn = async (payload) => {
  try {
    const {
      data, ok,
    } = await withAuth(myAccountServiceClient.post)(
      '/clients/me/returns',
      payload,
    )

    if (!ok) {
      throw new Error(data.message)
    }

    return {
      data,
      ok: true,
    }
  } catch (e) {
    logger.error(
      'createReturn failed: %s',
      e.message,
    )

    return {
      data: { },
      ok: true,
    }
  }
}
