import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      timeSlotDeliveryEnabled,
      simpleDeliveryTimeEnabled,
    },
    carrierCodes: {
      limitedByZipCode: {
        go4CarrierCode,
      },
    },
  },
} = useAppConfig()

// noinspection SpellCheckingInspection
export default field('available_shipping_methods', [
  field('carrier_code'),
  field('carrier_title'),
  field('integration_id'),
  field('courier_id'),
  field('method_title'),
  field('method_code'),
  field('pickup_place_types'),
  field('error_message'),
  field('price_incl_tax', [
    field('currency'),
    field('value'),
  ]),
  field('price_excl_tax', [
    field('value'),
  ]),
  field('icon'),
  ...simpleDeliveryTimeEnabled
    ? [
        field('simple_delivery_time'),
        field('simple_delivery_time_info'),
      ]
    : [],
  ...go4CarrierCode
    ? [
        field('drmax_available_zips'),
        field('drmax_cut_off_time'),
      ]
    : [],
  field('amount', [
    field('currency'),
    field('value'),
  ]),
  ...timeSlotDeliveryEnabled
    ? [field('time_slot_delivery', [
        field('routes', [
          field('delivery_route_id'),
          field('delivery_method'),
          field('drop_time_start'),
          field('drop_time_end'),
          field('cutoff_time'),
          field('cutoff_time_place_order'),
          field('skus_crossdock'),
          field('state'),
          field('price'),
          field('is_selected'),
        ]),
        field('default_drop_time_start'),
        field('default_price'),
        field('skus_not_in_stock'),
        field('skus_restricted', [
          field('delivery_method'),
          field('skus'),
        ]),
        field('postcode_ignored_total'),
      ])]
    : [],
])
