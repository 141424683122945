<template>
  <div
    class="pagebuilder-tip"
  >
    <div class="tip-left">
      <img v-if="iconSrc" alt="tip-icon" class="tip-icon" :src="iconSrc">

      <svg v-else aria-hidden="true">
        <use :xlink:href="`/images/icons-base.svg#${svgIconId}`" />
      </svg>
    </div>
    <div class="tip-right">
      <div class="tip-title">
        {{ title }}
      </div>
      <div class="tip-content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTip',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    title() {
      return this.data['data-title'] || ''
    },

    content() {
      return this.data['data-content'] || ''
    },

    icon() {
      return this.data['data-icon'] || ''
    },

    blogCategoryTipIcon() {
      const { blogCategoryTipIcon } = this.$store.state['_base/pagebuilder']
      return blogCategoryTipIcon
    },

    iconSrc() {
      // if default (tip) icon is set, and category has an icon, we will use category icon
      if (this.svgIconId === 'tip' && this.blogCategoryTipIcon) {
        return `${this.$config.public.magentoUrl}/${this.blogCategoryTipIcon}`
      }

      return ''
    },

    svgIconId() {
      // eslint-disable-next-line default-case
      switch (this.icon) {
        case 'faq':
          return 'faq'
        case 'size-of-baby':
          return 'ruler'
        case 'baby-development':
          return 'baby-development'
        case 'how-do-you-feel':
          return 'feel'
        default:
          return 'tip'
      }
    },
  },
}
</script>

<style lang="scss">
/* stylelint-disable max-nesting-depth */
.pagebuilder-tip {
  display: flex;
  padding: 20px;
  margin: 30px 0;
  border: 1px solid #c3e1f2;
  border-radius: 16px;
  background-color: #eaf6fc;
  box-shadow: 0 1px 3px rgba(223, 223, 223, 0.312937),
    0 1px 1px rgba(223, 223, 223, 0.207933),
    0 2px 1px -1px rgba(223, 223, 223, 0.090199);

  .tip-title {
    @include title-medium-spaced;
    margin-bottom: 10px;
    color: #025d9a;

    &::after {
      margin-top: 0;
    }

    &::before {
      margin-bottom: -0.12em;
    }
  }

  .tip-content {
    @include body-small-spaced;
    color: RGBA(0, 0, 0, 0.88);

    &::after {
      margin-top: 0;
    }
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    color: #025d9a;
  }
}

</style>
