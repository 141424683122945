import dayjs from 'dayjs'

/**
 * Takes a number which represent days from now (positive or negative) and make them
 * to fit 0 through 6 (i.e. monday to sunday).
 *
 * @param {number} day
 * @returns {number}
 */
export const wrapWeek = (day) => day % 7

/**
 * Takes id of today and returns ids of following days, including today.
 *
 * E.g. today is saturday, i.e. 6. We want 3 days. We receive 6 (sat), 7 (sun), 1 (mon).
 *
 * @param count
 * @returns {[]}
 */
export const daysIdsStartingToday = (count) => {
  const today = dayjs().day()

  const days = []

  for (let i = 0; i < count; i++) {
    const followingDay = today + i

    days.push(wrapWeek(followingDay))
  }

  return days
}

/**
 * Normalize Sunday to 0 instead of 7.
 *
 * @param day {number|string}
 * @returns {number|string}
 */
export const normalizeSunday = (day) => (Number(day) === 7 ? 0 : day)

export const isSameDayPickupAvailable = (isReservation, openHours) => {
  if (!isReservation || !openHours.length) {
    return false
  }

  const currentDate = dayjs().format('YYYY-MM-DD')
  const openHoursToday = openHours.find((day) => day.date === currentDate)

  if (!openHoursToday?.close) {
    return false
  }

  const now = dayjs()
  const closesAt = dayjs(`${currentDate}T${openHoursToday.close}`)
  const difference = closesAt.diff(now, 'minute')

  return difference >= 60
}
