<template>
  <div class="bmi-calculator-widget">
    <h2 class="bmi-title">
      {{ $t('cms.bmiCalculator.BMI calculator') }}
    </h2>

    <div class="bmi-description" v-html="$t('cms.bmiCalculator.Description')" />

    <div class="bmi-separator" />

    <div class="bmi-content">
      <div class="bmi-content-left">
        <form @submit.prevent="calculateBmi">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="gray-label">
                {{ $t('cms.bmiCalculator.Height') }}
              </label>
              <div class="input-group">
                <input v-model="height" class="form-control" type="text">
                <div class="input-group-append">
                  <span class="input-group-text">cm</span>
                </div>
              </div>
            </div>

            <div class="form-group col-md-6 col-weight">
              <label class="gray-label">
                {{ $t('cms.bmiCalculator.Weight') }}
              </label>
              <div class="input-group">
                <input v-model="weight" class="form-control" type="text">
                <div class="input-group-append">
                  <span class="input-group-text">kg</span>
                </div>
              </div>
            </div>
          </div>

          <v-button stretch>
            {{ $t('cms.bmiCalculator.Calculate BMI') }}
          </v-button>
        </form>
      </div>

      <div class="bmi-content-right">
        <div v-show="bmi" class="bmi-results">
          <div class="bmi-title">
            {{ $t('cms.bmiCalculator.Your results') }}
          </div>

          <div v-if="currentInterval" class="bmi-result-text" :class="[`bmi-group-${currentInterval.group}`]">
            {{ $t('cms.bmiCalculator.Your BMI is') }} <span class="highlight">{{ bmiString }}</span>.
            {{ $t('cms.bmiCalculator.According to BMI classification it is') }}:
            <span class="highlight">
              {{ $t(`cms.bmiCalculator.${currentInterval.name}`) }}
            </span>
          </div>

          <div class="bmi-diagram">
            <div ref="group1" class="group group-1">
              <div class="bmi-marker" :class="{'bmi-marker-small': smallFontMarker}">
                <span>{{ bmiString }}</span>
              </div>
            </div>

            <div ref="group2" class="group group-2">
              <div class="bmi-marker" :class="{'bmi-marker-small': smallFontMarker}">
                <span>{{ bmiString }}</span>
              </div>
            </div>

            <div ref="group3" class="group group-3">
              <div class="bmi-marker" :class="{'bmi-marker-small': smallFontMarker}">
                <span>{{ bmiString }}</span>
              </div>
            </div>

            <div ref="group4" class="group group-4">
              <div class="bmi-marker" :class="{'bmi-marker-small': smallFontMarker}">
                <span>{{ bmiString }}</span>
              </div>
            </div>

            <div ref="group5" class="group group-5">
              <div class="bmi-marker" :class="{'bmi-marker-small': smallFontMarker}">
                <span>{{ bmiString }}</span>
              </div>
            </div>
          </div>

          <div class="bmi-result-text">
            {{ $t('cms.bmiCalculator.Your ideal weight should be') }} {{ `${idealWeightMin} - ${idealWeightMax}` }} kg.
          </div>
        </div>

        <div v-if="bmiError" class="bmi-error">
          <div>{{ bmiError }}</div>
        </div>

        <div v-show="!bmi && !bmiError" class="bmi-no-results">
          <div>{{ $t('cms.bmiCalculator.Your results will appear here') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@nsf/ui-library/components/atoms/Button.vue'

export default {
  name: 'BmiCalculatorWidget',

  components: {
    'v-button': Button,
  },

  data() {
    return {
      height: '',
      weight: '',
      bmi: 0,
      bmiString: '',
      smallFontMarker: false,
      idealWeightMin: 0,
      idealWeightMax: 0,
      currentInterval: null,
      bmiError: '',
      intervals: [
        {
          min: 0,
          max: 18.5,
          name: 'underweight',
          group: 1,
        },
        {
          min: 18.5,
          max: 25,
          name: 'healthy range',
          group: 2,
        },
        {
          min: 25,
          max: 30,
          name: 'overweight',
          group: 3,
        },
        {
          min: 30,
          max: 40,
          name: 'obesity',
          group: 4,
        },
        {
          min: 40,
          max: 1000,
          name: 'severe obesity',
          group: 5,
        },
      ],
    }
  },

  computed: {
    heightInM() {
      return parseFloat(this.height.replace(',', '.')) / 100
    },
  },

  methods: {
    validateBmiForm() {
      this.bmiError = ''

      const weight = parseFloat(this.weight.replace(',', '.'))
      const height = parseFloat(this.height.replace(',', '.'))

      if (!(height > 0)) {
        this.bmiError = this.$t('cms.bmiCalculator.Wrong height')
      } else if (!(weight > 0)) {
        this.bmiError = this.$t('cms.bmiCalculator.Wrong weight')
      }

      return !this.bmiError
    },

    calculateBmi() {
      const weight = parseFloat(this.weight.replace(',', '.'))

      if (!this.validateBmiForm()) {
        this.bmi = 0
        return
      }

      this.bmiString = (weight / Math.pow(this.heightInM, 2)).toFixed(1)
      this.bmi = Number(this.bmiString)
      this.bmiString = this.bmiString.replace('.', ',')

      if (this.bmiString.endsWith(',0')) {
        this.bmiString = `${parseInt(this.bmi, 10)}`
      }

      this.smallFontMarker = this.bmiString.length > 2

      const interval = this.findInterval(this.bmi)
      const percent = (this.bmi - interval.min) / (interval.max - interval.min)

      const groupElement = this.$refs?.[`group${interval.group}`]
      const markerElement = groupElement.firstChild

      this.resetActive()
      groupElement.classList.add('active')

      // timeout because we want some animation
      setTimeout(() => {
        markerElement.style.left = `${Math.min(100, percent * 100)}%`
      }, 100)

      this.idealWeightMin = Math.round(18.5 * Math.pow(this.heightInM, 2))
      this.idealWeightMax = Math.round(25 * Math.pow(this.heightInM, 2))
      this.currentInterval = interval
    },

    findInterval(bmi) {
      return (this.intervals.find((i) => i.min <= bmi && i.max > bmi) || this.intervals.slice(-1)[0])
    },

    resetActive() {
      this.$refs?.group1.classList.remove('active')
      this.$refs?.group2.classList.remove('active')
      this.$refs?.group3.classList.remove('active')
      this.$refs?.group4.classList.remove('active')
      this.$refs?.group5.classList.remove('active')
    },
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.bmi-calculator-widget {
  border-radius: 16px;
  border: 1px solid rgba(0,0,0, 0.08);
  background-color: rgba(251,251,251, 1);
  padding: 12px;
  margin: 30px auto;
  max-width: 800px;

  .bmi-title {
    @include title-large-spaced;
    color: RGBA(0, 0, 0, 0.88);
    margin: 15px 0 18px 0;
  }

  .bmi-description {
    @include body-small-spaced;
    color: RGBA(0, 0, 0, 0.56);
  }

  .bmi-separator {
    width: 100%;
    background-color: RGB(220, 220, 220);
    height: 1px;
    margin: 16px 0 6px 0;
  }

  form label.gray-label {
    @include label-large-spaced;
    color: RGBA(0, 0, 0, 0.72);
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
  }

  input.form-control, .input-group-text {
    @include label-large-spaced;
    color: RGBA(0, 0, 0, 0.72);
  }

  .bmi-content {
    display: flex;
  }

  .bmi-content-left {
    width: 300px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .bmi-content-right {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 60px;
  }

  .bmi-no-results {
    @include title-medium-spaced;
    color: RGBA(0, 0, 0, 0.56);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100px;
  }

  .bmi-error {
    @include title-small-spaced;
    color: RGB(241, 50, 57);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100px;
  }

  .bmi-result-text {
    @include body-small-spaced;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.88);

    .highlight {
      color: RGB(81, 132, 13);
      font-weight: 600;
    }

    &.bmi-group-1 .highlight {
      color: RGB(241, 50, 57);
    }

    &.bmi-group-3 .highlight {
      color: RGB(233, 189, 24);
    }

    &.bmi-group-4 .highlight {
      color: RGB(241, 50, 57);
    }

    &.bmi-group-5 .highlight {
      color: RGB(241, 50, 57);
    }
  }

  .bmi-diagram {
    display: flex;
    width: 100%;
    max-width: 300px;
    margin: 20px 0 40px 0;
    padding-top: 40px;

    .bmi-marker {
      font-size: 20px;
      font-weight: 700;
      border-radius: 2.5px;
      background-color: rgba(100,160,22, 1);
      color: RGBA(255, 255, 255, 0.96);
      position: absolute;
      top: -40px;
      left: 0;
      display: none;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 24px;
      margin-left: -19px;
      transition: left 1s ease;

      &.bmi-marker-small {
        font-size: 15px;
      }

      span {
        display: block;
      }

      &:after {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 20px 0 20px;
        border-color: rgba(100,160,22, 1) transparent transparent transparent;
        display: block;
        content: "";
        bottom: -12px;
        left: 0;
      }
    }

    .group {
      height: 10px;
      border-radius: 2.5px;
      margin-right: 4px;
      flex-shrink: 0;
      flex-grow: 0;
      width: 22px;
      position: relative;
      transition: all 1s ease;

      &::before {
        @include label-small-spaced;
        color: RGBA(0, 0, 0, 0.56);
        content: '';
        position: absolute;
        left: -7px;
        bottom: -20px;
        display: none;
      }

      &::after {
        @include label-small-spaced;
        color: RGBA(0, 0, 0, 0.56);
        content: '';
        position: absolute;
        right: -7px;
        bottom: -20px;
        display: none;
      }

      &.active {
        flex-shrink: 1;
        flex-grow: 1;
        width: auto;

        &::before {
          display: block;
        }

        &::after {
          display: block;
        }

        .bmi-marker {
          display: flex;
        }
      }
    }

    .group-1 {
      background-color: RGB(241, 50, 57);

      &::before {
        content: '0';
      }

      &::after {
        content: '18.5';
        right: -10px;
      }

      .bmi-marker {
        background-color: RGB(241, 50, 57);

        &:after {
          border-color: RGB(241, 50, 57) transparent transparent transparent;
        }
      }
    }

    .group-2 {
      background-color: RGB(100, 160, 22);

      &::before {
        content: '18.5';
        left: -10px;
      }

      &::after {
        content: '25';
      }

      .bmi-marker {
        background-color: RGB(100, 160, 22);

        &:after {
          border-color: RGB(100, 160, 22) transparent transparent transparent;
        }
      }
    }

    .group-3 {
      background-color: RGB(233, 189, 24);

      &::before {
        content: '25';
      }

      &::after {
        content: '30';
      }

      .bmi-marker {
        background-color: RGB(233, 189, 24);

        &:after {
          border-color: RGB(233, 189, 24) transparent transparent transparent;
        }
      }
    }

    .group-4 {
      background-color: RGB(241, 50, 57);

      &::before {
        content: '30';
      }

      &::after {
        content: '40';
      }

      .bmi-marker {
        background-color: RGB(241, 50, 57);

        &:after {
          border-color: RGB(241, 50, 57) transparent transparent transparent;
        }
      }
    }

    .group-5 {
      background-color: RGB(241, 50, 57);

      &::before {
        content: '40';
      }

      &::after {
        content: '∞';
      }

      .bmi-marker {
        background-color: RGB(241, 50, 57);

        &:after {
          border-color: RGB(241, 50, 57) transparent transparent transparent;
        }
      }
    }
  }

  .bmi-results {
    margin-bottom: 15px;
  }

  @media (max-width: $md) {
    .bmi-content {
      display: block;
    }

    .bmi-content-left {
      width: auto;
    }

    .bmi-content-right {
      margin-left: 0;
    }

    .bmi-no-results {
      display: none;
    }

    .bmi-results {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .row > .col-weight {
      padding-top: 0;
    }
  }
}
/* stylelint-enable */
</style>
