import "core-js/modules/es.array.concat.js";
import { renderChildren, extractStyle, extract } from '../components/PageBuilder/index.js';
export default {
  name: 'NoText',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var definedClass = children[0].class || '';
    var style = extractStyle(ctx.props.data, 1);
    var borderStyle = extract(style, /border-style: .+?;/);
    var borderColor = extract(style, /border-color: .+?;/);
    var borderWidth = extract(style, /border-width: .+?;/);
    var data = {
      class: "section mt-2 ".concat(definedClass),
      style: "".concat(borderStyle, " ").concat(borderColor, " ").concat(borderWidth)
    };
    return h('div', data, renderChildren(h, ctx, children, 1));
  }
};