<template>
  <div>
    <div
      v-if="!isSubcategoryListShown"
      id="menu"
      class="category-menu category-menu--desktabs"
      data-test-id="category-menu"
    >
      <div
        :class="{'show': !isExpanded}"
        class="collapse d-lg-none"
      >
        <button
          :aria-expanded="isExpanded"
          class="btn category-menu__trigger"
          aria-controls="category-menu"
          @click="onToggle"
        >
          {{ title }}
          <svg>
            <use xlink:href="~/assets/icons/icons.svg#chevron-left" />
          </svg>
        </button>
      </div>

      <nav
        id="category-menu"
        :class="{'show': isExpanded}"
        :aria-label="$t('base.Navigation')"
        class="category-menu__list collapse d-lg-flex"
      >
        <ul v-if="items.length">
          <li v-for="(item, i) in items" :key="i" class="d-inline-block">
            <button
              v-show="!item.hideTab"
              :key="i"
              :class="{'isCurrent': item.isShown}"
              class="category-menu__tab"
              active-class=""
              data-test-id="category-menu-button"
              @click="setActiveTab(item.name)"
            >
              {{ item.title }}
            </button>
          </li>
          <li class="d-inline-block d-lg-none">
            <button
              :aria-label="$t('base.Close')"
              :aria-expanded="isExpanded"
              class="category-menu__list__close "
              @click="onToggle"
            >
              <svg :aria-hidden="!isExpanded">
                <use xlink:href="~/assets/icons/icons.svg#close" />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'MenuIndex',

  provide() {
    return {
      registerItem: this.registerItem,
      getActiveTab: this.getActiveTab,
    }
  },

  props: {
    defaultTab: {
      type: String,
      default: '',
    },
    isSubcategoryListShown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [],
      isExpanded: false,
      activeTab: this.defaultTab,
    }
  },

  computed: {
    title() {
      return (this.items.filter((item) => item.isShown)[0] || {}).title || ''
    },
  },

  watch: {
    '$route.query.q': function () {
      this.setActiveTab('products')
    },
  },

  methods: {
    registerItem(item) {
      this.items.push(item)
    },

    onToggle() {
      this.isExpanded = !this.isExpanded
    },

    setActiveTab(name) {
      this.activeTab = name
      this.$emit('search-items', name)
    },

    getActiveTab() {
      return this.activeTab
    },
  },
}
</script>
