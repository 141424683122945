<template>
  <div
    v-if="$config.public.myAccountReturnServiceEnabled"
    class="my-account new-return-form"
    @click="onClick"
  >
    <div
      class="justify-content-center mt-4"
      :class="{ 'py-0': isInitialized}"
    >
      <div v-if="isInitialized">
        <h2 class="mb-4 title title-standalone">
          {{ $t('myAccount.returns.Title') }}
        </h2>

        <v-alert-message
          v-if="isErrorMessage"
        >
          <span v-html="$t('myAccount.returns.We are sorry, but email and order number dont match')" />
        </v-alert-message>
        <v-alert-message
          v-else-if="isWarningMessageLogIn || isWarningMessageReturnExists"
          type="warning"
        >
          {{
            isWarningMessageLogIn
              ? $tc('myAccount.returns.Please be aware, that there is an existing account with email', email)
              : $t('myAccount.returns.There is already an active return/complaint for this order')
          }}
        </v-alert-message>

        <v-alert-message
          v-else-if="isOtcReservationOrderTypeMessage"
          class="mt-2"
          type="warning"
        >
          {{ $t('myAccount.returns.OTC Reservation warning message') }}
        </v-alert-message>

        <v-form
          v-if="!isLoggedIn"
          ref="return"
          name="return"
          @submit="checkReturn"
        >
          <div class="list mobile-full-width col-lg-5 px-0">
            <div class="list-item py-5">
              <div class="row justify-content-center">
                <div class="col-lg-10 gap-3">
                  <v-input
                    id="orderId"
                    ref="orderId"
                    v-model.trim="$v.orderId.$model"
                    :label="$t('myAccount.returns.Order number')"
                    :validation-errors="{
                      isNumber: $t('myAccount.returns.Order id must be a number'),
                    }"
                    :validations="$v.orderId"
                    name="orderId"
                    required
                  />
                  <v-input
                    id="email"
                    ref="email"
                    v-model.trim="$v.email.$model"
                    :label="$t('myAccount.returns.Email')"
                    :validations="$v.email"
                    name="email"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="list-item bg-chalk-white">
              <v-button
                :loading="isLoading"
                :disabled="$v.$invalid || $v.$error"
              >
                {{ isWarningMessageLogIn
                  ? $t('myAccount.returns.Check with this email')
                  : $t('myAccount.returns.Check') }}
              </v-button>

              <v-modal
                ref="returnServiceModal"
                :title="$t('myAccount.returns.Email sent successfully')"
              >
                <div class="my-2 text">
                  {{ $t('myAccount.returns.Please check your inbox') }}
                </div>
              </v-modal>
            </div>
          </div>
        </v-form>
        <div v-else class="list mobile-full-width col-lg-5 px-0">
          <div class="list-item py-5">
            <v-alert-message
              class="mt-2"
            >
              {{ $t('myAccount.returns.As logged in user, please create return/complaint in your account') }}
            </v-alert-message>
          </div>
          <div class="list-item bg-chalk-white">
            <v-button
              :link="localePath('my-account')"
            >
              {{ $t('myAccount.returns.Order history list') }}
            </v-button>
          </div>
        </div>
      </div>
      <div
        v-if="!isInitialized"
        class="d-flex flex-column align-items-center h-100"
      >
        <div
          class="spinner-border text-success display-4 mb-3"
          role="status"
        >
          <span class="sr-only">{{ $t('base.Loading') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators/index.js'
import VInput from '@nsf/base/components/Form/Input.vue'
import VForm from '@nsf/base/components/Form/index.vue'
import VModal from '@nsf/base/components/Modal.vue'
import { emailValidation } from '@nsf/validations/validators/email.js'
import { verifyClient } from '../../repositories/ReturnRepository.js'
import { checkEmail } from '../../repositories/EmailRepository.js'
import HasUser from '../../mixins/HasUser.js'

export default {
  name: 'NewReturnForm',
  components: {
    'v-button': () => import('@nsf/ui-library/components/atoms/Button.vue'),
    VInput,
    VForm,
    'VAlertMessage': () => import('../atomic-design/atoms/AlertMessage.vue'),
    VModal,
  },
  mixins: [validationMixin, HasUser],
  data() {
    return {
      email: '',
      orderId: '',
      isErrorMessage: false,
      isWarningMessageLogIn: false,
      isWarningMessageReturnExists: false,
      isOtcReservationOrderTypeMessage: false,
      isLoading: false,
    }
  },

  validations: {
    email: emailValidation(),

    orderId: {
      required,

      isNumber(value) {
        return !Number.isNaN(value)
      },
    },
  },

  async mounted() {
    if (this.$config.public.recaptchaEnabled) {
      try {
        await this.$recaptcha.init()
      } catch (e) {
        console.error(e)
      }
    }
  },

  methods: {
    async checkReturn() {
      this.isWarningMessageReturnExists = false
      this.isOtcReservationOrderTypeMessage = false
      if (this.isWarningMessageLogIn) {
        return this.verify()
      }

      this.isLoading = true
      this.isErrorMessage = false
      const { clientId } = await checkEmail(this.$v.email.$model, this)
      this.isWarningMessageLogIn = !!clientId

      if (!this.isWarningMessageLogIn) {
        await this.verify()
      }

      this.isLoading = false

      return undefined
    },

    async verify() {
      this.isWarningMessageLogIn = false
      const {
        ok, isReturnExists, isOtcReservationOrderType,
      } = await verifyClient(
        Number(this.orderId),
        this.email,
        this.$config.public.appUrl + this.localePath('new-return-step1'),
      )

      if (isOtcReservationOrderType) {
        this.isOtcReservationOrderTypeMessage = true
      } else if (isReturnExists) {
        this.isWarningMessageReturnExists = true
      } else if (ok) {
        await this.$refs.returnServiceModal.show()
      } else {
        this.isErrorMessage = true
        this.$refs.return.setFormData({
          email: '',
          orderId: '',
        })
        this.$refs?.email?.clearValidityClasses()
        this.$refs?.orderId?.clearValidityClasses()
      }
    },

    onClick() {
      this.isErrorMessage = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/main';

.my-account.container {
  @media screen and (max-width: $lg) {
    padding-right: 0;
    padding-left: 0;

    .title {
      padding-left: 0;
      margin-left: 0;
    }
  }

  > div,
  nav {
    padding-right: 12px;
    padding-left: 12px;
  }
}
</style>
