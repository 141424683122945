<template>
  <component
    :is="componentTag"
    v-if="render"
    :name="nameAttr"
    :autocomplete="autocomplete"
    @change.prevent.stop="$emit('change')"
    @submit.prevent.stop="onSubmit"
  >
    <slot :is-valid="isValid" />
  </component>
</template>

<script>

import {
  isEmpty, isNullish,
} from '@nsf/core/Utils.js'

/**
 * @param {Object} formDataObj Object with some form data
 * @param {Object} form Form component instance
 * @returns {Object} Object with merged data from form to formDataObj
 */
function addDataFromForm(
  formDataObj, form,
) {
  const {
    flatten, name, render,
  } = form
  const data = form.getFormData()

  if (render) {
    if (flatten) {
      formDataObj = {
        ...formDataObj,
        ...data,
      }
    } else if (name) {
      formDataObj[name] = {
        ...formDataObj[name],
        ...data,
      }
    }
  }

  return formDataObj
}

/**
 * @param {Object} formDataObj Object with some form data
 * @param {Object} input Input component instance (with IsFormInput mixin)
 * @returns {Object} Object with merged data from input to formDataObj
 */
function addDataFromInput(
  formDataObj, input,
) {
  const { name } = input
  if (name) {
    formDataObj[name] = input.getValue()
  }

  return formDataObj
}

function setDataToForm(
  formDataObj, form,
) {
  if (isNullish(formDataObj)) {
    return
  }

  const {
    flatten, name,
  } = form
  const formData = flatten ? formDataObj : name ? formDataObj[name] : undefined

  if (formData !== undefined) {
    form.setFormData(formData)
  }
}

function setDataToInput(
  formDataObj, input,
) {
  if (isNullish(formDataObj)) {
    return
  }

  const { name } = input

  if (name && formDataObj && formDataObj[name] !== undefined) {
    input.setValue(formDataObj[name])
  }
}

export default {
  provide() {
    return {
      form: this,
    }
  },

  inject: {
    form: { default: null },
    WindowDimensionProvider: 'WindowDimensionProvider',
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    flatten: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    render: {
      type: Boolean,
      default: true,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },

  data() {
    return {
      forms: [],
      inputs: [],
      cachedData: {},
      validations: [],
      isValid: false,
    }
  },

  computed: {
    componentTag() {
      if (this.tag) {
        return this.tag
      }

      return this.form ? 'div' : 'form'
    },

    nameAttr() {
      return this.componentTag === 'form' ? this.name : null
    },
  },

  watch: {
    defaultValue: {
      handler(val) {
        this.setFormData(val)
      },
      immediate: true,
    },
  },

  created() {
    if (this.form) {
      this.form.registerForm(this)
    }
  },

  beforeDestroy() {
    if (this.form) {
      this.form.unregisterForm(this)
    }
  },

  methods: {
    scrollToForm() {
      const hasHeader = this.WindowDimensionProvider.isMd
      const margin = 10

      const header = document.getElementById('header')
      const { offsetHeight } = header
      const { top } = this.$el.getBoundingClientRect()

      const verticalPosition = top + window.pageYOffset - margin
      const y = hasHeader ? verticalPosition - offsetHeight : verticalPosition

      window.scrollTo({
        top: y,
        behavior: 'smooth',
        block: 'start',
      })
    },

    resetCachedData() {
      this.cachedData = {}
    },

    validate({ isSubmit } = {}) {
      this.isValid = true

      if (!this.render) {
        this.$emit(
          'validated',
          true,
        )
        return true
      }

      for (const $v of this.validations) {
        $v.$touch()
        if ($v.$invalid || $v.$pending) {
          this.isValid = false
        }
      }

      for (const form of this.forms) {
        const validateResult = form.validate()

        if (!validateResult) {
          this.isValid = false
        }

        let firstInvalidForm

        if (isSubmit && !form.isRoot && !firstInvalidForm && !validateResult) {
          form.scrollToForm()
          firstInvalidForm = form
        }
      }
      this.$emit(
        'validated',
        this.isValid,
      )

      return this.isValid
    },

    registerForm(form) {
      this.forms.push(form)
      setDataToForm(
        this.cachedData,
        form,
      )
    },

    unregisterForm(form) {
      for (let i = 0; i < this.forms.length; i++) {
        if (form === this.forms[i]) {
          this.$delete(
            this.forms,
            i,
          )
          this.cachedData = addDataFromForm(
            this.cachedData,
            form,
          )
        }
      }
    },

    registerInput(input) {
      this.inputs.push(input)
      if (input.validations && !isEmpty(input.validations)) {
        this.validations.push(input.validations)
      }

      setDataToInput(
        this.cachedData,
        input,
      )
    },

    unregisterInput(input) {
      for (let i = 0; i < this.inputs.length; i++) {
        if (input === this.inputs[i]) {
          this.$delete(
            this.validations,
            i,
          )
          this.$delete(
            this.inputs,
            i,
          )
          this.cachedData = addDataFromInput(
            this.cachedData,
            input,
          )
        }
      }
    },

    getFormData() {
      let formData = {}

      for (const form of this.forms) {
        formData = addDataFromForm(
          formData,
          form,
        )
      }

      for (const input of this.inputs) {
        formData = addDataFromInput(
          formData,
          input,
        )
      }

      return formData
    },

    setFormData(data) {
      const isInit = Object.keys(this.cachedData).length === 0

      this.cachedData = {
        ...this.cachedData,
        ...data,
      }

      for (const form of this.forms) {
        setDataToForm(
          data,
          form,
        )
      }

      for (const input of this.inputs) {
        setDataToInput(
          data,
          input,
        )
      }

      if (isInit) {
        this.$emit(
          'form-data-init',
          { form: this },
        )
      } else {
        this.$emit(
          'form-data-update',
          { form: this },
        )
      }
    },

    submitRootForm() {
      if (this.form) {
        this.form.submitRootForm()
      } else {
        this.onSubmit()
      }
    },

    onSubmit() {
      if (this.validate({ isSubmit: true })) {
        const formData = this.getFormData()
        this.$emit(
          'submit',
          { formData },
        )
      }
    },
  },
}
</script>
