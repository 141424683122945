<template>
  <div
    class="cookiebot-widget"
  >
    <div ref="cookieDeclaration" class="section mb-2 order-4" />
  </div>
</template>

<script>
import { pushEventVirtualPageviewOther } from '@nsf/gtm/events/virtualPageview.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  rootConfig: {
    seo: {
      title: seoTitle,
    },
  },
} = useAppConfig()

export default {
  name: 'CookiebotWidget',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    id() {
      return this.data?.children?.[0]?.id
    },
  },

  mounted() {
    if (this.id) {
      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('id', 'CookieDeclaration')
      scriptTag.setAttribute('src', `https://consent.cookiebot.com/${this.id}/cd.js`)
      scriptTag.setAttribute('type', 'text/javascript')
      scriptTag.setAttribute('async', 'true')
      this.$refs.cookieDeclaration.appendChild(scriptTag)
    }

    pushEventVirtualPageviewOther(seoTitle)
  },
}
</script>
