import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'ikv81kj93827totuudanhela',
  dataLayerName: 'dataLayerV2',
  persooEnabled: true,

  persooOfferIdAlternatives: 'c9d528dca2aa4baca517853615583f51',
  persooOfferIdBasket: 'ad522275666b41fe86131b5acceb477b',
  persooOfferIdLastViewed: 'eefa24d9b372488480fac5b3e5823f2f',
  persooOfferIdNotFound: 'ea43af0384c044339f2d558d3f46c6b6',
  persooOfferIdPreBasket: 'f341a13de0244f09a3a9a540b7063512',

  persooPreBasketLimit: 6,
  persooNotFoundLimit: 6,
  ...config,
})
