export default {
  maps: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js',
    apiVersion: '3.52',

    defaultProps: {
      gestureHandling: 'greedy',
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      minZoom: {
        pharmacy: 0,
        postOffice: 0,
        paczkomat: 9,
        innoship: 9,
        drmax_box: 0,
        packeta: 0,
        default: 0,
      },
    },

    zoomLevels: {
      initial: {
        pharmacy: 14,
        postOffice: 14,
        paczkomat: 14,
        drmax_box: 12,
        packeta: 14,
        innoship: 16,
        default: 14,
      },
      geolocation: 15,

      /**
     * Point types used in Google Place API
     * List of available place types: https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
     */
      country: 6,
      administrativeAreaLevel1: 8,
      administrativeAreaLevel2: 10,
      locality: 13,
      sublocality: 14,
      neighborhood: 14,
      route: 15,
      townSquare: 15,
      streetAddress: 16,
      establishment: 16,

      noCluster: 15, // according to Pickup Places
    },
  },
}
