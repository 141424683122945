<template>
  <placement-view-provider
    :placement-name="placementName"
  >
    <div
      :id="elementId"
      :class="className"
    >
      <Slider v-if="data" :slider="data" />

      <img
        v-else
        style="height: 330px;"
        :src="`/${PLACEHOLDER_IMAGE_PATH}`"
      >

      <style v-if="styleContent" v-text="styleContent"></style>
    </div>
  </placement-view-provider>
</template>

<script>

import PlacementViewProvider from '@nsf/gtm/providers/PlacementViewProvider.js'
import { applyInlineStyles, createElementId, createStyleElementContent } from '../utils/PagebuilderStyleUtils.js'
import { PLACEHOLDER_IMAGE_PATH } from '../enums/index.js'

export default {
  name: 'VSlider',

  components: {
    PlacementViewProvider,
    Slider: () => import('../components/PageBuilder/Slider/index.vue'),
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      PLACEHOLDER_IMAGE_PATH,
    }
  },

  computed: {
    type() {
      return 'slider'
    },

    placementName() {
      return 'banner-widget-slider'
    },

    mobileStyle() {
      if (!this.data) {
        return ''
      }

      return this.data?.['mobile-style']
    },

    desktopStyle() {
      if (!this.data) {
        return ''
      }

      const wrapperElement = this.data?.children?.[0]

      let wrapperStyles = ''

      if (wrapperElement) {
        wrapperStyles = applyInlineStyles(wrapperElement, wrapperElement.style, { width: true })
      }

      return applyInlineStyles(this.data, this.data.style, { width: true }) + wrapperStyles
    },

    elementId() {
      return createElementId(this.data?.['data-pb-style'])
    },

    styleContent() {
      return createStyleElementContent(this.elementId, this.desktopStyle, this.mobileStyle)
    },

    className() {
      return `${this.data?.class ? `${this.data?.class} ` : ''}pagebuilder-${this.type}`
    },
  },
}
</script>
