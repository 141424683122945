<template functional>
  <ul
    v-bind="data.attrs"
    role="list"
    class="commercial commercial--category-box commercial--nowrap-mob row pb-2 mt-3"
    v-on="listeners"
  >
    <li
      v-for="(cat, i) in props.categories"
      :key="i"
      class="col-8 col-md-3 col-lg-24"
    >
      <v-link
        :to="cat.urlPath"
        class="commercial-image"
      >
        <v-lazy-image
          v-if="cat.image"
          :opts="$options.imageOpts(cat.image)"
          :alt="cat.name"
          :is-not-lazy="!i"
        />
        <component
          :is="props.useH2 ? 'h2' : 'span'"
          v-if="props.showName"
          class="commercial__absolute-title"
        >
          {{ cat.name }}
        </component>
      </v-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CategoryBox',

  props: {
    categories: {
      type: Array,
      default: () => ([]),
    },

    showName: {
      default: true,
      type: Boolean,
    },

    useH2: {
      default: true,
      type: Boolean,
    },
  },

  imageOpts(path) {
    return {
      ...((path.startsWith('https://') && { path }) || { m2: path }),
      w: 450,
      h: 275,
    }
  },
}
</script>

<style scoped lang="css">
li {
  margin-bottom: 12px;
}

.commercial-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 450 / 275;
}
</style>
