import "core-js/modules/es.array.join.js";
import { extractMarginAndPaddingFromStyle, getBackgroundStyle } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import { extractBorderRadius, extractHtml } from '../components/PageBuilder/index.js';
export default {
  name: 'VHtml',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var content = extractHtml(children);
    var style = [extractMarginAndPaddingFromStyle(ctx.props.data.style), extractBorderRadius(ctx.props.data.style), getBackgroundStyle(ctx)].join('');
    return h('div', {
      domProps: {
        innerHTML: content
      },
      style: style,
      class: 'pagebuilder-html'
    });
  }
};