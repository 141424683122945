<template>
  <div class="calorie-calculator-widget">
    <h2 class="calorie-title">
      {{ $t('cms.calorieCalculator.Calorie calculator') }}
    </h2>

    <div class="calorie-description" v-html="$t('cms.calorieCalculator.Description')" />

    <div class="calorie-separator" />

    <div class="calorie-content">
      <div class="calorie-content-left">
        <form @submit.prevent="calculateCalorie">
          <div class="form-group">
            <label class="gray-label">
              {{ $t('cms.calorieCalculator.Gender') }}
            </label>

            <div class="gender-picker">
              <div class="radio" :class="{checked: gender === 'male'}">
                <label for="male">
                  <input
                    id="male"
                    v-model="gender"
                    type="radio"
                    name="gender"
                    value="male"
                  >
                  <span>
                    {{ $t('cms.calorieCalculator.Male') }}
                  </span>
                </label>
              </div>

              <div class="radio" :class="{checked: gender === 'female'}">
                <label for="female">
                  <input
                    id="female"
                    v-model="gender"
                    type="radio"
                    name="gender"
                    value="female"
                  >
                  <span>
                    {{ $t('cms.calorieCalculator.Female') }}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="gray-label">
              {{ $t('cms.calorieCalculator.Age') }}
            </label>
            <input v-model="age" class="form-control" type="text">
          </div>

          <div class="row">
            <div class="form-group col-md-6">
              <label class="gray-label">
                {{ $t('cms.calorieCalculator.Height') }}
              </label>
              <div class="input-group">
                <input v-model="height" class="form-control" type="text">
                <div class="input-group-append">
                  <span class="input-group-text">cm</span>
                </div>
              </div>
            </div>

            <div class="form-group col-md-6 col-weight">
              <label class="gray-label">
                {{ $t('cms.calorieCalculator.Weight') }}
              </label>
              <div class="input-group">
                <input v-model="weight" class="form-control" type="text">
                <div class="input-group-append">
                  <span class="input-group-text">kg</span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="gray-label">
              {{ $t('cms.calorieCalculator.Activity level') }}
            </label>

            <select v-model="activityLevel" class="custom-select">
              <option value="1">
                {{ $t('cms.calorieCalculator.sedentery') }}
              </option>
              <option value="2">
                {{ $t('cms.calorieCalculator.moderate') }}
              </option>
              <option value="3">
                {{ $t('cms.calorieCalculator.active') }}
              </option>
              <option value="4">
                {{ $t('cms.calorieCalculator.very active') }}
              </option>
            </select>
          </div>

          <v-button stretch>
            {{ $t('cms.calorieCalculator.Calculate calorie') }}
          </v-button>
        </form>
      </div>

      <div class="calorie-content-right">
        <div v-if="bmrCalories" class="calorie-results">
          <div class="calorie-title">
            {{ $t('cms.calorieCalculator.Your results') }}
          </div>

          <div class="calories-bmr-tdee">
            <div class="calories-bmr-tdee-left">
              <div>
                <strong>{{ tdeeCalories }}</strong>
                <span>{{ $t('cms.calorieCalculator.calories') }}</span>
              </div>

              <div class="calories-name">
                {{ $t('cms.calorieCalculator.Total Daily Energy Expenditure') }} (TDEE)
              </div>
            </div>

            <div class="calories-bmr-tdee-right">
              <div>
                <strong>{{ bmrCalories }}</strong>
                <span>{{ $t('cms.calorieCalculator.calories') }}</span>
              </div>

              <div class="calories-name">
                {{ $t('cms.calorieCalculator.Basal Metabolic Rate') }} (BMR)
              </div>
            </div>
          </div>

          <div class="calorie-result-text">
            {{ $t('cms.calorieCalculator.How many calories should i eat to') }} <span class="highlight">{{ $t('cms.calorieCalculator.lose weight') }}</span>?
          </div>

          <div class="calories-intake">
            <div class="calories-intake-left">
              <div class="calories-value">
                {{ $t('cms.calorieCalculator.Slow') }}
              </div>
              <div class="calories-value-light">
                (0.5 kg {{ $t('cms.calorieCalculator.per week') }})
              </div>
            </div>
            <div class="calories-intake-right">
              <span class="calories-value">{{ lossCalories.slow }} cal</span>
            </div>
          </div>

          <div class="calories-intake">
            <div class="calories-intake-left">
              <div class="calories-value">
                {{ $t('cms.calorieCalculator.Moderate') }}
              </div>
              <div class="calories-value-light">
                (0.75 kg {{ $t('cms.calorieCalculator.per week') }})
              </div>
            </div>
            <div class="calories-intake-right">
              <span class="calories-value">{{ lossCalories.moderate }} cal</span>
            </div>
          </div>

          <div class="calories-intake">
            <div class="calories-intake-left">
              <div class="calories-value">
                {{ $t('cms.calorieCalculator.Fast') }}
              </div>
              <div class="calories-value-light">
                (1 kg {{ $t('cms.calorieCalculator.per week') }})
              </div>
            </div>
            <div class="calories-intake-right">
              <span class="calories-value">{{ lossCalories.fast }} cal</span>
            </div>
          </div>

          <div class="calorie-result-text">
            {{ $t('cms.calorieCalculator.How many calories should i eat to') }} <span class="highlight">{{ $t('cms.calorieCalculator.gain weight') }}</span>?
          </div>

          <div class="calories-intake">
            <div class="calories-intake-left">
              <div class="calories-value">
                {{ $t('cms.calorieCalculator.Slow') }}
              </div>
              <div class="calories-value-light">
                (0.5 kg {{ $t('cms.calorieCalculator.per week') }})
              </div>
            </div>
            <div class="calories-intake-right">
              <span class="calories-value">{{ gainCalories.slow }} cal</span>
            </div>
          </div>

          <div class="calories-intake">
            <div class="calories-intake-left">
              <div class="calories-value">
                {{ $t('cms.calorieCalculator.Moderate') }}
              </div>
              <div class="calories-value-light">
                (0.75 kg {{ $t('cms.calorieCalculator.per week') }})
              </div>
            </div>
            <div class="calories-intake-right">
              <span class="calories-value">{{ gainCalories.moderate }} cal</span>
            </div>
          </div>

          <div class="calories-intake">
            <div class="calories-intake-left">
              <div class="calories-value">
                {{ $t('cms.calorieCalculator.Fast') }}
              </div>
              <div class="calories-value-light">
                (1 kg {{ $t('cms.calorieCalculator.per week') }})
              </div>
            </div>
            <div class="calories-intake-right">
              <span class="calories-value">{{ gainCalories.fast }} cal</span>
            </div>
          </div>
        </div>

        <div v-if="formError" class="calorie-error">
          <div>{{ formError }}</div>
        </div>

        <div v-if="!bmrCalories && !formError" class="calorie-no-results">
          <div>{{ $t('cms.calorieCalculator.Your results will appear here') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@nsf/ui-library/components/atoms/Button.vue'

export default {
  name: 'BmiCalculatorWidget',

  components: {
    'v-button': Button,
  },

  data() {
    return {
      gender: '',
      age: '',
      height: '',
      weight: '',
      activityLevel: '',
      smallFontMarker: false,
      formError: '',
      bmrCalories: 0,
      tdeeCalories: 0,
    }
  },

  computed: {
    caloriesRoundedToHundrets() {
      return this.tdeeCalories ? Math.round(this.tdeeCalories / 100) * 100 : 0
    },

    lossCalories() {
      return {
        slow: this.caloriesRoundedToHundrets - 500,
        moderate: this.caloriesRoundedToHundrets - 750,
        fast: this.caloriesRoundedToHundrets - 1000,
      }
    },

    gainCalories() {
      return {
        slow: this.caloriesRoundedToHundrets + 500,
        moderate: this.caloriesRoundedToHundrets + 750,
        fast: this.caloriesRoundedToHundrets + 1000,
      }
    },
  },

  methods: {
    validateCalorieForm() {
      this.formError = ''

      const weight = parseFloat(this.weight.replace(',', '.'))
      const height = parseFloat(this.height.replace(',', '.'))
      const age = parseInt(this.age)

      if (!this.gender) {
        this.formError = this.$t('cms.calorieCalculator.Wrong gender')
      } else if (!(age > 0)) {
        this.formError = this.$t('cms.calorieCalculator.Wrong age')
      } else if (!(height > 0)) {
        this.formError = this.$t('cms.calorieCalculator.Wrong height')
      } else if (!(weight > 0)) {
        this.formError = this.$t('cms.calorieCalculator.Wrong weight')
      } else if (!this.activityLevel) {
        this.formError = this.$t('cms.calorieCalculator.Wrong activity level')
      }

      return !this.formError
    },

    calculateCalorie() {
      const weight = parseFloat(this.weight.replace(',', '.'))
      const height = parseFloat(this.height.replace(',', '.'))
      const age = parseInt(this.age)

      if (!this.validateCalorieForm()) {
        this.bmrCalories = 0
        this.tdeeCalories = 0
        return
      }

      if (this.gender === 'male') {
        this.bmrCalories = Math.round(66.5 + (13.75 * weight) + (5.003 * height) - (6.75 * age))
      } else {
        this.bmrCalories = Math.round(655.1 + (9.563 * weight) + (1.85 * height) - (4.676 * age))
      }

      this.tdeeCalories = Math.round(this.bmrCalories * this.tdeeConstant(this.activityLevel))
    },

    tdeeConstant(activityLevel) {
      switch (activityLevel) {
        case '1':
          return 1.2
        case '2':
          return 1.375
        case '3':
          return 1.55
        case '4':
          return 1.725
        default:
          return 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.calorie-calculator-widget {
  border-radius: 16px;
  border: 1px solid rgba(0,0,0, 0.08);
  background-color: rgba(251,251,251, 1);
  padding: 12px;
  margin: 30px auto;
  max-width: 800px;

  .calorie-title {
    @include title-large-spaced;
    color: RGBA(0, 0, 0, 0.88);
    margin: 15px 0 18px 0;
  }

  .calorie-description {
    @include body-small-spaced;
    color: RGBA(0, 0, 0, 0.56);
  }

  .calorie-separator {
    width: 100%;
    background-color: RGB(220, 220, 220);
    height: 1px;
    margin: 16px 0 6px 0;
  }

  form label.gray-label {
    @include label-large-spaced;
    color: RGBA(0, 0, 0, 0.72);
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
  }

  input.form-control, .input-group-text, select {
    @include label-large-spaced;
    color: RGBA(0, 0, 0, 0.72);
  }

  .calorie-content {
    display: flex;
  }

  .calorie-content-left {
    width: 300px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .calorie-content-right {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 60px;
  }

  .calorie-no-results {
    @include title-medium-spaced;
    color: RGBA(0, 0, 0, 0.56);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100px;
  }

  .calorie-error {
    @include title-small-spaced;
    color: RGB(241, 50, 57);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100px;
  }

  .gender-picker {
    padding: 4px;
    border-radius: 8px;
    border: 1px solid rgb(192,192,192);
    background-color: rgb(251,251,251);
    display: flex;
    margin-bottom: 22px;

    div.radio {
      width:100%;
      position: relative;
      border-left: 1px solid rgb(192, 192, 192);

      &:first-child {
        border-left: none;
      }

      &.checked {
        border-left: none;

        & + .radio {
          border-left: none;
        }
      }

      input[type=radio] {
        cursor: pointer;
        width: 1px;
        height: 1px;
        margin: 0;
        padding: 0;
        opacity: 0;
        position: absolute;

        //&:focus ~ span,
        //&:active ~ span {
        //  background-color: red;
        //}

        &:disabled {
          cursor: not-allowed;
        }

        &:checked ~ span {
          border: 2px solid rgba(0,120,190, 1);
          background-color: rgba(234,246,252, 1);
          color: RGBA(0, 0, 0, 0.88);
        }

        &:disabled ~ span.icon {
          border-color: #ddd;
        }
      }

      label {
        display: block;
        text-align: center;
        cursor: pointer;
        margin-bottom: 0;

        span {
          display: block;
          padding: 13px 0;
          border-radius: 4px;
          border: 2px solid transparent;
          background-color: transparent;
          color: RGB(0, 120, 190);
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .highlight {
    color: rgb(0, 120, 190);
  }

  .calorie-result-text {
    @include title-medium-spaced;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.88);
    margin-top: 40px;
  }

  .calories-bmr-tdee {
    @include label-medium-spaced;
    display: flex;
    margin: 30px 0;

    strong {
      font-size: 23.98px;
      color: rgba(0,120,190,1);
    }

    span {
      color: RGBA(0, 0, 0, 0.88)
    }
  }

  .calories-bmr-tdee-left {
    width: 30%;
  }

  .calories-bmr-tdee-right {
    width: 30%;
    margin-left: 50px;
  }

  .calories-name {
    color: RGBA(0, 0, 0, 0.72);
    margin-top: 10px;
  }

  .calories-intake {
    display: flex;
    justify-content: space-between;
    margin: 22px 0;
    align-items: center;
  }

  .calories-value {
    @include title-small-spaced;
  }

  .calories-value-light {
    @include title-small-spaced;
    font-weight: 400;
    margin-top: 10px;
    display: block;
  }

  @media (max-width: $md) {
    .calorie-content {
      display: block;
    }

    .calorie-content-left {
      width: auto;
    }

    .calorie-content-right {
      margin-left: 0;
    }

    .calorie-no-results {
      display: none;
    }

    .calorie-results {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .row > .col-weight {
      padding-top: 0;
    }

    .calories-bmr-tdee-left {
      width: 50%;
    }

    .calories-bmr-tdee-right {
      width: 50%;
    }
  }
}
/* stylelint-enable */
</style>
