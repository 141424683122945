<template functional>
  <component
    :is="props.el"
    v-bind="data.attrs"
    :aria-expanded="props.addExpandedAttribute ? `${props.expanded}` : undefined"
    :class="[data.staticClass].concat(data.class)"
    class="expand"
    v-on="listeners"
  >
    <slot />
  </component>
</template>

<script>

export default {
  name: 'VExtend',

  props: {
    el: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    addExpandedAttribute: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
@media (max-width: 575.98px) {
  .d-sm-block.expand[aria-expanded="false"] {
    max-height: 0;
  }
}

@media (max-width: 767.98px) {
  .d-md-block.expand[aria-expanded="false"] {
    max-height: 0;
  }
}

@media (max-width: 991.98px) {
  .d-lg-block.expand[aria-expanded="false"] {
    max-height: 0;
  }
}

.d-xl-block.expand[aria-expanded="false"] {
  max-height: 0;
}

.expand[aria-expanded="false"] {
  transition: max-height 0.4s ease-out;
  overflow: hidden;
}

.expand[aria-expanded="true"] {
  transition: max-height 0.4s ease-in;
  max-height: 200vh;
  overflow: hidden;
}
</style>
