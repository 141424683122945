<template>
  <div v-if="categoriesOrdered.length" class="section mt-2">
    <ul
      class="categories"
      role="list"
      itemprop="mainEntity"
      itemscope=""
      itemtype="http://schema.org/ItemList"
    >
      <li
        v-for="(category, i) in categoriesDisplayed"
        :key="i"
        class="categories__item"
        itemprop="itemListElement"
        itemscope=""
      >
        <h2
          class="categories__title h3"
          itemprop="name"
        >
          <a
            itemprop="url"
            :href="`/${brandUrl}/${category.urlPath}`"
            @click.prevent="addFilter(category.id)"
          >
            {{ category.name }}
          </a>
        </h2>
        <figure class="image-rounded image-rounded--sm mr-3">
          <v-lazy-image
            :opts="getImageUrl(category)"
            :alt="category.name"
          />
        </figure>
      </li>
      <li
        v-if="showMoreCategoriesButton && !allCategoriesDisplayed"
        class="categories__item"
      >
        <h2
          class="categories__title h3"
          itemprop="name"
        >
          <a
            itemprop="url"
            @click="displayAllCategories()"
          >
            {{ $t('base.Next') }}
          </a>
        </h2>
        <figure class="image-rounded image-rounded--sm mr-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="6"
            viewBox="0 0 28 6"
          >
            <path
              fill="#0072B6"
              fill-rule="evenodd"
              d="M1046,571 C1047.65685,571 1049,572.343146 1049,574 C1049,575.656854 1047.65685,577 1046,577 C1044.34315,577 1043,575.656854 1043,574 C1043,572.343146 1044.34315,571 1046,571 Z M1057,571 C1058.65685,571 1060,572.343146 1060,574 C1060,575.656854 1058.65685,577 1057,577 C1055.34315,577 1054,575.656854 1054,574 C1054,572.343146 1055.34315,571 1057,571 Z M1068,571 C1069.65685,571 1071,572.343146 1071,574 C1071,575.656854 1069.65685,577 1068,577 C1066.34315,577 1065,575.656854 1065,574 C1065,572.343146 1066.34315,571 1068,571 Z"
              transform="translate(-1043 -571)"
            />
          </svg>
        </figure>
      </li>
    </ul>
  </div>
</template>

<script>
const CATEGORIES_DISPLAYED_COUNT = 8
export default {
  inject: ['categories', 'brandUrl'],

  data() {
    return { allCategoriesDisplayed: false }
  },

  computed: {
    categoriesOrdered() {
      if (!this.categories?.length) {
        return []
      }
      return [...this.categories].sort((a, b) => b.productCount - a.productCount)
    },

    categoriesDisplayed() {
      if (this.allCategoriesDisplayed) {
        return this.categoriesOrdered
      }
      return this.categoriesOrdered.length <= CATEGORIES_DISPLAYED_COUNT ? this.categoriesOrdered : [...this.categoriesOrdered].slice(0, CATEGORIES_DISPLAYED_COUNT - 1)
    },

    showMoreCategoriesButton() {
      return this.categories?.length > CATEGORIES_DISPLAYED_COUNT
    },
  },

  methods: {
    getImageUrl(category) {
      if (!category.image) {
        return
      }

      return {
        m2: `/media/catalog/category/${category.image}`,
        w: 52,
        h: 52,
      }
    },

    addFilter(id) {
      // eslint-disable-next-line camelcase
      this.$pushQuery({ a_category_id: id, page: undefined }, '#brand-products')
    },

    displayAllCategories() {
      this.allCategoriesDisplayed = true
    },
  },
}
</script>
