import { setState } from '@nsf/core/Store.js'
import {
  delCookie, getCookie,
} from '@nsf/utils/CookieUtils.js'
import { getExistingSkus } from '../utils/index.js'
import {
  getFavoriteProductsUserData, mergeSkusWithUserData, setFavoriteProductsUserData,
} from '../repositories/FavoriteProductsRepository.js'
import { getFavoritePharmaciesUserData } from '../repositories/FavoritePharmaciesRepository.js'

export const state = () => ({
  skus: [],
  userData: {
    version: 0,
    data: {},
  },
  pharmaciesIds: [],
  pharmaciesUserData: null,
})

export const mutations = {
  setState,
}

export const actions = {

  async loadFavoritePharmacies({ commit }) {
    const pharmaciesUserData = await getFavoritePharmaciesUserData()
    const pharmaciesIds = pharmaciesUserData?.data?.pharmacies?.map((pharmacy) => pharmacy.pickupPlaceId).filter(Boolean) ?? []
    return commit(
      'setState',
      {
        pharmaciesUserData,
        pharmaciesIds,
      },
    )
  },
  async removeFavoriteProducts({ commit }) {
    return commit(
      'setState',
      {
        skus: [],
        userData: {
          version: 0,
          data: {},
        },
      },
    )
  },

  async loadFavoriteProducts(
    {
      commit, state,
    }, { isLoggedIn },
  ) {
    // get favorites from cookies
    const skusInCookies = getCookie('favoriteProducts')
      ?.split(',') ?? []

    const { skus: skusFromCookies } = await getExistingSkus(skusInCookies)

    // if no user, set favorites to vuex
    if (!isLoggedIn) {
      return commit(
        'setState',
        { skus: skusFromCookies },
      )
    }

    // get favorites from CRM
    let userData = await getFavoriteProductsUserData()
    if (!userData.version) {
      return false
    }

    // if favorites in cookies, let them merge with favorites from CRM
    if (skusInCookies.length) {
      const mergedData = mergeSkusWithUserData(
        skusInCookies,
        userData,
      )
      userData = await setFavoriteProductsUserData(mergedData)
      if (userData.version <= state.userData?.version) {
        return false
      }
    }

    // get favorites skus from updated CRM data
    const userDataSkus = userData?.data?.products?.map((product) => product.sku)

    const { skus } = await getExistingSkus(userDataSkus)

    // logged in customers shouldn't have favorites in cookies
    delCookie('favoriteProducts')
    delCookie('favoriteProductsWithCreatedAt')

    return commit(
      'setState',
      {
        userData,
        skus,
      },
    )
  },
}
