/**
 * On the client-side, only keys defined in `public` are available.
 * The entire runtime config is available on the server-side.
 *
 * More details in official doc: https://nuxt.com/docs/api/composables/use-runtime-config
 *
 * @example client-side and server-side
 * import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
 * const { varName } = useRuntimeConfig()
 *
 * @example client-side: can be used $config
 * mounted() {
 *   const { varName } = this.$config.public
 * }
 * <template>
 *  <div>{{ $config.public.varName }}</div>
 * </template>
 */

/** Overriding by environment variables
 *
 * Your desired variables must be defined in your nuxt.config.mjs file
 * Only a specially-named environment variable can override a runtime config property.
 * That is, an uppercase environment variable starting with NUXT_ which uses _ to separate keys and case changes.
 *
 * WARNING! Do not use `NUXT_APP_` prefix, it is reserved prefix for application variables
 *
 * @example override default values
 * runtimeConfig: {
 *   secretVarName: 'default secret', // auto env.: NUXT_SECRET_VAR_NAME
 *   privateVarName: 'default secret', // auto env.: NUXT_PRIVATE_VAR_NAME
 *   otherVarName: 'default secret', // auto env.: NUXT_OTHER_VAR_NAME
 *   public: {
 *     publicVarName: 'default public', // auto env.: NUXT_PUBLIC_PUBLIC_VAR_NAME
 *     myVarName: 'default public', // auto env.: NUXT_PUBLIC_MY_VAR_NAME
 *   },
 * }
 * */

/** Use old environment variable as a default value
 * runtimeConfig: {
 *   public: {
 *     newPublicVar: process.env.OLD_VARIABLE || '', // auto env.: NUXT_PUBLIC_NEW_PUBLIC_VAR
 *   },
 * }
 */

export default {
  runtimeConfig: {
    appUrlInternal: '', // Change name of this variable for NUXT3
    public: {
      appUrl: '',
      cmsHomepageId: 93,
      competitionServiceEnabled: false,
      competitionServiceUrl: '',
      cookieDomain: '.drmax.net', // previously was related to countryCode
      countryCode: '', // The runtime value that indicates the running country (country code).
      deliveryCalculationUrl: '',
      deliveryCalculatorUrl: '', // Delivery Calculator 2.0
      elasticSplittedIndiciesEnabled: true,
      environmentName: 'live',
      favoritePharmaciesEnabled: true, // Temporary runtime during stage testing
      favoriteProductsEnabled: true, // Temporary runtime during stage testing
      mailkitApiUrl: '',
      mailkitClientId: '',
      mailkitClientMd5: '',
      magentoUrl: '',
      magentoUrlInternal: 'http://magento-worker.magento.svc.cluster.local',
      orderTrackingUrl: '',
      pageResolver: 'graphql',
      pickupPlacesUrl: '',
      pickupSelectPharmacyApiEnabled: true,
      pickupSelectPharmacyApiUrl: '',
      placementGenericEnabled: false,
      placementPersonalizedEnabled: false,
      placementBannersEnabled: false,
      placementServiceUrl: '',
      recaptchaEnabled: false, // #20203 - Temporary runtime var during stage testing
      reservationsDisabled: false,
      storeViewCode: '', // default value only for multiStoreEnabled
    },
  },
}
