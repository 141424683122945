<template functional>
  <span class="optional-text">• {{ props.optional }}</span>
</template>

<script>
export default {
  name: 'OptionalAtom',
  props: {
    optional: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.optional-text {
  @include label-medium;
  margin-left: 4px;
  color: $text-black-tertiary;
}
</style>
