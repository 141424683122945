import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default (withOrder, withReservation, withPickupTimes) => Query.named('pickupPlace')
  .toDeliveryCalculator()
  .where('id', variable('pickupPlaceId', 'String!'))
  .where('products', variable('products', '[ProductInput!]'))
  .fields([
    field('id'),
    field('type'),
    field('courierId'),
    field('title'),
    field('location', [
      field('latitude'),
      field('longitude'),
      field('street'),
      field('city'),
      field('description'),
    ]),
    field('openingHours', [
      field('date'),
      field('day'),
      field('isOpen'),
      field('isOpenNonstop'),
      field('timePeriods', [
        field('from'),
        field('to'),
        field('isOpen'),
      ]),
    ]),
    ...withOrder
      ? [field('order', [
          field('available'),
          field('message'),
          ...withPickupTimes
            ? [field('pickupTimes', [
                field('cutoffTime'),
                field('pickupTime'),
              ])]
            : [],
        ])]
      : [],
    ...withReservation
      ? [field('reservation', [
          field('available'),
          field('message'),
          ...withPickupTimes
            ? [field('pickupTimes', [
                field('cutoffTime'),
                field('leadTime'),
                field('pickupTime'),
              ])]
            : [],
        ])]
      : [],
  ])
