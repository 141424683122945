import pushEventPasswordRecoveryEmailSent from '@nsf/gtm/events/passwordRecoveryEmailSent.js'
import pushEventPasswordRecoveryEmailSuccess from '@nsf/gtm/events/passwordRecoveryEmailSuccess.js'
import logger from '@nsf/my-account/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import { withAuth } from '../utils/authDecorator.js'
import { transformUserEmailAndPush } from '../utils/index.js'
import { setEmail } from './EmailRepository.js'
import { getUserById } from './UserRepository.js'

const {
  public: { recaptchaEnabled },
} = useRuntimeConfig()

export const setLoginPassword = async (clientId, { login, password }) => {
  try {
    const { status } = await withAuth(myAccountServiceClient.put)(
      `/clients/${clientId}/eshop-account`,
      {
        login,
        password,
      },
    )

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'setLoginPassword(%s, %s, %s) failed: %s',
      clientId,
      login,
      password,
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const changeUserPassword = async ({ oldPassword, newPassword }) => {
  try {
    const { data, ok } = await withAuth(myAccountServiceClient.put)(
      '/clients/me/password',
      {
        password: newPassword,
        passwordOld: oldPassword,
      },
    )

    if (ok) {
      return {
        isNotValidPassword: false,
        ok: true,
      }
    }

    const parsedResponse = JSON.parse(JSON.parse(data?.detail)?.detail)
    throw new Error(parsedResponse?.err_code)
  } catch (e) {
    let responseObj
    switch (e.message) {
      case '3':
      case '44':
        responseObj = {
          isNotValidPassword: true,
          isPasswordSameAsOld: false,
          ok: false,
        }
        break
      case '13':
        responseObj = {
          isNotValidPassword: false,
          isPasswordSameAsOld: true,
          ok: false,
        }
        break
      default:
        responseObj = {
          isNotValidPassword: false,
          isPasswordSameAsOld: false,
          ok: false,
        }
    }

    logger.error('changeUserPassword(***, ***) failed: %s', responseObj)
    return responseObj
  }
}

export const resetUserPassword = async (formData, ctx) => {
  try {
    let captchaQuery = ''
    if (ctx?.$recaptcha && recaptchaEnabled) {
      captchaQuery = `?captcha=${await ctx.$recaptcha.execute('submit')}`
    }

    const path = `/clients/reset-password${captchaQuery}`

    const { status, ok } = await myAccountServiceClient.post(path, formData)

    if (!ok) {
      const err = new Error(`User reset password error with status ${status}`)
      err.isNetworkError = false
      throw err
    }

    await transformUserEmailAndPush(
      formData.email,
      pushEventPasswordRecoveryEmailSent(),
      ctx,
      false,
    )

    return {
      ok: true,
      networkProblem: false,
      status,
    }
  } catch (e) {
    logger.error('resetUserPassword(***, ***) failed: %s', e.message)

    return {
      ok: false,
      networkProblem: e.isNetworkError !== false,
      status: 500,
    }
  }
}

export const setUserPassword = async (formData) => {
  try {
    const path = `/clients/${formData.clientId}/password`

    const { status, ok } = await myAccountServiceClient.put(path, formData)
    if (ok) {
      pushEventPasswordRecoveryEmailSuccess(formData.clientId)
    }

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error('setUserPassword(***, ***) failed: %s', e.message)

    return {
      ok: false,
      status: 500,
    }
  }
}

export const updateCredentials = async (clientId, payload) => {
  try {
    const { ok: setLoginPasswordOk } = await setLoginPassword(
      clientId,
      payload,
    )
    const { ok: getUserByIdOk, user } = await getUserById(clientId)

    if (!setLoginPasswordOk || !getUserByIdOk) {
      return {
        ok: false,
        status: 500,
      }
    }

    if (user.email) {
      return {
        ok: true,
        status: 204,
      }
    }

    const { ok: setEmailOk } = await setEmail(clientId, payload.login)

    return {
      ok: setEmailOk,
      status: 204,
    }
  } catch (e) {
    logger.error('updateCredentials(%s) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
    }
  }
}
