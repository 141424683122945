/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/1543995637/Product+Detail+notification
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { formatProduct } from '../utils/product.js'

export const EVENT_NAME = 'productDetail'

/**
 * @param {Object} product
 * @param {boolean} isLoggedIn
 */
export const pushEventProductDetail = (product, isLoggedIn = false) => {
  const event = {
    event: EVENT_NAME,

    products: [formatProduct(product, EVENT_NAME, isLoggedIn)],
  }

  usePushEvent(event)
}
