<script>
import Slider from './Slider.vue'

export default {
  name: 'BannerWidget',

  extends: Slider,

  data() {
    return {
      showNavigation: false,
    }
  },

  computed: {
    type() {
      return 'banner'
    },

    placementName() {
      return 'banner-widget'
    },
  },
}
</script>
