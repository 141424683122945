import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'
import footer from './footer.mjs'

export default defineAppConfig({
  logo: {
    src: '/images/company-logo.svg',
    width: 147,
    height: 50,
    alt: 'Dr. Max',
  },
  ...features,
  ...footer,
  ...config,
})
