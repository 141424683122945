import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      myAccountReturnServiceEnabled: false,
      myAccountReturnServiceURL: '',
      crmProxyUrl: '',
      myAccountServerUrl: '',
      placementServiceUrl: '',
    },
  },
})
