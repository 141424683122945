<template>
  <div :id="elementId" :class="className">
    <v-category-box
      v-if="isBigBlocks || isBigBlocksWithText"
      :categories="categoriesLimited"
      :show-name="isBigBlocksWithText"
      :use-h2="false"
    />
    <v-categories
      v-else
      :categories="categoriesLimited"
      class="mb-2"
    />
    <style v-if="styleContent" v-text="styleContent" />
  </div>
</template>
<script>

import logger from '@nsf/catalog/logger.js'
import { applyInlineStyles, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js'
import { getCategoriesByIds } from '@nsf/catalog/repositories/CategoryRepository.js'
import CategoryBox from '@nsf/catalog/components/category/Categories/CategoryBox.vue'

export default {
  name: 'DrmaxCustomCategoryBlock',

  components: {
    'v-categories': () => import('@nsf/catalog/components/category/Categories/index.vue'),
    'v-category-box': CategoryBox,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      categories: [],
    }
  },

  async fetch() {
    const fetchIds = []

    this.configuration.categories.forEach((cat) => {
      const category = this.mapDataFromConfig(cat)
      if (category.id && (!category.name || !category.image || !category.urlPath)) {
        fetchIds.push(category.id)
      }
      this.categories.push(category)
    })

    this.categories.sort((cat1, cat2) => cat2.priority - cat1.priority)

    if (fetchIds.length) {
      const { categories } = await getCategoriesByIds(fetchIds)

      const fetchedCategories = {}
      categories.forEach((cat) => {
        fetchedCategories[cat.id] = cat
      })

      for (const category of this.categories) {
        if (!fetchedCategories[category.id]) {
          // eslint-disable-next-line no-continue
          continue
        }

        if (!category.name) {
          category.name = fetchedCategories[category.id].name
        }
        if (!category.urlPath) {
          category.urlPath = `/${fetchedCategories[category.id].urlPath}`
        }
        if (!category.image && fetchedCategories[category.id].image) {
          category.image = `/media/catalog/category/${fetchedCategories[category.id].image}`
        }
      }
    }
  },

  computed: {
    categoriesLimited() {
      return this.categories.slice(0, this.configuration.categoriesNumberPerLine)
    },

    className() {
      return `${this.data.class ? `${this.data.class} ` : ''}pagebuilder-custom-category ${this.configuration?.appearance}`
    },

    configuration() {
      const children = this.data?.children || []

      let appearance = 'big_blocks'
      let categoriesNumberPerLine = 5

      for (const child of children) {
        switch (child['data-element']) {
          case 'categories_appearance':
            appearance = child['data-categories-appearance'] || child.children?.[0]?.html || appearance
            break
          case 'categories_number':
            categoriesNumberPerLine = child['data-categories-number'] || child.children?.[0]?.html || categoriesNumberPerLine
            if (typeof categoriesNumberPerLine === 'string') {
              categoriesNumberPerLine = Number(categoriesNumberPerLine)
            }
            break
          default:
            console.log('Unknown data-element')
        }
      }

      let dataCategories = []
      if (this.data['data-categories']) {
        try {
          dataCategories = JSON.parse(this.data['data-categories'])
        } catch (e) {
          logger.error('data-categories JSON parse error: %s', e.message)
        }
      }

      return {
        appearance,
        categoriesNumberPerLine,
        categories: dataCategories,
      }
    },

    elementId() {
      return this.data?.['data-pb-style']
    },

    inlineMobileStyles() {
      return applyInlineStyles(this.data, this.data?.['mobile-style'], { width: true })
    },

    inlineStyles() {
      return applyInlineStyles(this.data, this.data.style, { width: true })
    },

    isBigBlocks() {
      return this.configuration?.appearance === 'big_blocks'
    },

    isBigBlocksWithText() {
      return this.configuration?.appearance === 'big_blocks_with_text'
    },

    isSmallBlocks() {
      return this.configuration?.appearance === 'small_blocks'
    },

    styleContent() {
      return createStyleElementContent(this.elementId, this.inlineStyles, this.inlineMobileStyles)
    },
  },

  methods: {
    mapDataFromConfig({
      category_id: id = 0, custom_image: image = [], custom_link: urlPath = '', custom_text: name = '', priority = 0,
    }) {
      return {
        id: Number(id),
        name,
        urlPath,
        image: image[0]?.url || '',
        priority: Number(priority),
      }
    },
  },
}
</script>

<style scoped lang="css">
/* stylelint-disable */
.big_blocks .commercial-image {
  border-radius: 7px;
}
/* stylelint-enable */
</style>
