import { envBool } from '@nsf/utils/EnvUtils.js'

export default {
  robots: {
    allow: (process.env.ROBOTS_ALLOW ?? '*?page*|/_i/*').split('|').concat(['/i18n?lang*']),
    content: {
      live: 'index, follow',
      container: 'index, follow',
      other: 'noindex, nofollow',
    },
    crawlDelay: process.env.ROBOTS_CRAWL_DELAY || '10',
    disabledCrawling: envBool(process.env.ROBOTS_DISABLED_CRAWLING, false),
    disallow: (process.env.ROBOTS_DISALLOW ?? '/*?*').split('|'),
    sitemap: process.env.ROBOTS_SITEMAP,
    sitemapEnabled: envBool(process.env.ROBOTS_SITEMAP_ENABLED, true),
    userAgent: process.env.ROBOTS_USER_AGENT || '*',
  },
}
