import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  rootConfig: {
    global: {
      langIso,
    },
  },
} = useAppConfig()

export const localizeTime = (time) => {
  if (!time) {
    return undefined
  }

  return new Date(time)?.toLocaleTimeString(langIso, { hour: '2-digit', minute: '2-digit' })
}

export const localizeDate = (date) => {
  if (!date) {
    return {
      day: undefined,
      month: undefined,
      year: undefined,
    }
  }

  const dateObject = new Date(date)

  const month = dateObject.getMonth() + 1
  const day = dateObject.getDay()

  return {
    day: day < 10 ? `0${day}` : day,
    month: month < 10 ? `0${month}` : month,
    year: dateObject.getFullYear(),
  }
}

export const toOpeningHours = (openingHours) => openingHours.map((openingHour) => {
  const { day, month, year } = localizeDate(openingHour.date)

  const json = {
    date: `${year}-${month}-${day}`,
    day,
    open: null,
    close: null,
    openTimestamp: null,
    closeTimestamp: null,
    nonstop: openingHour.nonstop,
    closed: !openingHour.open,
  }

  if (openingHour.open) {
    const timestamps = []

    openingHour.openingHour
      .filter((element) => element.open)
      .forEach((element) => {
        timestamps.push(new Date(element.from).valueOf())
        timestamps.push(new Date(element.to).valueOf())
      })

    json.openTimestamp = Math.min(...timestamps)
    json.closeTimestamp = Math.max(...timestamps)

    json.open = localizeTime(json.openTimestamp)
    json.close = localizeTime(json.closeTimestamp)

    json.breaks = openingHour.openingHour
      .filter((element) => !element.open)
      .sort((a, b) => (a.from > b.from ? 1 : -1))
      .map((item) => ({
        from: localizeTime(item.from),
        to: localizeTime(item.to),
        note: item.note,
      }))

    if (json.breaks.length) {
      // Open intervals
      json.intervals = []
      let from = json.open
      json.breaks.forEach((b) => {
        json.intervals.push([from, b.from])
        from = b.to
      })
      json.intervals.push([from, json.close])
    }
  } else {
    json.open = false
  }

  return json
})
