import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapProducts = (products = []) => products.map((product) => mapDataToModel(
  product,
  {
    sku: String,
    name: [
      'productName',
      String,
    ],
    complaint: [
      'isComplainable',
      Boolean,
    ],
    return: [
      'isReturnable',
      Boolean,
    ],
    price: Number,
  },
))

export const getUniqueProducts = (products = []) => {
  const uniqueProducts = []
  products.forEach((product) => {
    const index = uniqueProducts
      .findIndex((p) => p.sku === product.sku)
    if (index > -1) {
      uniqueProducts[index].quantity += 1
    } else {
      uniqueProducts.push(product)
    }
  })
  return uniqueProducts
}

const getDataTestIdBasedOnReason = (reason) => {
  if (reason === 'Výrobok nefunguje/prejavuje závadu') {
    return 'not-working-rsn'
  }

  if (reason === 'Tovar nepotrebujem, chcem ho vrátiť') {
    return 'not-needed-rsn'
  }

  return 'other-rsn'
}

const getDataTestIdBasedOnSolution = (solution) => {
  if (solution === 'Vrátenie peňazí') {
    return 'return-money'
  }

  if (solution === 'Výmena tovaru za nový kus') {
    return 'new-product'
  }

  if (solution === 'Oprava tovaru') {
    return 'fix-product'
  }
}

export const mapReasonsSolutions = (items = [], origin = 'reason') => items.map((product) => ({
  label: product.name,
  value: product.name,
  dataTestId: origin === 'reason' ? getDataTestIdBasedOnReason(product.name) : getDataTestIdBasedOnSolution(product.name),
}))
