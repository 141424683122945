import { createRestClient } from '@nsf/core/RestClient.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    myAccountReturnServiceURL,
    storeViewCode,
  },
} = useRuntimeConfig()

const {
  rootConfig: {
    global: {
      multiStoreEnabled,
    },
  },
} = useAppConfig()

let headers = {}
if (multiStoreEnabled) {
  headers = {
    'Compdev-Website-Code': storeViewCode,
  }
}
const returnServiceClient = createRestClient({
  baseURL: myAccountReturnServiceURL,
  headers,
})

export default returnServiceClient
