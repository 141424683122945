import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

const pushEventPasswordRecoveryEmailSent = () => (clientId) => {
  const event = {
    event: 'passwordRecoveryEmailSent',
    user: {
      id: clientId,
    },
  }
  usePushEvent(event)
}

export default pushEventPasswordRecoveryEmailSent
