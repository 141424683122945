var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('picture', [_vm._l(_vm.sources, function (_ref, i) {
    var mimeType = _ref.mimeType,
      srcset = _ref.srcset,
      media = _ref.media,
      w = _ref.w,
      h = _ref.h;
    return _c('source', {
      key: i,
      attrs: {
        "srcset": srcset,
        "type": mimeType,
        "media": media,
        "width": w || _vm.imageSize.w,
        "height": h || _vm.imageSize.h
      }
    });
  }), _vm._v(" "), _c('img', {
    ref: "img",
    style: _vm.imageStyle,
    attrs: {
      "src": _vm.img,
      "alt": _vm.alt,
      "title": _vm.title,
      "loading": _vm.loading,
      "fetchPriority": _vm.fetchPriority,
      "width": _vm.imageSize.w,
      "height": _vm.imageSize.h
    },
    on: {
      "error": _vm.onError,
      "load": _vm.onLoad
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };