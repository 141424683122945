<template>
  <div class="pregnancy-calculator">
    <header>
      <img src="/images/clubs/calculator.png" alt="" lazy>
      <div class="title">
        {{ $t('clubs.Pregnancy calculator') }}
      </div>
    </header>
    <section>
      <div>
        {{ $t('clubs.We will tell you what week of pregnancy you are') }},<br>
        {{ $t('clubs.enter the date of your last period') }}:
      </div>
      <div class="form">
        <div>
          <v-dropdown
            placeholder="--"
            :dropdown-options="dayOptions"
            @select="onSelectDay"
          />
        </div>
        <div>
          <v-dropdown
            v-model="periodMonth"
            placeholder="--"
            :dropdown-options="monthOptions"
            @select="onSelectMonth"
          />
        </div>
        <div>
          <v-dropdown
            v-model="periodYear"
            placeholder="--"
            :dropdown-options="yearOptions"
            @select="onSelectYear"
          />
        </div>
        <v-button
          variant="secondary"
          @click="calculate()"
        >
          {{ $t('clubs.Find out') }}
        </v-button>
      </div>
      <div v-if="errorMessage" class="text-center invalid-feedback">
        {{ errorMessage }}
      </div>
      <img class="separator" src="/images/clubs/separator-heart.svg" alt="" lazy>
    </section>
    <footer class="row">
      <div class="col col-sm-4">
        <div class="ico-week">
          <svg>
            <use xlink:href="/images/clubs/icons.svg#calendar" />
          </svg>
          <br>
          <div v-if="pregnancyWeek">
            <strong>{{ $t('clubs.week', [pregnancyWeek]) }}</strong>
          </div>
          <span>{{ $t('clubs.Pregnancy week') }}</span>
        </div>
      </div>
      <div class="col col-sm-4">
        <div class="ico-date">
          <svg>
            <use xlink:href="/images/clubs/icons.svg#calendar-check" />
          </svg>
          <br>
          <div v-if="birthDate">
            <strong>{{ birthDate }}</strong>
          </div>
          <span>{{ $t('clubs.Date of birth') }}</span>
        </div>
      </div>
      <div class="col col-sm-4">
        <div class="ico-weight">
          <svg>
            <use xlink:href="/images/clubs/icons.svg#weight" />
          </svg>
          <br>
          <div v-if="fetusWeight">
            <strong>{{ fetusWeight }}/{{ fetusHeight }}</strong>
          </div>
          <span>{{ $t('clubs.Baby weight and length') }}</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import Button from '@nsf/ui-library/components/atoms/Button.vue'
import Dropdown from '@nsf/ui-library/components/molecules/Dropdown.vue'
import dayjs from 'dayjs'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import period from '@nsf/clubs/public/period.json'

const {
  rootConfig: {
    global: {
      langIso,
    },
  },
} = useAppConfig()

export default {
  name: 'PregnancyCalculator',

  components: {
    'v-button': Button,
    'v-dropdown': Dropdown,
  },

  data() {
    const currentYear = (new Date()).getFullYear()
    return {
      periodDay: '',
      periodMonth: '',
      periodYear: '',
      birthDate: '',
      pregnancyWeek: '',
      fetusWeight: '',
      fetusHeight: '',
      errorMessage: '',
      dayOptions: this.numberOptions(1, 31),
      monthOptions: this.numberOptions(1, 12),
      yearOptions: [
        {
          label: '--',
          value: '',
        },
        { label: currentYear - 1, value: currentYear - 1 },
        { label: currentYear, value: currentYear },
      ],
    }
  },

  methods: {

    numberOptions(min, max) {
      const options = [{
        label: '--',
        value: '',
      }]
      for (let i = min; i <= max; i++) {
        options.push({
          label: i,
          value: i,
        })
      }
      return options
    },

    resetData() {
      this.birthDate = ''
      this.pregnancyWeek = ''
      this.fetusWeight = ''
      this.fetusHeight = ''
      this.errorMessage = ''
    },

    onSelectDay(e) {
      this.periodDay = e.value.toString()
    },

    onSelectMonth(e) {
      this.periodMonth = e.value.toString()
    },

    onSelectYear(e) {
      this.periodYear = e.value.toString()
    },

    calculate() {
      this.resetData()

      const lastPeriod = dayjs()
        .year(parseInt(this.periodYear))
        .month(parseInt(this.periodMonth) - 1)
        .date(parseInt(this.periodDay))

      if (lastPeriod.isValid()) {
        this.pregnancyWeek = Math.ceil(dayjs().diff(lastPeriod, 'weeks', true))
        if (this.pregnancyWeek < 1 || this.pregnancyWeek > 40) {
          this.pregnancyWeek = ''
          this.errorMessage = this.$t('clubs.The pregnancy calculator is only for the period of pregnancy')
        } else {
          this.birthDate = lastPeriod.add(266, 'day').format('DD.MM.YYYY')
          if (this.pregnancyWeek > 5) {
            const week = period.weeks.filter((item) => item.week === this.pregnancyWeek)[0]
            this.fetusWeight = (week.weight > 1000)
              ? `${this.formatNumber(week.weight / 1000)} kg`
              : `${this.formatNumber(week.weight)} g`
            this.fetusHeight = `${this.formatNumber(week.height / 10)} cm`
          } else {
            this.fetusWeight = '<1 g'
            this.fetusHeight = '<4 mm'
          }
        }
      } else {
        this.errorMessage = this.$t('clubs.Invalid last period date')
      }
    },
    formatNumber(number) {
      return number.toLocaleString(langIso, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })
    },
  },
}
</script>

<style lang="scss">
/* stylelint-disable */
/* purgecss start ignore */

.pregnancy-calculator {
  background: #fef6f8;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;
  img {
    margin: 0 auto;
  }
  header {
    > img {
      margin: -3rem auto 0;
    }
    .title {
      font-size: 1.2rem;
      font-weight: 400;
      padding-top: 1rem;
    }
  }
  section {
    padding: .5rem 0 0 0;
    .form {
      padding-bottom: 10px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      row-gap: 10px;
      white-space: nowrap;

      .dropdown-select {
        > .search-text {
          font-size: 1.1em !important;
        }
      }

      > button {
        min-height: 42px;
      }
    }

    .invalid-feedback {
      display: block;
    }

    .separator {
      margin-top: 1rem;
      max-width: 287px;
      width: 100%;
    }
  }
  footer {
    padding-top: 1rem;
    .col {
      div {
        padding-bottom: .3em;
        strong {
          color: #ab5d8c;
          font-weight: 700;
          font-size: 1.3em;
        }
      }
      span {
        font-weight: 500;
      }
      svg {
        fill: #ab5d8c;
        width: 46px;
        height: 46px;
        margin-bottom: .75rem;
      }
    }
  }
}

/* purgecss end ignore */
/* stylelint-enable */
</style>
