import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { isArray } from '@nsf/core/Utils.js';
import { extractDeclarationFromStyle, applyInlineStyles, extractPaddingFromStyle, createStyleElementContent, createElementId } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import { extract, extractClass, extractStyle, renderChildren, extractMobileStyle } from '../components/PageBuilder/index.js';
var columnAlignment = function columnAlignment(data) {
  if (!data['data-appearance']) {
    return '';
  }
  if (data['data-appearance'] === 'full-height') {
    return '';
  }
  if (data['data-appearance'] === 'align-center') {
    return 'align-self:center;';
  }
  if (data['data-appearance'] === 'align-bottom') {
    return 'align-self:flex-end;';
  }
  if (data['data-appearance'] === 'align-top') {
    return 'align-self:flex-start;';
  }
  return '';
};
var getGridClassByWidth = function getGridClassByWidth(widthInPercent) {
  if (widthInPercent) {
    var columns = Math.round(12 * (widthInPercent / 100));
    if (columns === 4) {
      return "col-12 col-sm-4 col-md-4";
    }
    if (columns <= 6) {
      return "col-12 col-sm-6 col-md-".concat(columns);
    }
    return "col-12 col-sm-12 col-md-".concat(columns);
  }
  return '';
};
var _containsText = function containsText(children) {
  if (!isArray(children)) {
    return false;
  }
  var _iterator = _createForOfIteratorHelper(children),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var child = _step.value;
      if (child['data-content-type'] === 'text') {
        return true;
      }
      if (_containsText(child.children)) {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return false;
};
export default {
  name: 'VColumn',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];

    // we will count grid classes by width style
    var style = extractStyle(ctx.props.data, 0);
    var mobileStyle = extractMobileStyle(ctx.props.data, 0);

    // column width
    var widthValue = extractDeclarationFromStyle('width', style).declarationValue;
    if (widthValue.startsWith('calc')) {
      widthValue = extract(widthValue, /[0-9]+%/) || '';
    }
    var gridClass = getGridClassByWidth(parseFloat(widthValue));
    var cssClasses = extractClass(ctx.props.data, 0);
    var elementId = createElementId(ctx.props.data['data-pb-style']);
    var inlineStyles = applyInlineStyles(ctx.props.data, style, {
      width: true,
      padding: true
    }) + columnAlignment(ctx.props.data);
    var mobileInlineStyles = applyInlineStyles(ctx.props.data, mobileStyle, {
      width: true,
      padding: true
    });

    // if padding is 10px, it means it is default value from magento 2.3 a we will not apply it
    // otherwise it will break current visual of columns
    var padding = extractPaddingFromStyle(style);
    if (padding !== 'padding: 10px;') {
      inlineStyles += padding;
    }
    var paddingMobile = extractPaddingFromStyle(mobileStyle);
    if (paddingMobile !== 'padding: 10px;') {
      mobileInlineStyles += paddingMobile;
    }
    var data = {
      class: [gridClass || 'col', _containsText(children) ? 'text' : '', cssClasses].join(' ')
    };
    var styleElementContent = createStyleElementContent(elementId, inlineStyles, mobileInlineStyles);
    var styleElement = h('style', {}, styleElementContent);
    return h('div', data, [h('div', {
      attrs: {
        id: elementId
      }
    }, [renderChildren(h, ctx, children), styleElement])]);
  }
};