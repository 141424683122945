<template>
  <v-toc-lazy :data="data" :computed-anchors="computedAnchors" />
</template>

<script>

export default {
  name: 'TableOfContents',

  components: {
    'v-toc-lazy': () => import('@nsf/base/components/PageBuilder/Lazy/DrmaxTableOfContentsLazy.vue'),
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    computedAnchors: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
