import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import { renderChildren } from '../components/PageBuilder/index.js';
import Extend from '../components/Transitions/Extend.vue';
export default {
  name: 'ButtonAccordion',
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      expanded: false
    };
  },
  methods: {
    toggle: function toggle() {
      this.expanded = !this.expanded;
    }
  },
  render: function render(h) {
    var children = this.data.children || [];
    var data = children.flatMap(function (child) {
      return child.children;
    });
    var buttonContent = data[0].children || [];
    var accordionContent = data.slice(1) || [];
    var button = h('button', {
      on: {
        click: this.toggle
      },
      props: {
        'aria-expanded': this.expanded
      },
      class: 'btn btn-success-highlighted mb-2 mr-1'
    }, renderChildren(h, this, buttonContent));
    var accordion = h(Extend, {
      props: {
        expanded: this.expanded,
        el: 'div'
      },
      class: 'd-xl-block'
    }, renderChildren(h, this, accordionContent));
    return h('div', [button, accordion]);
  }
};