<template>
  <v-products-lazy :data="data" />
</template>

<script>

export default {
  name: 'VProducts',

  components: {
    'v-products-lazy': () => import('@nsf/base/components/PageBuilder/Lazy/ProductsLazy.vue'),
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
