import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

const pushEventPasswordRecoveryEmailSuccess = (clientid) => {
  const event = {
    event: 'passwordRecoverySuccess',
    user: {
      id: Number(clientid),
    },
  }
  usePushEvent(event)
}

export default pushEventPasswordRecoveryEmailSuccess
