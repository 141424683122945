import logger from '@nsf/base/logger.js'
import flatMap from 'lodash/flatMap.js'
import { Query } from '@nsf/core/ElasticSearch.js'

/**
 * Get banners by type and source. Available types in elasic are:
 *
 * @param id {number | string} Id of desired entity.
 * @param type {'brand_banners' | 'category_banners' | 'homepage_banners' | 'sales_banners'} Index name of the banner type.
 * @param source {'promo_actions' | 'salesrules'} Index name of the banner source.
 * @return {Promise<{banners: null}|Array>}
 */
export const getPromoActionsBanners = async (
  ids,
  type,
) => {
  try {
    const query = await Query.promoActions()
      .only(type)

    // if we have some promo actions, we will add condition to ES
    if (ids.promo_actions) {
      query.must((q) => {
        q.whereIn(`${type}.source.promo_actions`, ids.promo_actions?.split(','))
      })
    }

    // if we have some sale rules, we will add condition to ES
    if (ids.salesrule) {
      query.must((q) => {
        q.whereIn(`${type}.source.salesrules`, ids.salesrule?.split(','))
      })
    }

    const _response = await query
      .sort('id')
      .getAll()

    return flatMap(_response, (item) => item[type])
  } catch (e) {
    logger.error('getPromoActionsBanners() failed: %s', e.message)

    return { banners: null }
  }
}

export const getPromoActionsSideBanner = async () => {
  try {
    const promoActions = await Query.promoActions()
      .only('sidebar_banners')
      .exists('sidebar_banners')
      .sort('id', 'asc')
      .size(1)
      .getAll()

    if (!promoActions?.length) {
      return { banner: null }
    }

    return { banner: promoActions[0].sidebar_banners[0] }
  } catch (e) {
    logger.error('getPromoActionsBanners() failed: %s', e.message)

    return { banner: null }
  }
}
