import {
  extract,
  extractBorder,
  extractBorderRadius,
  extractMinHeight,
  findFirstElement,
  getPropsData,
} from '@nsf/base/components/PageBuilder/index.js'
import logger from '@nsf/base/logger.js'
import { mdMax } from '@nsf/ui-library/assets/styles/responsive.scss'

export const extractDeclarationFromStyle = (declarationProperty, style) => {
  if (!style) {
    return { declarationProperty: '', declarationValue: '' }
  }
  const styles = style?.split(';')
    .filter((styleItem) => styleItem.trim().startsWith(declarationProperty))
    .map((filteredStyleItem) => {
      const declaration = filteredStyleItem.trim().split(': ')
      return { declarationProperty: declaration[0], declarationValue: declaration[1] }
    })

  return styles[0] ?? { declarationProperty: '', declarationValue: '' }
}

export const extractMarginAndPaddingFromStyle = (style) => {
  if (!style) {
    return ''
  }
  const { declarationProperty: marginKey, declarationValue: marginValue } = extractDeclarationFromStyle('margin', style)
  const {
    declarationProperty: paddingKey,
    declarationValue: paddingValue,
  } = extractDeclarationFromStyle('padding', style)
  let marginAndPadding = marginValue ? `${marginKey}: ${marginValue}; ` : ''
  if (paddingValue) {
    marginAndPadding += `${paddingKey}: ${paddingValue}; `
  }

  return marginAndPadding
}

export const extractPaddingFromStyle = (style) => {
  if (!style) {
    return ''
  }

  let inlineStyle = extract(style, /padding: .+?;/) || ''
  inlineStyle += extract(style, /padding-left: .+?;/) || ''
  inlineStyle += extract(style, /padding-top: .+?;/) || ''
  inlineStyle += extract(style, /padding-bottom: .+?;/) || ''
  inlineStyle += extract(style, /padding-right: .+?;/) || ''

  return inlineStyle
}

export const extractMarginFromStyle = (style) => {
  if (!style) {
    return ''
  }

  let inlineStyle = extract(style, /margin: .+?;/) || ''
  inlineStyle += extract(style, /margin-left: .+?;/) || ''
  inlineStyle += extract(style, /margin-top: .+?;/) || ''
  inlineStyle += extract(style, /margin-bottom: .+?;/) || ''
  inlineStyle += extract(style, /margin-right: .+?;/) || ''

  return inlineStyle
}

export const setFlexAlignment = (style, flex = 'd-flex') => {
  if (!style) {
    return ''
  }

  const styleMap = {
    left: 'justify-content-start',
    right: 'justify-content-end',
    center: 'justify-content-center',
  }

  return `${flex} ${styleMap[style]}`
}

export const getBackgroundImages = (ctx) => {
  try {
    return JSON.parse(getPropsData(ctx, 'data-background-images')?.replace(/\\/g, ''))
  } catch (e) {
    return {
      // eslint-disable-next-line camelcase
      desktop_image: null,
      // eslint-disable-next-line camelcase
      mobile_image: null,
    }
  }
}

export const extractBackgroundImages = (data) => {
  try {
    return JSON.parse(data?.['data-background-images']?.replace(/\\/g, ''))
  } catch (e) {
    return {
      // eslint-disable-next-line camelcase
      desktop_image: null,
      // eslint-disable-next-line camelcase
      mobile_image: null,
    }
  }
}

export const composeBackgroundImageCssStyle = (image, style) => {
  if (!image) {
    return ''
  }

  let cssStyle = `background-image: url('${image}');`
  if (style) {
    cssStyle += extract(style, /background-position: .+?;/) || ''
    cssStyle += extract(style, /background-size: .+?;/) || ''
    cssStyle += extract(style, /background-repeat: .+?;/) || 'background-repeat: no-repeat;'
    cssStyle += extract(style, /background-attachment: .+?;/) || ''
  } else {
    cssStyle += 'background-repeat: no-repeat;'
  }

  return cssStyle
}

export const getBackgroundColor = (ctx) => extract(ctx.props.data.style, /background-color: .+?;/) || ''

export const extractBackgroundColor = (data) => extract(data?.style, /background-color: .+?;/) || ''

export const getBackgroundStyle = (ctx) => {
  const images = getBackgroundImages(ctx)
  if (images?.desktop_image) {
    return composeBackgroundImageCssStyle(images?.desktop_image, ctx.props.data.style)
  }
  if (images?.mobile_image) {
    return composeBackgroundImageCssStyle(images?.mobile_image, ctx.props.data.style)
  }

  return getBackgroundColor(ctx)
}

export const extractBackgroundStyle = (data) => {
  const images = extractBackgroundImages(data)
  let style = ''

  if (images?.desktop_image) {
    style += composeBackgroundImageCssStyle(images?.desktop_image, data.style)
  } else if (images?.mobile_image) {
    style += composeBackgroundImageCssStyle(images?.mobile_image, data.style)
  }

  style += extractBackgroundColor(data)
  return style
}

export const extractMobileBackgroundStyle = (data) => {
  const images = extractBackgroundImages(data)
  let style = ''

  if (images?.mobile_image) {
    style += composeBackgroundImageCssStyle(images?.mobile_image, data.style)
  } else if (images?.desktop_image) {
    style += composeBackgroundImageCssStyle(images?.desktop_image, data.style)
  }

  return style
}

export const extractVerticalAlign = (string) => {
  let extracted = ''

  extracted += extract(string, /display: .+?;/) || ''
  extracted += extract(string, /flex-direction: .+?;/) || ''
  extracted += extract(string, /justify-content: .+?;/) || ''

  return extracted
}

export const extractHorizontalAlign = (styles) => extract(styles, /text-align: .+?;/) || ''

export const extractWidth = (string) => extract(`;${string}`, /;width: .+?;/) || ''

export const createElementId = (id) => {
  if (!id) {
    return undefined
  }

  return `PB-${id}`
}

export const createStyleElementContent = (id, desktopStyle, mobileStyle) => {
  if (!id || id === 'PB-undefined') {
    return ''
  }

  const desktop = desktopStyle ? `#${id} {${desktopStyle}}` : ''
  const mobile = mobileStyle ? `@media (max-width: ${mdMax}px) {#${id} {${mobileStyle}}}` : ''

  return `${desktop} ${mobile}`
}

export const applyInlineStyles = (data, stylesFromMagento, excludedStyles = {}) => {
  try {
    let inlineStyles = ''

    // apply border
    if (!excludedStyles.border) {
      inlineStyles += extractBorder(stylesFromMagento)
    }

    // apply border radius
    if (!excludedStyles.borderRadius) {
      inlineStyles += extractBorderRadius(stylesFromMagento)
    }

    // apply margin
    if (!excludedStyles.margin) {
      inlineStyles += extractMarginFromStyle(stylesFromMagento)
    }

    // apply padding
    if (!excludedStyles.padding) {
      inlineStyles += extractPaddingFromStyle(stylesFromMagento)
    }

    // apply width
    if (!excludedStyles.width) {
      inlineStyles += extractWidth(stylesFromMagento)
    }

    // apply background
    if (!excludedStyles.background) {
      inlineStyles += extractBackgroundStyle(data)
    }

    // apply border radius
    if (!excludedStyles.borderRadius) {
      inlineStyles += extractBorderRadius(stylesFromMagento)
    }

    // apply min height
    if (!excludedStyles.minHeight) {
      inlineStyles += extractMinHeight(stylesFromMagento)
    }

    // apply vertical alignment
    if (!excludedStyles.verticalAlign) {
      inlineStyles += extractVerticalAlign(stylesFromMagento)
    }

    // apply horizontal alignment
    if (!excludedStyles.horizontalAlign) {
      inlineStyles += extractHorizontalAlign(stylesFromMagento)
    }

    return inlineStyles
  } catch (e) {
    logger.withTag('PageBuilder').error('applyInlineStyles error: %s', e.message)
    return ''
  }
}

export const applyLoadingEagerToFirstImage = (content) => {
  const firstImage = findFirstElement(
    content || {},
    (o) => o.tag === 'img' || o['data-content-type'] === 'image',
    30,
  )

  if (!firstImage) {
    return
  }

  if (firstImage.tag === 'img') {
    firstImage.disabledLazy = true
    firstImage.imagePreload = true
  } else if (firstImage.children?.length >= 2) {
    // data-content-type: Image has always 2 children: desktop and mobile image
    firstImage.children[0].disabledLazy = true
    firstImage.children[0].imagePreload = true
    firstImage.children[1].disabledLazy = true
    firstImage.children[1].imagePreload = true
  } else if (firstImage.children?.[0]?.tag === 'a' && firstImage.children[0].children?.length >= 2) {
    // image has a link. So we need to set disabledLazy in deeper element
    firstImage.children[0].children[0].disabledLazy = true
    firstImage.children[0].children[0].imagePreload = true
    firstImage.children[0].children[1].disabledLazy = true
    firstImage.children[0].children[1].imagePreload = true
  }
}
