import { renderChildren } from './index.js';
export default {
  name: 'VRenderContent',
  props: {
    content: {
      type: Array,
      required: true
    },
    useAllAttribs: {
      type: Boolean,
      default: false
    }
  },
  render: function render(h) {
    var children = this.content ? this.content : [];
    var data = {
      class: 'pb-render-content'
    };
    return h('div', data, renderChildren(h, {
      useAllAttribs: this.useAllAttribs
    }, children));
  }
};