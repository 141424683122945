var render = function render(_c,_vm){return _c(_vm.$options.componentTag(_vm.props.link),_vm._g(_vm._b({tag:"component",class:[_vm.data.staticClass].concat(_vm.data.class).concat([
    'drmax-btn',
    `drmax-btn--${_vm.props.variant}`,
    `drmax-btn--${_vm.props.size}`,
    `drmax-btn-icon--${_vm.props.iconPosition}`,
    {'drmax-btn--only-icon': _vm.$options.hasOnlyIcon(_vm.$slots.icon, _vm.$slots.default)},
    {'drmax-btn--loading': _vm.props.loading},
    {'drmax-btn--stretch': _vm.props.stretch},
  ]),attrs:{"disabled":_vm.props.disabled || _vm.props.loading,"to":_vm.props.link}},'component',_vm.data.attrs,false),_vm.listeners),[(_vm.$options.hasIcon(_vm.$slots.icon))?_c('div',{staticClass:"drmax-btn-icon-slot"},[(!_vm.props.loading)?_c('div',{class:[_vm.props.iconWrapperClasses]},[_vm._t("icon")],2):_c('span',{staticClass:"spinner-border spinner-border-sm drmax-btn-icon-spinner",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),(_vm.$options.hasText(_vm.$slots.default))?_c('div',{staticClass:"drmax-btn-text-div"},[_c('span',{staticClass:"drmax-btn-text-slot",style:({ opacity: _vm.$options.isTextInvisible(_vm.$slots.icon, _vm.props.loading) ? '0' : '1' })},[_vm._t("default")],2),_vm._v(" "),(_vm.$options.isTextInvisible(_vm.$slots.icon, _vm.props.loading))?_c('div',{staticClass:"drmax-btn-text-spinner-wrapper"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$options.hasIcon(_vm.$slots.icon) && _vm.props.loading),expression:"!$options.hasIcon($slots.icon) && props.loading"}],staticClass:"spinner-border spinner-border-sm drmax-btn-text-spinner",attrs:{"aria-hidden":"true"}})]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }