import { CategoryBuilder } from '@nsf/catalog/builders/CategoryBuilder.js'
import { fetchFilterPrefix, getFilter } from '@nsf/catalog/utils/BuilderUtils.js'
import {
  getCategoryFilterPageById,
} from '@nsf/catalog/repositories/CategoryRepository.js'
import { getAttributeById } from '@nsf/catalog/repositories/AttributeRepository.js'

// eslint-disable-next-line import/prefer-default-export
export class CategoryFilterBuilder extends CategoryBuilder {
  async #fetchFilterPageAttribute() {
    // eslint-disable-next-line no-underscore-dangle
    const attribute = await getAttributeById(this._data._filterPage.attributeId)

    return { _attribute: attribute }
  }

  async #fetchCategoryFilterPage() {
    const filterPage = await getCategoryFilterPageById(this.id())

    if (filterPage) {
      filterPage.id = this.id()
    }

    return {
      _filterPage: filterPage,
      ...(!filterPage && { statusCode: 404 }),
    }
  }

  getFilter() {
    const filter = getFilter(this.query('filter'), this._data)

    // we need to apply filter from category filter page

    // eslint-disable-next-line no-underscore-dangle
    const code = this._data._attribute.attributeCode
    // eslint-disable-next-line no-underscore-dangle
    const optionId = this._data._filterPage.attributeOptionId

    const existingFilterIndex = filter.findIndex((f) => f.startsWith(`${code},`))

    if (existingFilterIndex > -1) {
      filter[existingFilterIndex] += `,${optionId}`
    } else {
      filter.push(`${code},${optionId}`)
    }

    return filter
  }

  async load() {
    await super.load([
      this.#fetchCategoryFilterPage,
    ])

    await super.load([
      this.fetchCategory,
      this.#fetchFilterPageAttribute,
      fetchFilterPrefix,
    ])

    await super.load([
      this.fetchProducts,
      this.fetchCategories,
      this.fetchSubCategories,
      this.fetchPromoActions,
    ])

    await super.load([
      this.buildHierarchicalCategories,
    ])
  }
}
