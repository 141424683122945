import { variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { leafletListQuery } from '@ioc/graphql/queries.js'

export const getDrugLeaflets = async(query) => {
  const res = await leafletListQuery()
    .where('name', variable('name', REQUIRED_STRING))
    .bind({ name: query })
    .get()

  return res || []
}
