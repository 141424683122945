<template>
  <section>
    <div
      v-if="(isArticlesList || isCarousel) && articles.length"
      class="pagebuilder-articles"
    >
      <div
        :ref="ref"
        class="pagebuilder-articles-anchor"
      />

      <ArticlesList
        v-if="isArticlesList"
        :articles="articles"
        :title="configuration.title"
        :show-title="configuration.showTitle"
        :count="count"
        :per-page="configuration.articlesLimit"
        :is-paging="isPaging"
        :page-with-id="pageWithId"
        :alignment="alignment"
        @paginationPageClick="onPaginationPageClick"
      />

      <template v-else>
        <h2
          v-if="configuration.showTitle"
          class="carousel-headline"
        >
          {{ configuration.title }}
        </h2>

        <v-articles-carousel
          :articles="articles"
        />
      </template>
    </div>

    <div
      v-else-if="isMainAndNewest"
      class="pagebuilder-recent-articles"
    >
      <v-fetch-loader>
        <RecentArticles
          v-if="articles.length"
          :articles-obj="{
            title: configuration.title,
            showTitle: configuration.showTitle,
            articles
          }"
        />
      </v-fetch-loader>
    </div>
  </section>
</template>

<script>
import { getBlockArticles } from '@nsf/articles/repositories/ArticleRepository.js'
import { isEmpty } from '@nsf/core/Utils.js'

export default {
  name: 'ArticleBlock',

  components: {
    'ArticlesList': () => import('@nsf/articles/components/ArticlesList/index.vue'),
    'RecentArticles': () => import('@nsf/articles/components/RecentArticles/index.vue'),
    'v-articles-carousel': () => import('@nsf/base/components/PageBuilder/Articles/Carousel.vue'),
    'v-fetch-loader': () => import('@nsf/base/components/FetchLoader.vue'),
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      articles: [],
      count: 0,
      index: 0,
      scrollToView: false,
    }
  },

  async fetch() {
    if (!this.articleBlock) {
      return
    }
    if (this.isPaging && !this.isMainAndNewest) {
      this.articleBlock.page = this.getCurrentPage()
    }

    const { count, articles } = await getBlockArticles(this.articleBlock)
    this.articles = articles
    this.count = count

    if (this.scrollToView && this.$refs[this.ref]) {
      this.$refs[this.ref].scrollIntoView({
        behavior: 'smooth',
      })
      this.scrollToView = false
    }
  },

  computed: {
    alignment() {
      const regex = /text-align: (.+?);/
      const match = this.data.style.match(regex)
      return (match && match.length > 1) ? match[1] : match
    },

    articleBlock() {
      if (isEmpty(this.configuration)) {
        return null
      }
      const filters = {
        ...(this.configuration.tag.length) && { tag: this.configuration.tag },
        ...(this.configuration.tag.length > 1) && { tagOperator: this.configuration.tagOperator },

        ...(this.configuration.category.length) && { category: this.configuration.category },
        ...(this.configuration.category.length > 1) && { categoryOperator: this.configuration.categoryOperator },

        ...(this.configuration.post.length) && { post: this.configuration.post },
        ...(this.configuration.post.length > 0) && { postOperator: this.configuration.postOperator },

        operator: this.configuration.operator,
      }

      return {
        articlesLimit: this.configuration.articlesLimit,
        ...(this.sortBy) && { sort: this.sortBy },
        ...(this.configuration.sortOrder) && { sortOrder: this.configuration.sortOrder },
        filters,
      }
    },

    configuration() {
      const children = this.data?.children || []
      const {
        articles_appearance: appearance = null,
        articles_limit: articlesLimit = null,
        articles_sorting_param: sort = null,
        articles_sorting: sortOrder = 'ASC',
        articles_paging: paging = false,
        articles_show_title: showTitle = null,
        articles_title: title = '',
        articles_tag_filter: tagFilter = '',
        articles_tag_operator: tagOperator = null,
        articles_category_filter: categoryFilter = '',
        articles_category_operator: categoryOperator = null,
        articles_post_filter: postFilter = '',
        articles_post_operator: postOperator = null,
        articles_filters_operator: operator = null,
      } = children.reduce((acc, val) => {
        acc[val['data-element']] = val.children?.[0].html
        return acc
      }, {}) || null

      function getArticlesLimit() {
        if (appearance === 'featured_with_newest') {
          // display 1 main and 5 newest
          return 6
        }

        if (appearance === 'carousel') {
          if (articlesLimit === null || Number(articlesLimit) > 12) {
            return 12
          }

          return Number(articlesLimit)
        }

        if (articlesLimit === null) {
          return null
        }
        return Number(articlesLimit)
      }

      return {
        appearance,
        articlesLimit: getArticlesLimit(),
        sort,
        sortOrder,
        paging,
        showTitle: showTitle === 'true',
        title,
        tag: tagFilter && tagFilter.split(',').map(Number),
        category: categoryFilter && categoryFilter.split(',').map(Number),
        categoryOperator,
        tagOperator,
        operator,
        postOperator,
        post: postFilter && postFilter.split(',').map(Number),
      }
    },

    isArticlesList() {
      return this.configuration?.appearance === 'article_list'
    },

    isCarousel() {
      return this.configuration?.appearance === 'carousel'
    },

    isMainAndNewest() {
      return this.configuration?.appearance === 'featured_with_newest'
    },

    isPaging() {
      return parseInt(this.configuration?.articlesLimit?.toString(), 10) > 0 && this.configuration?.paging?.toString() === 'true'
    },

    pageWithId() {
      if (this.index > 0) {
        return `page${this.index}`
      }
      return null
    },

    ref() {
      return `articles-${this.index}`
    },

    sortBy() {
      // Sorting by priority is only available per one category
      if (this.configuration.sort === 'priority') {
        if (this.configuration.category.length === 1 && this.configuration.operator === 'AND' && this.configuration.post.length === 0) {
          return 'priority'
        }
        return null
      }
      return this.configuration.sort
    },

    uid() {
      return this._uid
    },
  },

  watch: {
    '$route.query': '$fetch',
  },

  created() {
    const articleBlocks = this.$parent.$children.filter((comp) => comp.articleBlock) || []
    if (articleBlocks.length > 1) {
      this.index = articleBlocks.findIndex((comp) => comp.uid === this.uid)
    }
  },

  methods: {
    getCurrentPage() {
      if (this.pageWithId) {
        return Number(this.$route.query[this.pageWithId]) || 1
      }
      return Number(this.$route.query?.page) || 1
    },

    onPaginationPageClick() {
      this.scrollToView = true
    },
  },
}
</script>

<style lang="scss">
/*! purgecss start ignore */
/* stylelint-disable */

.pagebuilder-articles {
  position: relative;

  .carousel-headline {
    @include headline-small-spaced;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.88);
  }

  &-anchor {
    position: absolute;
    top: -192px;
    height: 192px;
    pointer-events: none;

    @media screen and (min-width: $xl-min) {
      top: -64px;
      height: 64px;
    }
  }

  .article-list[data-alignment='center'] {
    .article-list {
      &__title {
        text-align: center;
      }

      &__items,
      &__pagination {
        display: flex;
        justify-content: center;
      }
    }
  }

  .article-list[data-alignment='right'] {
    .article-list {
      &__title {
        text-align: right;
      }

      &__items,
      &__pagination {
        display: flex;
        justify-content: right;
      }
    }
  }
}
.pagebuilder-recent-articles {
  min-height: 520px;
}

html[lang="pl-PL"] {
  .pagebuilder-recent-articles {
    min-height: 580px;
  }
}
/* stylelint-enable */
/*! purgecss end ignore */
</style>
