<template>
  <div v-if="isInitialized" class="pb-user-only-content">
    <template v-if="onlyForLoggedIn">
      <v-render-content v-if="isLoggedIn && content" :content="content" />
    </template>
    <template v-else>
      <v-render-content v-if="!isLoggedIn && children.length" :content="children" />
    </template>
  </div>
</template>

<script>
import logger from '@nsf/base/logger.js'
import IsLogin from '@nsf/my-account/mixins/IsLogin.js'
import RenderContent from './RenderContent.vue'
import { getRowContentForUser } from '../../repositories/ContentRepository.js'

export default {
  name: 'VUserOnlyContent',

  components: {
    'v-render-content': RenderContent,
  },
  mixins: [IsLogin],

  props: {
    entityType: {
      type: String,
      default: null,
    },

    entityId: {
      type: Number,
      default: null,
    },

    rowId: {
      type: String,
      default: null,
    },

    onlyForLoggedIn: {
      type: Boolean,
      default: true,
    },

    children: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      content: null,
    }
  },
  watch: {
    isLoggedIn: {
      handler(isLoggedIn) {
        if (this.onlyForLoggedIn && isLoggedIn) {
          this.getContent()
        }
      },
    },
  },

  methods: {
    async getContent() {
      try {
        const response = await getRowContentForUser(this.entityType, this.entityId, this.rowId)
        this.content = JSON.parse(response?.[0]?.content)?.children?.[0]?.children || []
      } catch (e) {
        logger.withTag('UserOnlyContent').error('created failed: %s', e.message)
      }
    },
  },
}
</script>
