<template>
  <div class="error-wrapper">
    <div v-for="(error, index) in errors" :key="`${index}-${error.label}`" :data-test-id="`${error.param}-feedback`">
      {{ error.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorAtom',
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped lang="scss">
.error-wrapper {
  @include label-small-bold;
  padding: 10px 6px 6px;
  color: $red-800;
  background-color: $red-50;
}
</style>
