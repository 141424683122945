/* eslint-disable sort-keys */
/* eslint-disable no-shadow */
import { setState } from '@nsf/core/Store.js'
import pushEventLoginError from '@nsf/gtm/events/loginError.js'
import { pushEventUserData } from '@nsf/gtm/events/userData.js'
import { pushEventUserLogin } from '@nsf/gtm/events/userLogin.js'
import { pushUserEvent } from '@nsf/gtm/events/user.js'
import { remove } from '@nsf/core/Storage.js'
import pushEventUserLogout from '@nsf/gtm/events/userLogout.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { updateCredentials } from '../repositories/PasswordRepository.js'
import {
  getCurrentUser,
  getCustomerGroupId,
  getDefaultUser,
} from '../repositories/UserRepository.js'
import { login } from '../repositories/authRepository.js'
import {
  getLoginError,
  getLogoutError,
  hasAnyToken,
  isServerErrorStatus,
  isUnauthorizedStatus,
  removeTokens,
} from '../utils/auth.js'
import { transformUserEmailAndPush } from '../utils/index.js'

const {
  public: { favoritePharmaciesEnabled, recaptchaEnabled },
} = useRuntimeConfig()

const {
  rootConfig: {
    global: {
      myAccountSorpEnabled,
    },
  },
} = useAppConfig()

export const state = () => ({
  isInitialized: false,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  user: getDefaultUser(),
  userGroupId: null,
  loginError: null,
  logoutError: null,
  loginPasswordError: null,
  loyaltyProgramme: {
    clientId: null,
    details: null,
  },
})

export const getters = {
  getIsInitialized: (state) => state.isInitialized,
  getIsLoggedIn: (state) => state.isLoggedIn,
  getUser: (state) => state.user,
  getLoginError: (state) => state.loginError,
  getLoyaltyCardClientId: (state) => state.loyaltyProgramme.clientId,
  getLoyaltyCardDetails: (state) => state.loyaltyProgramme.details,
  getSavingTotal: (state) => state.user.savingTotal,
}

export const mutations = {
  setState,

  setLogin(state, { isLoggedIn, status }) {
    state.isLoggedIn = isLoggedIn
    state.loginStatus = status || null
  },

  setIsLoggingIn(state, payload) {
    state.isLoggingIn = payload
  },

  setLoginError(state, error) {
    state.loginError = error
  },

  setLoyaltyCardClientId(state, payload) {
    state.loyaltyProgramme.clientId = payload
  },

  setLoyaltyCardDetails(state, payload) {
    state.loyaltyProgramme.details = payload
  },
}

if (myAccountSorpEnabled) {
  // Push user event with higher priority
  // Only when user/event local storage is present
  pushUserEvent()
}

export const actions = {
  async init({ commit, state, dispatch }) {
    if (!state.isLoggedIn && !state.isLoggingIn && !state.isLoggingOut) {
      commit('setState', { isLoggingIn: true })

      if (hasAnyToken()) {
        const { user, ok } = await getCurrentUser()
        const {
          clientId, firstName, lastName, email, agreements,
        } = user

        if (myAccountSorpEnabled) {
          // Initial push user event to populate local storage
          pushUserEvent(user)
        }

        commit('setLogin', { isLoggedIn: ok })
        dispatch('setUser', user)
        dispatch('my-account/watchdogs/loadWatchdogs', { email }, { root: true })

        pushEventUserData(
          {
            id: clientId,
            firstname: firstName,
            surname: lastName,
            email,
          },
          {
            agreements: {
              emailEshop:
                agreements.find(({ channel }) => channel === 'EMAIL')?.value === 'N',
              postEshop:
                agreements.find(({ channel }) => channel === 'PPD')?.value === 'Y',
              smsEshop:
                agreements.find(({ channel }) => channel === 'SMS')?.value === 'Y',
            },
          },
        )
      }

      if (this.$config.public.favoriteProductsEnabled) {
        dispatch(
          'my-account/favorites/loadFavoriteProducts',
          { isLoggedIn: state.isLoggedIn },
          { root: true },
        )
      }
      if (state.isLoggedIn && favoritePharmaciesEnabled) {
        dispatch('my-account/favorites/loadFavoritePharmacies', null, { root: true })
      }

      if (recaptchaEnabled) {
        try {
          await this.$recaptcha.init()
        } catch (e) {
          console.error(e)
        }
      }

      commit('setState', { isLoggingIn: false, isInitialized: true })
    }

    return { isLoggedIn: state.isLoggedIn }
  },

  resetLoginError({ commit }) {
    commit('setState', { loginError: null })
  },

  async afterUpdate({ commit, dispatch }) {
    const { user, ok } = await getCurrentUser()
    commit('setLogin', { isLoggedIn: ok })
    dispatch('setUser', user)
  },

  async login({ commit, state, dispatch }, payload) {
    commit('setState', { isLoggingIn: true })
    const { status } = await login(payload)

    if (isServerErrorStatus(status)) {
      commit('setState', { loginError: getLoginError(status) })
      commit('setState', { isLoggingIn: false, isInitialized: true })

      return { isLoggedIn: false }
    }

    if (!isUnauthorizedStatus(status)) {
      const { ok, status, user } = await getCurrentUser()
      const loginError = getLoginError(status)
      const {
        clientId, firstName, lastName, email, agreements,
      } = user

      if (loginError) {
        commit('setState', { loginError: getLoginError(status) })
      } else {
        commit('setLogin', { isLoggedIn: ok, status })
        dispatch('setUser', user)

        dispatch('my-account/watchdogs/loadWatchdogs', { email }, { root: true })

        if (this.$config.public.favoriteProductsEnabled) {
          dispatch(
            'my-account/favorites/loadFavoriteProducts',
            { isLoggedIn: state.isLoggedIn },
            { root: true },
          )
        }

        if (favoritePharmaciesEnabled) {
          dispatch('my-account/favorites/loadFavoritePharmacies', null, { root: true })
        }
        pushEventUserData(
          {
            id: clientId,
            firstname: firstName,
            surname: lastName,
            email,
          },
          {
            agreements: {
              emailEshop:
                agreements.find(({ channel }) => channel === 'EMAIL')?.value === 'Y',
              postEshop:
                agreements.find(({ channel }) => channel === 'PPD')?.value === 'Y',
              smsEshop:
                agreements.find(({ channel }) => channel === 'SMS')?.value === 'Y',
            },
          },
        )
        pushEventUserLogin(user)
      }
    } else {
      await transformUserEmailAndPush(
        payload.email,
        pushEventLoginError(),
        this,
        getLoginError(status),
      )
      commit('setState', { loginError: getLoginError(status) })
    }

    commit('setState', { isLoggingIn: false, isInitialized: true })

    return { isLoggedIn: state.isLoggedIn }
  },

  async logout({ state, commit, dispatch }) {
    if (state.isLoggingOut) {
      return
    }

    commit('setState', { isLoggingOut: true })

    removeTokens()

    commit('setLogin', { isLoggedIn: false })
    dispatch('setUser', getDefaultUser())

    commit('setState', { logoutError: getLogoutError(), isLoggingOut: false })
    commit('my-account/watchdogs/setWatchdogs', [], { root: true })

    dispatch('my-account/favorites/removeFavoriteProducts', '', { root: true })
    pushEventUserLogout()
    pushEventUserData()
    remove('user/event')
  },

  async setLoginPassword({ commit, state }, payload) {
    const clientId = state.loyaltyCardClientId
    const { ok } = await updateCredentials(clientId, payload)

    commit('setState', { loginPasswordError: !ok })
  },

  async setUser({ commit }, user = getDefaultUser()) {
    commit('setState', { user })

    const { customerGroupId } = await getCustomerGroupId(user.accountType)
    commit('setState', { userGroupId: customerGroupId })
  },
}
