/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
import { getProductsBySkus } from '@nsf/catalog/repositories/ProductRepository.js'
import { setState } from '@nsf/core/Store.js'
import { getPurchaseHistory } from '@nsf/my-account/repositories/PurchaseHistoryRepository.js'
import { isOngoing } from '@nsf/my-account/utils/purchaseStatus.js'
import { getCoupons } from '../repositories/CouponRepository.js'
import {
  getMissingPersonalDetails,
  setMissingPersonalDetails,
} from '../repositories/UserRepository.js'
import { updatePurchaseItemFromProducts } from '../utils/index.js'

export const state = () => ({
  doi: null,
  missingPersonalDataError: null,
  missingPersonalData: null,
  userLoginAlreadyExists: false,
  isDataPrefilledFromLocalStorage: false,
  purchaseHistory: [],
  purchaseOngoingList: [],
  purchaseHistoryList: [],
  coupons: [],
  isLoadingPurchaseHistory: false,
  isLoadingCoupons: false,
  isCouponsAlreadyFetched: false,
})

export const getters = {
  getDOI: (state) => state.doi,
  getMissingPersonalDataError: (state) => state.missingPersonalDataError,
  getMissingPersonalData: (state) => state.missingPersonalData,
  getPurchaseHistory: (state) => state.purchaseHistory,
  getIsLoadingPurchaseHistory: (state) => state.isLoadingPurchaseHistory,
  getPurchaseOngoingList: (state) => state.purchaseOngoingList,
  getPurchaseHistoryList: (state) => state.purchaseHistoryList,
  getIsLoadingCoupons: (state) => state.isLoadingCoupons,
  getIsCouponsAlreadyFetched: (state) => state.isCouponsAlreadyFetched,
  getCouponsList: (state) => state.coupons,
}

export const mutations = {
  setState,

  setDOI(state, payload) {
    state.doi = payload
  },

  setMissingPersonalDataError(state, error) {
    state.missingPersonalDataError = error
  },

  setMissingPersonalData(state, payload) {
    state.missingPersonalData = payload
  },
}

export const actions = {
  async loadPurchaseHistory({ commit }) {
    commit('setState', { isLoadingPurchaseHistory: true })

    const { purchaseHistory } = await getPurchaseHistory()
    const productSkus = purchaseHistory.data
      .flatMap((purchase) => purchase.purchaseHistoryTransactionItems
        .map((item) => item.sku))
      .filter((sku) => sku) // CEOS sends "null" or "undefined" for non-product items, we need to filter them out
    const { products } = await getProductsBySkus(productSkus, { size: 9999, ignorePimStatus: true })

    const lists = purchaseHistory.data.reduce(
      (result, purchase) => {
        result[isOngoing(purchase) ? 'purchaseOngoingList' : 'purchaseHistoryList'].push(purchase)
        return result
      },
      {
        purchaseOngoingList: [],
        purchaseHistoryList: [],
      },
    )

    lists.purchaseHistoryList
      .map(({ purchaseHistoryTransactionItems }) => purchaseHistoryTransactionItems
        .map((item) => updatePurchaseItemFromProducts(products)(item)))
    lists.purchaseOngoingList
      .map(({ purchaseHistoryTransactionItems }) => purchaseHistoryTransactionItems
        .map((item) => updatePurchaseItemFromProducts(products)(item)))

    commit('setState', { purchaseHistory: purchaseHistory.data })
    commit('setState', { ...lists })
    commit('setState', { isLoadingPurchaseHistory: false })
  },

  async loadCoupons({ commit }) {
    commit('setState', { isLoadingCoupons: true })

    const { coupons } = await getCoupons()

    const skus = coupons.map((coupon) => coupon.sku).filter((coupon) => coupon)
    const { products } = await getProductsBySkus(skus, { size: 150, from: 0 })

    const couponsData = coupons.map((coupon) => ({
      ...coupon,
      product: products.find((product) => product.sku === coupon.sku) ?? null,
    })).sort((a, b) => a.order - b.order)

    commit('setState', { coupons: couponsData })
    commit('setState', { isLoadingCoupons: false })
    commit('setState', { isCouponsAlreadyFetched: true })
  },

  async setMissingPersonalData({ commit }, payload) {
    const { ok, userAlreadyExists, doi } = await setMissingPersonalDetails(payload)

    commit(
      'setState',
      {
        missingPersonalDataError: !ok,
        userLoginAlreadyExists: userAlreadyExists,
        doi,
      },
    )
  },

  async getMissingPersonalData({ commit }, clientId) {
    const { ok, data } = await getMissingPersonalDetails(clientId)
    commit(
      'setState',
      {
        missingPersonalDataError: !ok,
        missingPersonalData: data,
      },
    )
  },
}
