<template>
  <div
    class="pagebuilder-socials"
    :class="{
      'socials-fb-post': isFacebookPost,
      'socials-fb-video': isFacebookVideo
    }"
  >
    <template v-if="isFacebookPost">
      <script async defer :src="facebookSdkUrl"></script>

      <div
        class="fb-post"
        :data-href="facebookHref"
        data-show-text="true"
      ></div>
    </template>

    <template v-else-if="isFacebookVideo">
      <script async defer :src="facebookSdkUrl"></script>

      <div
        class="fb-video"
        :data-href="facebookHref"
        data-allowfullscreen="true"
      ></div>
    </template>

    <template v-else-if="isTweet">
      <div ref="tweetContainer"></div>
    </template>

    <template v-else-if="isEmbedCode && isMounted">
      <v-render-content :content="children" :use-all-attribs="true" />
    </template>
  </div>
</template>

<script>

import { loadScript } from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import RenderContent from '../components/PageBuilder/RenderContent.vue'

const {
  base: {
    twitterWidgetsUrl,
    facebookSdkUrl,
  },
} = useAppConfig()

export default {
  name: 'DrmaxSocials',

  components: {
    'v-render-content': RenderContent,
  },

  data() {
    return {
      isMounted: false,
    }
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    facebookSdkUrl() {
      return facebookSdkUrl
    },

    type() {
      return this.data?.['socials-type']
    },

    isFacebookPost() {
      return this.type === 'facebook_post'
    },

    isFacebookVideo() {
      return this.type === 'facebook_video'
    },

    isTweet() {
      return this.type === 'tweet'
    },

    isEmbedCode() {
      return this.type === 'embed_code'
    },

    facebookHref() {
      return this.data?.children?.[0]?.['data-href']
    },

    twitterUrl() {
      return this.data?.children?.[0]?.['data-url']
    },

    children() {
      return this.data?.children || []
    },
  },

  mounted() {
    this.isMounted = true

    if (this.isTweet) {
      const tweetId = this.getTweetId(this.twitterUrl)

      if (!tweetId) {
        return
      }

      loadScript(twitterWidgetsUrl).then(() => {
        window?.twttr?.widgets?.createTweet(
          tweetId,
          this.$refs.tweetContainer,
          {
            conversation: 'none', // 'none' pre samostatný tweet
          },
        )
        return true
      })
        .catch((e) => {
          console.error(e)
        })
    }
  },

  methods: {
    getTweetId(tweetUrl) {
      const tweetIdMatch = tweetUrl.match(/\/status\/(\d+)/)

      if (tweetIdMatch && tweetIdMatch.length > 1) {
        return tweetIdMatch[1]
      }

      return null
    },
  },
}
</script>

<style lang="scss">
.pagebuilder-socials {
  margin: 20px 0;
  text-align: center;

  .twitter-tweet {
    margin-right: auto;
    margin-left: auto;
  }

  @media only screen and (max-width: $md) {
    &.socials-fb-post,
    &.socials-fb-video {
      overflow-x: hidden;
    }
  }
}
</style>
