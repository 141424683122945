<template functional>
  <label :for="props.id" :class="[data.staticClass].concat(data.class)">
    <slot />
  </label>
</template>

<script>
export default {
  name: 'LabelAtom',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
label {
  @include label-large;
  color: $text-black-secondary;
}
</style>
