import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  adultsOnlyEnabled: false,
  categoryTitleEnabled: true,
  features,
  infoBoxEnabled: false,
  mainPageTextEnabled: false,
  recentArticlesCategoriesEnabled: true,
  recentArticlesCategoriesHPEnabled: true,
  recentArticlesCategoriesIds: [1],
  relatedArticlesSimpleTitleEnabled: true,
  subcategoriesDescriptionOnBottomEnabled: false,
  ...config,
})
