var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$config.public.myAccountReturnServiceEnabled)?_c('div',{staticClass:"my-account new-return-form",on:{"click":_vm.onClick}},[_c('div',{staticClass:"justify-content-center mt-4",class:{ 'py-0': _vm.isInitialized}},[(_vm.isInitialized)?_c('div',[_c('h2',{staticClass:"mb-4 title title-standalone"},[_vm._v("\n        "+_vm._s(_vm.$t('myAccount.returns.Title'))+"\n      ")]),_vm._v(" "),(_vm.isErrorMessage)?_c('v-alert-message',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('myAccount.returns.We are sorry, but email and order number dont match'))}})]):(_vm.isWarningMessageLogIn || _vm.isWarningMessageReturnExists)?_c('v-alert-message',{attrs:{"type":"warning"}},[_vm._v("\n        "+_vm._s(_vm.isWarningMessageLogIn
            ? _vm.$tc('myAccount.returns.Please be aware, that there is an existing account with email', _vm.email)
            : _vm.$t('myAccount.returns.There is already an active return/complaint for this order'))+"\n      ")]):(_vm.isOtcReservationOrderTypeMessage)?_c('v-alert-message',{staticClass:"mt-2",attrs:{"type":"warning"}},[_vm._v("\n        "+_vm._s(_vm.$t('myAccount.returns.OTC Reservation warning message'))+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.isLoggedIn)?_c('v-form',{ref:"return",attrs:{"name":"return"},on:{"submit":_vm.checkReturn}},[_c('div',{staticClass:"list mobile-full-width col-lg-5 px-0"},[_c('div',{staticClass:"list-item py-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-10 gap-3"},[_c('v-input',{ref:"orderId",attrs:{"id":"orderId","label":_vm.$t('myAccount.returns.Order number'),"validation-errors":{
                    isNumber: _vm.$t('myAccount.returns.Order id must be a number'),
                  },"validations":_vm.$v.orderId,"name":"orderId","required":""},model:{value:(_vm.$v.orderId.$model),callback:function ($$v) {_vm.$set(_vm.$v.orderId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.orderId.$model"}}),_vm._v(" "),_c('v-input',{ref:"email",attrs:{"id":"email","label":_vm.$t('myAccount.returns.Email'),"validations":_vm.$v.email,"name":"email","required":""},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"list-item bg-chalk-white"},[_c('v-button',{attrs:{"loading":_vm.isLoading,"disabled":_vm.$v.$invalid || _vm.$v.$error}},[_vm._v("\n              "+_vm._s(_vm.isWarningMessageLogIn
                ? _vm.$t('myAccount.returns.Check with this email')
                : _vm.$t('myAccount.returns.Check'))+"\n            ")]),_vm._v(" "),_c('v-modal',{ref:"returnServiceModal",attrs:{"title":_vm.$t('myAccount.returns.Email sent successfully')}},[_c('div',{staticClass:"my-2 text"},[_vm._v("\n                "+_vm._s(_vm.$t('myAccount.returns.Please check your inbox'))+"\n              ")])])],1)])]):_c('div',{staticClass:"list mobile-full-width col-lg-5 px-0"},[_c('div',{staticClass:"list-item py-5"},[_c('v-alert-message',{staticClass:"mt-2"},[_vm._v("\n            "+_vm._s(_vm.$t('myAccount.returns.As logged in user, please create return/complaint in your account'))+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"list-item bg-chalk-white"},[_c('v-button',{attrs:{"link":_vm.localePath('my-account')}},[_vm._v("\n            "+_vm._s(_vm.$t('myAccount.returns.Order history list'))+"\n          ")])],1)])],1):_vm._e(),_vm._v(" "),(!_vm.isInitialized)?_c('div',{staticClass:"d-flex flex-column align-items-center h-100"},[_c('div',{staticClass:"spinner-border text-success display-4 mb-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('base.Loading')))])])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }