<template>
  <section class="tabs" data-test-id="tabs-wrapper">
    <h2 v-if="!hasTitleRightSlotData" class="tabs__title">
      {{ title }}
    </h2>

    <div v-if="hasTitleRightSlotData" class="tabs__title__container">
      <h2 class="tabs__title">
        {{ title }}
      </h2>

      <div class="tabs__title__right">
        <slot name="titleRight" />
      </div>
    </div>

    <div class="tabs__nav d-none d-md-flex">
      <button
        v-for="(tab, i) in tabs"
        :key="i"
        :aria-expanded="tab.isActive.toString()"
        class="h4"
        data-test-id="tabs-nav-item"
        @click="activate(tab)"
      >
        {{ tab.title }}
      </button>
    </div>
    <slot />
  </section>
</template>

<script>
import Tab from './Tab.vue'

export default {
  name: 'Tabs',

  provide() {
    return {
      tabs: this,
    }
  },

  props: {
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tabs: [],
    }
  },

  computed: {
    hasTitleRightSlotData() {
      return this.$scopedSlots.titleRight
    },
  },

  methods: {
    register(tab) {
      this.tabs.push(tab)

      if (this.tabs.length === 1) {
        tab.isActive = true
      }
    },

    activate(tab) {
      for (const _tab of this.tabs) {
        _tab.isActive = _tab === tab
      }
    },

    toggle(tab) {
      tab.isActive = !tab.isActive
    },
  },
}

export { Tab }
</script>

<style lang="scss" scoped>
.tabs__title__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tabs__title {
    flex-grow: 1;
  }

  .tabs__title__right {
    display: block;
    flex-grow: 0;

    @media only screen and (max-width: 767px) {
      margin-right: 20px;
    }
  }
}

</style>
