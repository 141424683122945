import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["format", "multiple", "w", "h"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { image } from '@nsf/utils/UrlUtils.js';
import { ensureArray } from '@nsf/utils/ArrayUtils.js';
import { isArray } from '@nsf/core/Utils.js';
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js';
import { PLACEHOLDER_IMAGE_PATH } from '../enums/index.js';
var _useRuntimeConfig = useRuntimeConfig(),
  imageFormats = _useRuntimeConfig.public.imageFormats;
export default {
  name: 'LazyImage',
  props: {
    /**
     * src is the simple string URL used to retrieve an image
     * it is used only when opts prop is not set
     * can serve as a fallback or when the image is fixed and not resizable
     */
    src: {
      type: String,
      default: ''
    },
    /**
     * alt attribute of the image
     */
    alt: {
      type: String,
      default: ''
    },
    /**
     * title attribute of the image
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * opts is an object accepted by the UrlUtils/image function
     * it is used when we want to generate multiple image sources based on format or size
     *
     * opts as array is used for different images paths for mobile & desktop, we iterate over the array to generate several <source> tags
     *
     */
    opts: {
      type: [Object, Array],
      default: null
    },
    /**
     * formats configures the formats used for generation of image sources when the opts prop is set
     */
    formats: {
      type: String,
      default: function _default() {
        return [imageFormats, 'jpg'].join(',');
      }
    },
    /**
     * if we want to bypass the lazy-load check and load the image immediately
     */
    isNotLazy: {
      type: Boolean,
      default: false
    },
    /**
     * if we want to preload the picture with high priority before any other resources or scripts
     *  - good for optimization (LCP)
     *  - use only in rare cases for super important images that will be shown in viewport on the first page load
     */
    preload: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: [Array, Number],
      default: function _default() {
        return [1];
      }
    },
    /**
     * width and height of the img for cumulative layout shift
     */
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    imageStyle: {
      type: String,
      default: ''
    },
    pixelDensity: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      error: false,
      placeholder: "/".concat(PLACEHOLDER_IMAGE_PATH)
    };
  },
  head: function head() {
    // Preloading is based on adding <link>s to <head>. In ideal case, we want to preload only a single
    // picture with selected format and size.
    //
    // Therefore:
    // 1. we filter the file format (we preload `webp`)
    // 2. and we provide media parameter - in case multiple <link>s are added to <head>, the media ensures
    //    that only the needed one is preloaded (i.e. mobile or desktop). In case one of the sources does not have
    //    media specified, it will always be preloaded no matter the target device.
    if (this.preload) {
      var modernSources = this.sources.filter(function (source) {
        return source.mimeType === 'image/avif' || source.mimeType === 'image/webp';
      });
      if (!modernSources) {
        return;
      }
      var links = modernSources.map(function (src) {
        return _objectSpread({
          rel: 'preload',
          as: 'image',
          href: src.srcset
        }, src.media && {
          media: src.media
        });
      });
      // eslint-disable-next-line consistent-return
      return {
        link: links
      };
    }
  },
  computed: {
    img: function img() {
      if (this.error) {
        return this.placeholder;
      }

      // If we received props "opts" as array, we have to select one image as fallback to <img> tag
      if (isArray(this.opts)) {
        if (!this.opts.length) {
          return this.placeholder;
        }
        return this.getImg(this.opts[0]);
      }
      if (!this.isImageUrl) {
        return this.src || this.placeholder;
      }

      // image from props "opts" prioritized over "src"
      return this.getImg(this.opts);
    },
    sources: function sources() {
      // Array used for different images for mobile & desktop
      if (isArray(this.opts)) {
        var sources = [];
        var opts = this.opts;
        opts.sort(function (a, b) {
          if (a.media && !b.media) {
            return -1;
          }
          if (!a.media && b.media) {
            return 1;
          }
          return 0;
        });
        for (var i = 0; i < opts.length; i++) {
          sources = sources.concat(this.getSource(opts[i]));
        }
        return sources;
      }
      if (!this.isImageUrl) {
        return [];
      }
      return this.getSource(this.opts);
    },
    // default browser lazy loading, eager will download image immediately
    loading: function loading() {
      return this.isNotLazy ? 'eager' : 'lazy';
    },
    // default browser fetch priority, high for not lazy images with eager loading
    fetchPriority: function fetchPriority() {
      return this.isNotLazy ? 'high' : 'auto';
    },
    isImageUrl: function isImageUrl() {
      var _this$opts;
      if (this.error || !this.opts || ((_this$opts = this.opts) === null || _this$opts === void 0 ? void 0 : _this$opts.path) === this.placeholder) {
        return false;
      }
      return !!(this.opts.path || this.opts.m2);
    },
    imageSize: function imageSize() {
      var size = {
        w: this.getWidth(),
        h: this.getHeight()
      };
      if (!size.w || !size.h) {
        return {
          w: null,
          h: null
        };
      }
      return size;
    }
  },
  watch: {
    $props: {
      handler: function handler() {
        // If someone changes props, we might get new valid image so we have to reset error handling
        this.error = false;
      },
      deep: true
    }
  },
  methods: {
    onError: function onError() {
      this.error = true;
    },
    onLoad: function onLoad() {
      this.$emit('load');
    },
    getImg: function getImg() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // img tag with srcset should not have w & h attributes
      if (opts.generatedImage) {
        return opts.generatedImage;
      }
      return image(Object.assign.apply(Object, [{}].concat(_toConsumableArray(Object.keys(opts).map(function (key) {
        return !(key === 'w' || key === 'h' || key === 'media' || key === 'format') && _defineProperty({}, key, opts[key]);
      })))));
    },
    getSrcOpts: function getSrcOpts() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return Object.keys(opts).map(function (key) {
        return !(key === 'media') && _defineProperty({}, key, opts[key]);
      });
    },
    getImgSrcset: function getImgSrcset() {
      var _this = this;
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var srcOpts = this.getSrcOpts(opts);
      var imgObj = Object.assign.apply(Object, [{}].concat(_toConsumableArray(srcOpts)));
      var isJpgGenerated = imgObj.format === 'jpg' && imgObj.srcJpg;
      var isModernGenerated = (imgObj.format === 'avif' || imgObj.format === 'webp') && imgObj.srcModern;
      var usePixelDensity = this.pixelDensity.length > 0 && opts.w && opts.h;
      if (!usePixelDensity && !isJpgGenerated && !isModernGenerated) {
        return image(imgObj);
      }
      if (!usePixelDensity) {
        return isModernGenerated ? imgObj.srcModern : imgObj.srcJpg;
      }
      var srcset = ["".concat(image(imgObj), " 1x")];
      this.pixelDensity.forEach(function (density) {
        var srcDensityOpts = _this.getSrcOpts(opts);
        srcDensityOpts.forEach(function (value, key) {
          if (value.w) {
            srcDensityOpts[key].w *= density;
          }
          if (value.h) {
            srcDensityOpts[key].h *= density;
          }
        });
        srcset.push("".concat(image(Object.assign.apply(Object, [{}].concat(_toConsumableArray(srcDensityOpts)))), " ").concat(density, "x"));
      });
      return srcset.join(',');
    },
    getSource: function getSource() {
      var _this2 = this;
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var formats = this.formats.split(',');
      return ensureArray(formats).map(function (format) {
        // eslint-disable-next-line no-param-reassign
        opts.format = format;
        return {
          mimeType: format === 'jpg' ? 'image/jpeg' : "image/".concat(format),
          media: opts.media,
          w: opts.w,
          h: opts.h,
          srcset: _this2.getImgSrcset(opts)
        };
      });
    },
    resizeImage: function resizeImage(_ref3) {
      var format = _ref3.format,
        _ref3$multiple = _ref3.multiple,
        multiple = _ref3$multiple === void 0 ? 1 : _ref3$multiple,
        w = _ref3.w,
        h = _ref3.h,
        rest = _objectWithoutProperties(_ref3, _excluded);
      return image(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, rest), w && {
        w: w * multiple
      }), h && {
        h: h * multiple
      }), format && {
        format: format
      })) + (w && this.multiple.length > 1 ? " ".concat(w * multiple, "w") : '');
    },
    getWidth: function getWidth() {
      var _this$opts2;
      if (this.width) {
        return this.width;
      }
      if (isArray(this.opts)) {
        var _this$opts$;
        if (this.opts.length && (_this$opts$ = this.opts[0]) !== null && _this$opts$ !== void 0 && _this$opts$.w) {
          return this.opts[0].w;
        }
      } else if ((_this$opts2 = this.opts) !== null && _this$opts2 !== void 0 && _this$opts2.w) {
        return this.opts.w;
      }
      return null;
    },
    getHeight: function getHeight() {
      var _this$opts3;
      if (this.height) {
        return this.height;
      }
      if (isArray(this.opts)) {
        var _this$opts$2;
        if (this.opts.length && (_this$opts$2 = this.opts[0]) !== null && _this$opts$2 !== void 0 && _this$opts$2.h) {
          return this.opts[0].h;
        }
      } else if ((_this$opts3 = this.opts) !== null && _this$opts3 !== void 0 && _this$opts3.h) {
        return this.opts.h;
      }
      return null;
    }
  }
};