import { isArray } from '@nsf/core/Utils.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    magentoUrl,
  },
} = useRuntimeConfig()

export const mapDataToProductAttachment = (data, translations) => {
  if (isArray(data)) {
    return data.map((value) => mapDataToProductAttachment(value, translations))
  }

  // Force string conversion for consistency
  const type = `${data.file_type}`
  const url = `${data.file_url}`
  const path = `${data.file_path}`

  switch (type) {
    case 'url':
      // This is due to backwards compatibility.
      // File URL should be absolute URL starting with http but some attachments have mixed file URL/path and URL
      // is only name of the file. To maintain backwards compatibility we check if the URL is absolute and if not,
      // prepend rest of the URL to M2 Amasty static directory.
      if (url.startsWith('http')) {
        return {
          url,
        }
      }

      return {
        url: `${magentoUrl}/media/amasty/amfile/attach/${url}`,
      }
    case 'file':
      return {
        url: `${magentoUrl}/media/amasty/amfile/attach/${path}`,
      }
    default:
      return {
        url: '',
      }
  }
}
