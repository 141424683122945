import { mapDataToModel } from '@nsf/core/Mapper.js'

export const getDefaultTotalRating = () => ({
  count: 0,
  avg: 0,
})

export const mapDataToReview = (data, translations = {}) => mapDataToModel(data, {
  ...translations,
})

export const mapDataToTotalRating = (data, translations = {}) => mapDataToModel(data, {
  key: ['rating', Number],
  doc_count: ['count', Number],
  ...translations,
})
export const mapDataToProductsTotalRating = (data, translations = {}) => mapDataToModel(data, {
  key: ['productId', Number],
  doc_count: ['count', Number],
  avg: (avgRating) => avgRating.value,
  stars: (stars) => mapDataToTotalRating(stars.buckets),
  ...translations,
})
