import logger from '@nsf/cms/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const { appUrlInternal, public: { appUrl } } = useRuntimeConfig()

const getUrl = () => appUrlInternal || appUrl

export const getContentBlock = async (blockId, ignoreCache = false) => {
  try {
    const response = await fetch(`${getUrl()}/cms-block/${blockId}${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getContentBlock() failed: %s', e.message)
    return null
  }
}

export const getAnnouncement = async (ignoreCache = false) => {
  try {
    const response = await fetch(`${getUrl()}/cms-announcement-info${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getAnnouncement() failed: %s', e.message)
    return null
  }
}

export const getPageById = async (pageId, ignoreCache) => {
  try {
    const response = await fetch(`${getUrl()}/cms-page/${pageId}${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getPageById() failed: %s', e.message)
    return null
  }
}

export const getPageBuilderData = async (data) => {
  try {
    const response = await fetch(
      `${getUrl()}/pagebuilder-data`,
      {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: { 'Content-Type': 'application/json' },
      },
    )

    console.log({ response })

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getPageBuilderData() failed: %s', e.message)
    return null
  }
}
