import { renderChildren } from '@nsf/base/components/PageBuilder/index.js';
export default {
  name: 'VUl',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var data = {
      class: ctx.props.data.class,
      style: ctx.props.data.style
    };
    return h('ul', data, renderChildren(h, ctx, children));
  }
};