export function debounce(fn, delay) {
  const thatUidToTimeoutID = {}
  return function () {
    const args = arguments
    const that = this
    clearTimeout(thatUidToTimeoutID[that._uid])
    thatUidToTimeoutID[that._uid] = setTimeout(() => fn.apply(that, args), delay)
  }
}

export function normalizeString(str) {
  // TODO: fix this, select doesnt have to be string !!
  if (typeof str !== 'string') {
    return str
  }
  if (!str) {
    return ''
  }
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function normalizeRomanianSpecialChars(str) {
  const charMap = {
    ă: 'ă',
    â: 'â',
    î: 'î',
    ș: 'ș',
    ş: 'ș',
    ț: 'ț',
    ţ: 'ț',
    Ă: 'Ă',
    Â: 'Â',
    Î: 'Î',
    Ș: 'Ș',
    Ş: 'Ș',
    Ț: 'Ț',
    Ţ: 'Ț',
  }

  return str.replace(/[ăâîșşțţĂÂÎȘŞȚŢ]/g, (match) => charMap[match])
}
