<template>
  <component
    :is="el"
    v-if="alwaysRender || isShown"
    v-show="isShown"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'MenuItem',

  inject: ['registerItem', 'getActiveTab'],

  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    startsShown: {
      type: Boolean,
      default: false,
    },
    alwaysRender: {
      type: Boolean,
      default: false,
    },
    el: {
      type: String,
      default: 'div',
    },
    hideTab: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isShown() {
      return this.getActiveTab()
        ? this.getActiveTab() === this.name
        : this.startsShown
    },
  },

  created() {
    this.registerItem(this)
  },
}
</script>
