<template>
  <div
    v-if="offers.length"
    class="pagebuilder-limited-time-offer"
  >
    <v-default v-if="showSingle && isDefault" :offer="offers[0]" />
    <v-rounded v-else-if="showSingle && !isDefault" :offer="offers[0]" />

    <v-slider v-else :offers="offers" :is-default="isDefault" />
  </div>
</template>

<script>
import { getProductsBySkus } from '@nsf/catalog/repositories/ProductRepository.js'
import { isInStock } from '@nsf/catalog/utils/StockUtils.js'
import logger from '@nsf/base/logger.js'

export default {
  name: 'VDrmaxTimeLimitedOfferForm',

  components: {
    'v-default': () => import('../components/PageBuilder/DrmaxLimitedTimeOffer/Default.vue'),
    'v-rounded': () => import('../components/PageBuilder/DrmaxLimitedTimeOffer/Rounded.vue'),
    'v-slider': () => import('../components/PageBuilder/DrmaxLimitedTimeOffer/Slider.vue'),
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      products: [],
    }
  },

  fetch() {
    if (this.skus.length) {
      getProductsBySkus(this.skus)
        .then(({ products }) => {
          this.products = products
        })
    }
  },

  computed: {
    activeOffers() {
      const now = Date.parse(new Date())
      try {
        return JSON.parse(this.data['data-offers'])?.filter((offer) => Date.parse(offer.from) < now && now < Date.parse(offer.to))
      } catch (e) {
        logger.withTag('PageBuilder').error('Limited Time Offer - JSON parse error: %s', e.message)
        return []
      }
    },

    skus() {
      return this.activeOffers.map((offer) => offer.product_sku) ?? []
    },

    offers() {
      const offers = [...this.activeOffers]
      for (let i = 0; i < this.activeOffers.length; i++) {
        for (let j = 0; j < this.products.length; j++) {
          if (this.activeOffers[i].product_sku === this.products[j].sku) {
            offers[i].product = this.products[j]
            break
          }
        }
      }

      return offers.filter((offer) => this.isVisible(offer)) ?? []
    },

    showSingle() {
      return this.offers.length === 1
    },

    isDefault() {
      return this.data['data-offers-appearance'] === 'default' ?? false
    },

  },
  methods: {
    isVisible(offer) {
      if (!offer.product) {
        return false
      }
      return isInStock(offer.product) || !offer.hide_unavailable_product
    },
  },
}
</script>
