<template>
  <v-new-return-form />
</template>

<script>

import NewReturnForm from '../components/returns/NewReturnForm.vue'

export default {
  name: 'NewReturnFormWidget',

  components: {
    'v-new-return-form': NewReturnForm,
  },
}
</script>
