import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { isArray } from '@nsf/core/Utils.js';
import { extractMarginAndPaddingFromStyle, createStyleElementContent, createElementId } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import Tab from '../components/Tabs/Tab.vue';
import Tabs from '../components/Tabs/index.vue';
import { findFirstElement, renderChildren } from '../components/PageBuilder/index.js';
export default {
  name: 'VTabs',
  functional: true,
  components: {
    'v-tab': Tab,
    'v-tabs': Tabs
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var extractedContent = _extractContent(children, []);
    var childrenToRender = [];
    for (var i = 0; i < extractedContent.length; i++) {
      var productsComponent = findFirstElement(extractedContent[i].children || [], function (o) {
        return o['data-content-type'] === 'products';
      }, 30);
      childrenToRender[i] = h(Tab, {
        attrs: {
          title: extractedContent[i]['data-tab-name'],
          hasMinHeight: !!productsComponent
        }
      }, renderChildren(h, ctx, extractedContent[i].children));
    }

    // we will parse styles and create style element
    var elementId = createElementId(ctx.props.data['data-pb-style']);
    var mobileStyle = extractMarginAndPaddingFromStyle(ctx.props.data['mobile-style']);
    var desktopStyle = extractMarginAndPaddingFromStyle(ctx.props.data.style);
    var styleElementContent = createStyleElementContent(elementId, desktopStyle, mobileStyle);
    var styleElement = h('style', {}, styleElementContent);
    childrenToRender.push(styleElement);
    var tabsData = {
      attrs: {
        id: elementId
      },
      class: 'pagebuilder-tabs'
    };
    return childrenToRender.length ? h(Tabs, tabsData, childrenToRender) : '';
  }
};
var _extractContent = function extractContent(o) {
  var content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var extractedContent = content;
  if (!isArray(o)) {
    return {};
  }
  o.forEach(function (child) {
    if (child['data-content-type'] === 'tab-item') {
      extractedContent.push(child);
    } else {
      return _extractContent(child.children, extractedContent);
    }
  });
  return extractedContent;
};