import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'ccde951c3d598f29efd3eed98aaaa017',
  analyticsApiKey: '4f6877dfe592d62e9bd3549bbc47dda9',
  ...config,
})
