<template>
  <button
    class="option"
    :class="[
      { 'active': isActive },
      { 'disabled': isDisabled }
    ]"
    @click="select"
  >
    <span class="text-left" :class="stylesLeft">{{ textLeft }}</span>
    <span class="text-right" :class="stylesRight">{{ textRight }}</span>
  </button>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    textLeft() {
      return this.option.label
    },
    stylesLeft() {
      return []
    },
    textRight() {
      return this.option.labelSecondary
    },
    stylesRight() {
      return []
    },
    isActive() {
      return this.active
    },
    isDisabled() {
      return this.disabled
    },
  },

  methods: {
    select() {
      this.$emit('select', this.option)
    },
  },
}
</script>

<style scoped lang="scss">
.option {
  display: flex;
  text-align: start;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 16px 8px;
  border-radius: 6px;
  border: none;
  background-color: white;
  @include label-large;
  color: $text-black-primary;

  &:hover, &:focus-visible {
    color: $blue-600;
    background-color: $blue-25;
  }

  &:focus-visible {
    outline: 2px solid $yellow-200;
    outline-offset: 2px;
  }

  &.active {
    border: 1px solid $blue-500;
    color: $blue-600;
  }

  &.disabled {
    color: $text-black-tertiary;
    background-color: $gray-25;
  }
}
</style>
