import { defuFn } from 'defu'
import { default as rootConfig } from 'drmax/config/ro-drmax/app.config.mjs'
import { default as i18n } from '@nsf/i18n/config/ro-drmax/app.config.mjs'
import { default as csp } from '@nsf/csp/config/ro-drmax/app.config.mjs'
import { default as base } from '@nsf/base/config/ro-drmax/app.config.mjs'
import { default as algolia } from '@nsf/algolia/config/ro-drmax/app.config.mjs'
import { default as search } from '@nsf/search/config/ro-drmax/app.config.mjs'
import { default as articles } from '@nsf/articles/config/ro-drmax/app.config.mjs'
import { default as pickupSelect } from '@nsf/pickup-select/config/ro-drmax/app.config.mjs'
import { default as apothekaWeb } from '@nsf/apotheka-web/config/ro-drmax/app.config.mjs'
import { default as deliveryFe } from '@nsf/delivery-fe/config/ro-drmax/app.config.mjs'
import { default as suklWidget } from '@nsf/sukl-widget/config/ro-drmax/app.config.mjs'
import { default as checkout } from '@nsf/checkout/config/ro-drmax/app.config.mjs'
import { default as homepage } from '@nsf/homepage/config/app.config.mjs'
import { default as catalog } from '@nsf/catalog/config/ro-drmax/app.config.mjs'
import { default as gtm } from '@nsf/gtm/config/ro-drmax/app.config.mjs'
import { default as speedCurve } from '@nsf/speed-curve/config/ro-drmax/app.config.mjs'
import { default as subscription } from '@nsf/subscription/config/ro-drmax/app.config.mjs'
import { default as myAccount } from '@nsf/my-account/config/ro-drmax/app.config.mjs'
import { default as cms } from '@nsf/cms/config/ro-drmax/app.config.mjs'
import { default as tags } from '@nsf/tags/config/app.config.mjs'
import { default as clubs } from '@nsf/clubs/config/ro-drmax/app.config.mjs'
import { default as productRelationshipLoaderPersoo } from '@nsf/product-relationship-loader-persoo/config/ro-drmax/app.config.mjs'
import { default as productRelationshipLoaderPlacement } from '@nsf/product-relationship-loader-placement/config/ro-drmax/app.config.mjs'
import { default as addressValidation } from '@nsf/address-validation/config/ro-drmax/app.config.mjs'
import { default as validations } from '@nsf/validations/config/ro-drmax/app.config.mjs'
import { default as convert } from '@nsf/convert/config/ro-drmax/app.config.mjs'
import { default as myAccountSorp } from '@nsf/my-account-sorp/config/ro-drmax/app.config.mjs'

const inlineConfig = {}
export default /* #__PURE__ */ defuFn({rootConfig, i18n, csp, base, algolia, search, articles, pickupSelect, apothekaWeb, deliveryFe, suklWidget, checkout, homepage, catalog, gtm, speedCurve, subscription, myAccount, cms, tags, clubs, productRelationshipLoaderPersoo, productRelationshipLoaderPlacement, addressValidation, validations, convert, myAccountSorp}, inlineConfig)
