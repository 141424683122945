import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { renderChildren } from '../components/PageBuilder/index.js';
export default {
  name: 'VColumnGroup',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$data;
    var children = ctx.props.data.children || [];
    var style = ctx.props.data.style || '';
    var alignItems = ((_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : _ctx$props$data.alignItems) || null;
    if (!alignItems && (children === null || children === void 0 ? void 0 : children.length) > 0) {
      children.forEach(function (child) {
        if ((child === null || child === void 0 ? void 0 : child['data-appearance']) === 'align-center') {
          alignItems = 'center';
        } else if ((child === null || child === void 0 ? void 0 : child['data-appearance']) === 'align-bottom') {
          alignItems = 'flex-end';
        } else if (child !== null && child !== void 0 && child.style) {
          if (child.style.includes('justify-content: center;')) {
            alignItems = 'center';
          } else if (child.style.includes('justify-content: flex-end;')) {
            alignItems = 'flex-end';
          }
        }
      });
    }
    if (alignItems) {
      style += "align-items: ".concat(alignItems, ";");
    }
    var data = {
      class: 'row',
      style: style || null
    };
    return h('div', data, renderChildren(h, ctx, children));
  }
};