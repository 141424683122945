<template>
  <v-pregnancy-calculator />
</template>

<script>

import PregnancyCalculator from '../components/PregnancyCalculator.vue'

export default {
  name: 'PregnancyCalculatorWidget',

  components: {
    'v-pregnancy-calculator': PregnancyCalculator,
  },
}
</script>
