import { renderChildren } from '../components/PageBuilder/index.js';
import Extend from '../components/Transitions/Extend.vue';
import { applyInlineStyles, createElementId, createStyleElementContent } from '../utils/PagebuilderStyleUtils.js';
export default {
  name: 'ButtonAccordion',
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      expanded: false,
      buttonClass: 'btn btn-link mb-2 mr-1 shadow-none px-0 text-left'
    };
  },
  computed: {
    questionTag: function questionTag() {
      var _this$data$children, _this$data$children$, _this$data$children$$;
      return ((_this$data$children = this.data.children) === null || _this$data$children === void 0 ? void 0 : (_this$data$children$ = _this$data$children[0].children) === null || _this$data$children$ === void 0 ? void 0 : (_this$data$children$$ = _this$data$children$[0]) === null || _this$data$children$$ === void 0 ? void 0 : _this$data$children$$.tag) || 'div';
    }
  },
  methods: {
    toggle: function toggle() {
      this.expanded = !this.expanded;
    },
    keydown: function keydown(event) {
      if (event.keyCode === 32 || event.keyCode === 13) {
        this.toggle();
        event.preventDefault();
      }
    }
  },
  render: function render(h) {
    var _children$0$children$, _children$0$children$2, _children$0$children$3, _this$data, _this$data$children2, _this$data$children2$, _this$data2, _this$data2$children, _this$data2$children$, _this$data3, _this$data3$children, _this$data3$children$;
    var children = this.data.children || [];
    // extractHtml cant be used
    var buttonTitle = (_children$0$children$ = children[0].children[0]) === null || _children$0$children$ === void 0 ? void 0 : (_children$0$children$2 = _children$0$children$.children) === null || _children$0$children$2 === void 0 ? void 0 : (_children$0$children$3 = _children$0$children$2[0]) === null || _children$0$children$3 === void 0 ? void 0 : _children$0$children$3.html;
    var accordionContent = children[1].children;
    if (!buttonTitle) {
      return;
    }
    var button = h(this.questionTag, {
      class: 'accordion-title'
    }, buttonTitle);
    var accordion = accordionContent ? h(Extend, {
      props: {
        expanded: this.expanded,
        el: 'div'
      },
      class: 'd-xl-block'
    }, renderChildren(h, this, accordionContent)) : null;

    // we will parse styles and create style element
    var elementId = createElementId((_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$children2 = _this$data.children) === null || _this$data$children2 === void 0 ? void 0 : (_this$data$children2$ = _this$data$children2[1]) === null || _this$data$children2$ === void 0 ? void 0 : _this$data$children2$['data-pb-style']);
    var mobileStyle = applyInlineStyles(this.data, (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$children = _this$data2.children) === null || _this$data2$children === void 0 ? void 0 : (_this$data2$children$ = _this$data2$children[1]) === null || _this$data2$children$ === void 0 ? void 0 : _this$data2$children$['mobile-style'], {
      width: true,
      padding: true
    });
    var desktopStyle = applyInlineStyles(this.data, (_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : (_this$data3$children = _this$data3.children) === null || _this$data3$children === void 0 ? void 0 : (_this$data3$children$ = _this$data3$children[1]) === null || _this$data3$children$ === void 0 ? void 0 : _this$data3$children$.style, {
      width: true,
      padding: true
    });
    var styleElementContent = createStyleElementContent(elementId, desktopStyle, mobileStyle);
    var styleElement = h('style', {}, styleElementContent);
    var data = {
      on: {
        click: this.toggle,
        keydown: this.keydown
      },
      class: "pagebuilder-accordion".concat(this.expanded ? ' accordion-expanded' : ''),
      attrs: {
        tabindex: '0',
        id: elementId
      },
      props: {
        'aria-expanded': this.expanded
      }
    };
    var innerAccordion = h('div', {
      class: 'accordion-inner'
    }, [button, accordion]);
    var chevronTop = h('svg', {
      class: 'chevron-top'
    }, [h('use', {
      attrs: {
        'xlink:href': '/images/core/icons.svg#chevron-left'
      }
    })]);
    return h('div', data, [chevronTop, innerAccordion, styleElement]);
  }
};