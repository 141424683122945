<template>
  <client-only>
    <div
      v-if="channelId"
      class="streams-widget"
    >
      <script type="text/javascript" src="https://cdn.streams.live/embed.js" />
      <div id="streams-live-embed-script" :data-channel-id="channelId" />
    </div>
  </client-only>
</template>

<script>
export default {
  name: 'StreamsWidget',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    channelId() {
      if (this.data?.children?.[0]?.['channel-id']) {
        return this.data.children[0]['channel-id']
      }

      const parts = this.data?.class?.split('-')
      return (parts && parts.length > 1) ? parts[1] : null
    },
  },
}
</script>

<style lang="scss" scoped>
.streams-widget {}
</style>
