var render, staticRenderFns
import script from "./Divider.vue?vue&type=script&lang=js"
export * from "./Divider.vue?vue&type=script&lang=js"
import style0 from "./Divider.vue?vue&type=style&index=0&id=6fbbec6c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@5.2.7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbbec6c",
  null
  
)

export default component.exports