import logger from '@nsf/base/logger.js'
import { userOnlyContentQuery, suggesterListQuery } from '@ioc/graphql/queries.js'

export const getRowContentForUser = async (entityType, entityId, rowId) => {
  try {
    const response = await userOnlyContentQuery().bind({ content_ids: [rowId], entity_type: entityType, entity_id: entityId })
      .get()

    return response
  } catch (e) {
    logger.withTag('ContentRepository').error('getRowContentForUser() failed: %s', e.message)

    return null
  }
}

export const getSuggesterList = async () => {
  try {
    const response = await suggesterListQuery().get()

    return { response }
  } catch (e) {
    logger.withTag('graphql').error('getSuggesterList() failed: %s', e.message)

    return null
  }
}
