<template>
  <div class="at-doctor-link-widget">
    <client-only>
      <v-button
        v-if="urlAtDoctor"
        :link="urlAtDoctor"
        target="_blank"
      >
        {{ $t('clubs.Ask doctor') }}
      </v-button>

      <v-button
        v-else
        disabled="disabled"
      >
        {{ $t('clubs.Link is loading') }}
      </v-button>
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@nsf/ui-library/components/atoms/Button.vue'

export default {
  name: 'AtDoctorLinkWidget',

  components: {
    'v-button': Button,
  },

  data() {
    return {
      urlAtDoctor: '',
    }
  },

  watch: {
    user(newValue) {
      this.setLink(newValue)
    },
  },

  mounted() {
    this.setLink(this.user)
  },

  computed: {
    ...mapState(
      'my-account/user',
      ['user'],
    ),
  },

  methods: {
    async setLink(user) {
      const clientId = user?.clientId || 0

      if (!clientId) {
        this.urlAtDoctor = this.$config.public.clubsAtDoctorUrl
        return
      }

      const response = await fetch(`/at-doctor-link/${clientId}`)
      const responseJson = await response.json()

      this.urlAtDoctor = responseJson.url
    },
  },
}
</script>

<style lang="scss" scoped>
.at-doctor-link-widget {
  min-height: 45px;
  text-align: center;
}
</style>
