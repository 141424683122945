<template functional>
  <li
    v-if="props.category.id"
    v-bind="data.attrs"
    class="categories__item"
    v-on="listeners"
  >
    <h3 class="categories__title h3">
      <v-link :to="props.category.urlKey">
        {{ props.category.title }}
      </v-link>
    </h3>
    <figure class="image-rounded image-rounded--sm mr-2">
      <v-lazy-image
        :alt="props.category.title"
        :opts="$options.imageOpts(props.category.icon)"
      />
    </figure>
  </li>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },

  imageOpts(icon) {
    return { path: icon, w: 128, h: 128 }
  },
}
</script>
