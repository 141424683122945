import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'

export default defineAppConfig({
  defaultLocale: 'ro-drmax',
  locales: [
    {
      code: 'ro-drmax',
      domain: '',
      file: 'index.js',
      iso: 'ro-RO',
    },
  ],
  ...config,
})
