import "core-js/modules/es.array.concat.js";
import { image } from '@nsf/utils/UrlUtils.js';
import { extractStyle, extract, extractSrc, extractAlt, extractHref } from '../components/PageBuilder/index.js';
export default {
  name: 'VBrandblock',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var definedClass = children[0].class || '';
    var style = extractStyle(ctx.props.data, 1);
    var borderStyle = extract(style, /border-style: .+?;/);
    var borderColor = extract(style, /border-color: .+?;/);
    var borderWidth = extract(style, /border-width: .+?;/);
    var columns = [];
    for (var i = 0; i < (children === null || children === void 0 ? void 0 : (_children$ = children[0]) === null || _children$ === void 0 ? void 0 : (_children$$children = _children$.children) === null || _children$$children === void 0 ? void 0 : (_children$$children$ = _children$$children[0]) === null || _children$$children$ === void 0 ? void 0 : (_children$$children$$ = _children$$children$.children) === null || _children$$children$$ === void 0 ? void 0 : _children$$children$$.length); i++) {
      var _children$, _children$$children, _children$$children$, _children$$children$$, _children$2, _children$2$children, _children$2$children$, _children$2$children$2;
      var child = children === null || children === void 0 ? void 0 : (_children$2 = children[0]) === null || _children$2 === void 0 ? void 0 : (_children$2$children = _children$2.children) === null || _children$2$children === void 0 ? void 0 : (_children$2$children$ = _children$2$children[0]) === null || _children$2$children$ === void 0 ? void 0 : (_children$2$children$2 = _children$2$children$.children) === null || _children$2$children$2 === void 0 ? void 0 : _children$2$children$2[i];
      var linkHref = extractHref(child) || '#';
      var imgHref = extractSrc(child);
      var imgAlt = extractAlt(child);
      var dataLink = {
        class: '',
        props: {
          to: linkHref,
          newWindow: true
        }
      };
      var dataImg = {
        attrs: {
          alt: imgAlt,
          src: image({
            path: imgHref
          })
        }
      };
      columns[i] = h('div', {
        class: 'col-12 col-sm-4'
      }, [h('v-link', dataLink, [h('v-lazy-image', dataImg, [])])]);
    }
    var data = {
      class: "section ".concat(definedClass),
      style: "".concat(borderStyle, " ").concat(borderColor, " ").concat(borderWidth)
    };
    return h('div', data, [h('div', {
      class: 'row'
    }, columns)]);
  }
};