<template>
  <v-timed-promotion-lazy :data="data" />
</template>

<script>

export default {
  name: 'TimedPromotion',

  components: {
    'v-timed-promotion-lazy': () => import('@nsf/base/components/PageBuilder/Lazy/TimedPromotionLazy.vue'),
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
