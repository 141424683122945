import { applyInlineStyles, createElementId, createStyleElementContent, extractMarginFromStyle, extractPaddingFromStyle } from '@nsf/base/utils/PagebuilderStyleUtils.js';
export default {
  name: 'VDivider',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$data$child, _ctx$props$data$child2, _ctx$props$data$child3, _ctx$props$data, _ctx$props$data$child4, _ctx$props$data$child5, _ctx$props$data2, _ctx$props$data3;
    var className = "".concat(ctx.props.data.class ? "".concat(ctx.props.data.class, " ") : '', "pagebuilder-divider");
    var style = applyInlineStyles(ctx.props.data, ctx.props.data.style, {
      padding: true,
      margin: true,
      width: true
    });
    var innerStyle = applyInlineStyles((_ctx$props$data$child = ctx.props.data.children) === null || _ctx$props$data$child === void 0 ? void 0 : _ctx$props$data$child[0], (_ctx$props$data$child2 = ctx.props.data.children) === null || _ctx$props$data$child2 === void 0 ? void 0 : (_ctx$props$data$child3 = _ctx$props$data$child2[0]) === null || _ctx$props$data$child3 === void 0 ? void 0 : _ctx$props$data$child3.style, {
      padding: true,
      margin: true
    });

    // we will take margin and padding from outer element and apply to inner <hr>
    // its because it is current behaviour for divider and if we change it, it will look different
    innerStyle += extractMarginFromStyle(ctx.props.data.style);
    innerStyle += extractPaddingFromStyle(ctx.props.data.style);
    var elementId = createElementId((_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : (_ctx$props$data$child4 = _ctx$props$data.children) === null || _ctx$props$data$child4 === void 0 ? void 0 : (_ctx$props$data$child5 = _ctx$props$data$child4[0]) === null || _ctx$props$data$child5 === void 0 ? void 0 : _ctx$props$data$child5['data-pb-style']);
    var mobileInnerStyle = '';
    mobileInnerStyle += extractMarginFromStyle((_ctx$props$data2 = ctx.props.data) === null || _ctx$props$data2 === void 0 ? void 0 : _ctx$props$data2['mobile-style']);
    mobileInnerStyle += extractPaddingFromStyle((_ctx$props$data3 = ctx.props.data) === null || _ctx$props$data3 === void 0 ? void 0 : _ctx$props$data3['mobile-style']);
    var data = {
      style: style,
      class: className
    };
    var innerData = {
      attrs: {
        id: elementId
      }
    };
    var styleElementContent = createStyleElementContent(elementId, innerStyle, mobileInnerStyle);
    var styleElement = h('style', {}, styleElementContent);
    return h('div', data, [h('hr', innerData), styleElement]);
  }
};