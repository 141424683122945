import { isClient } from '@nsf/core/Utils.js'

class FocusModal {
  constructor({
    focusAreaRef, focusElRef, lastActiveElement = null, prev = null,
  }) {
    this.focusAreaRef = focusAreaRef
    this.focusElRef = focusElRef
    this.lastActiveElement = lastActiveElement
    this.prev = prev
  }
}

const focusTrap = new class focusTrap {
  constructor() {
    this.focusRestrictionHandler = this.focusRestriction.bind(this)
    this.activeModal = null
  }

  focusRestriction(event) {
    if (this.activeModal && event.target.nodeType === 1 && !this.activeModal.focusAreaRef?.contains(event.target)) {
      event.stopPropagation()
      this.activeModal.focusElRef?.focus()
      return
    }
  }
}()

export default {
  data: () => ({
    focusTrapModal: null,
  }),

  methods: {
    removeFocusTrapListener() {
      if (!isClient() || !this.focusTrapModal) {
        return
      }
      focusTrap.activeModal.lastActiveElement?.focus()

      const prevModal = focusTrap.activeModal?.prev

      this.focusTrapModal = null

      if (prevModal) {
        focusTrap.activeModal = prevModal
      } else {
        focusTrap.activeModal = null
        window.removeEventListener('focus', focusTrap.focusRestrictionHandler, true)
      }
    },

    addFocusTrapListener(focusAreaRef, focusElRef, lastActiveElement = null) {
      if (!isClient() || this.focusTrapModal) {
        return null
      }

      const prevModal = focusTrap.activeModal

      this.focusTrapModal = new FocusModal({
        focusAreaRef,
        focusElRef,
        lastActiveElement,
        ...(prevModal ? { prev: prevModal } : { prev: null }),
      })

      if (!prevModal) {
        window.addEventListener('focus', focusTrap.focusRestrictionHandler, true)
      }

      focusTrap.activeModal = this.focusTrapModal
      focusTrap.activeModal.focusElRef?.focus()
    },
  },

  beforeDestroy() {
    this.removeFocusTrapListener()
  },
}
