import { pbPublitasWidget, pbCountdownWidget, pbDrugLeafletWidget, pbPregnancyCalculatorWidget, pbAdvisoryWidget, pbPregnancyWeeks, pbCategoriesWidget, pbRecentArticlesWidget, pbSubCategoryBoxesWidget, pbCookiebotWidget, pbAtDoctorLinkWidget, pbSkinAnalyzerWidget, pbStreamsWidget, pbBmiCalculatorWidget, pbCalorieCalculatorWidget, pbPlacementBannerWidget, pbPopularBrandsWidget, pbLoginButtonWidget, pbNewReturnFormWidget } from '@ioc/pageBuilder.js';
var getWidgetComponentByType = function getWidgetComponentByType(widgetType) {
  switch (widgetType) {
    case 'publitas_widget':
      return pbPublitasWidget;
    case 'countdown_widget':
      return pbCountdownWidget;
    case 'drug_leaflet_widget':
      return pbDrugLeafletWidget;
    case 'pregnancy_calculator_widget':
      return pbPregnancyCalculatorWidget;
    case 'advisory_widget':
      return pbAdvisoryWidget;
    case 'pregnancy_weeks_widget':
      return pbPregnancyWeeks;
    case 'advisory_categories_widget':
      return pbCategoriesWidget;
    case 'recent_articles_widget':
      return pbRecentArticlesWidget;
    case 'subcategory_boxes_widget':
      return pbSubCategoryBoxesWidget;
    case 'cookiebot_widget':
      return pbCookiebotWidget;
    case 'at_doctor_link_widget':
      return pbAtDoctorLinkWidget;
    case 'skin_analyzer_widget':
      return pbSkinAnalyzerWidget;
    case 'streams_widget':
      return pbStreamsWidget;
    case 'bmi_calculator_widget':
      return pbBmiCalculatorWidget;
    case 'calorie_calculator_widget':
      return pbCalorieCalculatorWidget;
    case 'popular_brands_widget':
      return pbPopularBrandsWidget;
    case 'login_button_widget':
      return pbLoginButtonWidget;
    case 'new_return_form_widget':
      return pbNewReturnFormWidget;
    case 'placement_banner_widget':
      return pbPlacementBannerWidget;
    default:
      return 'div';
  }
};
export default {
  name: 'DrmaxWidget',
  functional: true,
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  render: function render(h, ctx) {
    var _ctx$props;
    var widgetType = (_ctx$props = ctx.props) === null || _ctx$props === void 0 ? void 0 : _ctx$props.data['widget-type'];
    var widget = getWidgetComponentByType(widgetType);
    var props = {
      props: {
        data: ctx.props.data
      }
    };
    return h(widget, props);
  }
};