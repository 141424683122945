import { mapState, mapActions, mapMutations } from 'vuex'
import HasUser from './HasUser.js'

export default {
  methods: {
    ...mapActions('my-account/user', ['resetLoginError', 'logout', 'login']),
    ...mapMutations('my-account/user', ['setLoginError']),
  },
  mixins: [HasUser],
  computed: {
    ...mapState(
      'my-account/user',
      [
        'isLoggingIn',
        'isLoggingOut',
        'loginError',
      ],
    ),

    isUnauthorized() {
      return this.loginError === 'unauthorized'
    },

    isMissingConfirmation() {
      return this.loginError === 'missingConfirmation'
    },

    isOtherError() {
      return this.loginError === 'other'
    },

    isConfirmationEmailResendSuccess() {
      return this.loginError === 'confirmationEmailResendSuccess'
    },

    isConfirmationEmailResendError() {
      return this.loginError === 'confirmationEmailResendError'
    },

    isSomeError() {
      return this.loginError !== null
    },

    errorList() {
      const errors = []
      this.isUnauthorized && errors.push({ type: 'isUnauthorized', message: `${this.$t('login.Could not log in')}. ${this.$t('login.Please check your username and password')}.` })
      this.isOtherError && errors.push({ type: 'isOtherError', message: `${this.$t('login.Could not log in')}. ${this.$t('login.An error occurred during login')}` })
      this.isMissingConfirmation && errors.push({ type: 'isMissingConfirmation', message: `${this.$t('login.Could not log in')}. ${this.$t('login.Your account is not verified, check your email or')}` })
      return errors
    },
  },
}
