<template>
  <v-recent-articles />
</template>

<script>

export default {
  components: {
    'v-recent-articles': () => import('../components/RecentArticles/index.vue'),
  },
}
</script>
