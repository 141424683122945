import logger from '@nsf/catalog/logger.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { mapDataToModel } from '@nsf/core/Mapper.js'
import { getProductsByIds } from '@nsf/catalog/repositories/ProductRepository.js'
import { listingRequiredFields } from '@nsf/catalog/utils/FieldsUtils.js'

export default async function (prodId) {
  try {
    const response = await Query.productGroup()
      .where('is_active', true)
      .nested(
        'products',
        Query
          .any()
          .where('products.product_id', prodId),
      )
      .first()

    if (!response) {
      return { productGroup: {} }
    }

    const productGroup = mapDataToModel(response)
    const productsOfGroup = productGroup.products.find((e) => e.productId === prodId).attributes

    const productIds = [...new Set(productsOfGroup.flatMap((item) => (item.options || [])
      .map((option) => option.productId)))]
      .filter((id) => id !== undefined)

    const { products } = await getProductsByIds(productIds,
      {
        size: productIds.length,
        callback: (query) => {
          query.only(listingRequiredFields)
        },
      })

    if (!products) {
      return { productGroup: {} }
    }

    const productVariants = productsOfGroup.map((group) => {
      const variantOptions = (group.options || [])
        .filter((option) => option.productId !== undefined)
        .map((option) => {
          const product = products.find((prod) => prod.id === option.productId)
          return product ? { ...product, ...option } : null
        })

      return { ...group, options: variantOptions.filter((e) => !!e) }
    })

    return {
      productGroup: {
        name: productGroup.name,
        id: productGroup.id,
        code: productGroup.code,
        productVariants,
      },
    }
  } catch (e) {
    logger.error('getProductGroup failed: %o', e)
    return { productGroup: {} }
  }
}
