import logger from '@nsf/brands/logger.js'
import { mapDataToBrand } from '@nsf/brands/mappers/BrandMapper.js'
import { brandInfoQuery } from '@ioc/graphql/queries.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'

export const getAllBrands = async () => {
  try {
    const response = await Query.brands()
      .only(['id', 'url_key', 'url_path', 'title', 'option_id'])
      .getAll()

    const brands = mapDataToBrand(response)

    return { brands }
  } catch (e) {
    logger.withTag('elastic').error('getAllBrands failed: %o', e)

    return { brands: [] }
  }
}

export const getAllNonEmptyBrands = async () => {
  try {
    const nonEmptyBrands = await Query.products()
      .aggregate('drmax_brand', 9001)
      .aggregations('drmax_brand')

    return { nonEmptyBrands }
  } catch (e) {
    logger.withTag('elastic').error('getAllNonEmptyBrands failed: %o', e)

    return { nonEmptyBrands: [] }
  }
}

export const getBrandById = async (id) => {
  try {
    const brand = await Query.brands()
      .where('id', id)
      .first()
    if (!brand) {
      throw new Error('Brand id does not exist in ES.')
    }

    return {
      brand: mapDataToBrand(brand),
    }
  } catch (e) {
    logger.withTag('catalog').error('getBrandById(%o) failed: %s', id, e.message)

    return {
      brand: {},
    }
  }
}

export const getBrandsByIds = async (ids) => {
  try {
    const brands = await Query.brands()
      .whereIn('id', ids)
      .getAll()

    return brands.map((item) => mapDataToBrand(item))
  } catch (e) {
    logger.withTag('catalog').error('getBrandsByIds(%o) failed: %s', ids, e.message)

    return []
  }
}

export const getBrandsByOptionIds = async (ids) => {
  try {
    const brands = await Query.brands()
      .whereIn('option_id', ids)
      .getAll()

    return brands.map((item) => mapDataToBrand(item))
  } catch (e) {
    logger.withTag('catalog').error('getBrandsByOptionIds(%o) failed: %s', ids, e.message)

    return []
  }
}

export const getBrandPreviewById = async (id) => {
  try {
    const response = await brandInfoQuery()
      .clone()
      .where('id', variable('id', REQUIRED_INT))
      .bind({ id })
      .get()

    return {
      brand: mapDataToBrand(response),
    }
  } catch (e) {
    logger
      .withTag('catalog')
      .error('getBrandPreviewById(%o) failed: %s', id, e.message)

    return {
      brand: {},
    }
  }
}

export const getBrandByOptionId = async (optionId) => {
  try {
    const brand = await Query.brands()
      .where('option_id', optionId)
      .first()
    return {
      brand: mapDataToBrand(brand),
    }
  } catch (e) {
    logger.withTag('catalog').error('getBrandById(%o) failed: %s', optionId, e.message)

    return {
      brand: {},
    }
  }
}
