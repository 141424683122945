import { extract, findFirstElement, renderChildren } from '@nsf/base/components/PageBuilder/index.js';
export default {
  name: 'VP',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var children = ctx.props.data.children || [];
    var data = {
      class: 'text',
      style: extract(ctx.props.data.style, /text-align: .+?;/) || ''
    };
    var hasImage = findFirstElement(children, function (o) {
      return o.tag === 'img';
    }, 30);
    var tagName = hasImage ? 'div' : 'p';
    return h(tagName, data, renderChildren(h, ctx, children));
  }
};