<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div ref="dropdown" class="dropdown-container">
    <div v-if="label || optional || required" class="dropdown-title">
      <v-label v-if="label">
        {{ label }}
      </v-label>
      <Optional v-if="optional" :optional="optional" />
      <Required v-if="required" />
    </div>
    <div class="error-wrapper" :class="{ error: error && errorText }">
      <button
        class="dropdown-select"
        :class="[
          { 'is-open': isOpen },
          { disabled: disabled },
          { error: error },
        ]"
        @click="toggleDropdown"
      >
        <span
          class="search-text"
          :class="{ 'option-selected': hasValueSelected }"
        >{{ dropdownText }}</span>
        <svg :class="{ 'is-open': isOpen }" width="24" height="24">
          <use xlink:href="/images/icons-base.svg#arrow-down" />
        </svg>
      </button>
      <span v-if="error && errorText" class="error-text">{{ errorText }}</span>
    </div>
    <div
      v-if="isOpen"
      class="dropdown-box"
      :class="{
        'dropdown-box--without-label': !label && !optional && !required,
      }"
    >
      <div class="dropdown-options">
        <v-input
          v-if="searchFilterEnabled"
          v-model="search"
          type="text"
          class="search-filter"
          :placeholder="$t('base.header.Search')"
        >
          <template #leadingIcon>
            <svg>
              <use xlink:href="/images/icons-base.svg#search" />
            </svg>
          </template>
        </v-input>
        <div v-if="loading" class="loading">
          <span class="spinner-border" />
          <span>{{ $t("base.Loading") }}...</span>
        </div>
        <div
          v-else-if="filteredOptions.length > 0"
          ref="dropdownOptions"
          class="dropdown-results"
          :class="{ 'dropdown-results-scrollbar': filteredOptionsCount > 5 }"
        >
          <component
            :is="optionComponent"
            v-for="(option, index) in filteredOptions"
            :key="index"
            :option="option"
            @select="onSelect"
          />
        </div>
        <span v-else class="no-results">{{
          $t("algolia.No results found")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Label from '@nsf/ui-library/components/atoms/Label.vue'
import DropdownOption from '@nsf/ui-library/components/atoms/DropdownOption.vue'
import Required from '@nsf/ui-library/components/atoms/Required.vue'
import Optional from '@nsf/ui-library/components/atoms/Optional.vue'
import Input from '@nsf/ui-library/components/molecules/Input.vue'

export default {
  components: {
    'v-label': Label,
    'v-input': Input,
    DropdownOption,
    Optional,
    Required,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: null,
    },
    initialValue: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    resetOntoInitialValue: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    searchFilter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
      optionComponent: 'DropdownOption',
      search: '',
      selected: null,
    }
  },

  computed: {
    isOpen() {
      return this.open && !this.disabled
    },
    hasValueSelected() {
      return !!this.selected
    },
    filteredOptions() {
      return !this.search
        ? this.dropdownOptions
        : this.dropdownOptions.filter((option) => option.label?.toLowerCase()?.includes(this.search.toLowerCase()))
    },
    dropdownText() {
      return this.selected?.label || this.placeholder || this.$t('base.Please select')
    },
    filteredOptionsCount() {
      return this.filteredOptions.length
    },
    searchFilterEnabled() {
      return this.searchFilter
    },
  },

  watch: {
    resetOntoInitialValue(newValue) {
      if (newValue) {
        this.selected = this.initialValue
      }
    },
  },

  mounted() {
    if (this.initialValue) {
      this.selected = this.initialValue
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick)
  },

  methods: {
    scrollToOption() {
      const dropdown = this.$refs.dropdownOptions
      const selectedOption = dropdown?.querySelector(`.option.active`)
      if (dropdown) {
        dropdown.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
      if (selectedOption) {
        this.$nextTick(() => selectedOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' }))
      }
    },
    toggleDropdown() {
      if (this.disabled) {
        this.open = false
      } else {
        this.open = !this.open
        if (this.isOpen) {
          this.$nextTick(() => this.scrollToOption())
          document.addEventListener('click', this.handleDocumentClick)
        } else {
          document.removeEventListener('click', this.handleDocumentClick)
        }
      }
    },
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.open = false
        this.search = ''
        document.removeEventListener('click', this.handleDocumentClick)
      }
    },
    onSelect(option) {
      this.open = false
      this.selected = option
      this.$emit('select', option)
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown-title {
  display: flex;
  align-items: center;
  height: 10px;
  margin: 0 2px 10px;
}

.dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 328px;
}

.error-wrapper {
  height: 42px;

  > .error-text {
    height: 8px;
    padding-left: 6px;
    color: $red-800;
    @include label-small-bold;
  }

  &.error {
    height: 62px;
    border-radius: 4px;
    background-color: $red-50;
    outline: 1px solid $red-500;
    outline-offset: -1px;
  }
}

.dropdown-select {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 8px;
  text-align: start;
  border: 1px solid $gray-300;
  border-radius: 4px;
  background-color: $text-white-primary;
  box-shadow: 0 -4px 5px 1px rgba(0, 0, 0, 0.04) inset;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid $yellow-200;
    outline-offset: 2px;
  }

  &:hover:not(.disabled):not(.error),
  &.is-open {
    color: $blue-500;
    border: 1px solid $blue-300;
  }

  &.error {
    border: 1px solid $red-500;

    /* stylelint-disable max-nesting-depth */
    > .search-text {
      color: $red-700;
    }

    &.is-open {
      color: $red-700;
    }
    /* stylelint-enable max-nesting-depth */
  }

  &.disabled {
    background-color: $gray-50;
    box-shadow: none;
    cursor: default;
  }

  > .search-text.option-selected {
    color: $blue-600;
  }

  > .search-text {
    flex-grow: 50;
    padding: 0 4px;
    @include body-medium;
    color: $text-black-tertiary;
  }
}

.dropdown-box {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: auto;
  padding: 12px;
  margin-top: 70px;
  border: 1px solid $gray-300;
  border-radius: 4px;
  background-color: #fff;

  &--without-label {
    margin-top: 41px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;

  .spinner-border {
    color: $blue-500;
  }
}

.dropdown-results {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 250px;

  /* stylelint-disable max-nesting-depth */
  &.dropdown-results-scrollbar {
    padding-right: 8px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track-piece {
      border-radius: 2px;
      background-color: $gray-50;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: $gray-300;
    }
  }
  /* stylelint-enable max-nesting-depth */
}

.search-filter {
  margin-bottom: 10px;
}

.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;
}

svg {
  transition: transform 0.1s ease;

  /* stylelint-disable selector-no-qualifying-type */
  &.is-open {
    transform: rotate(180deg);
  }
  /* stylelint-enable selector-no-qualifying-type */
}

@media screen and (max-width: 767px) {
  .dropdown-container {
    max-width: 100%;
  }
}
</style>
